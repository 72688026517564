/**
 * @copyright 2019 @ DigiNet
 * @author rocachien
 * @create 2019/02/15 10:25
 * @update 2019/02/15 10:25
 */

import React, {Component} from 'react';
import {Column} from "devextreme-react/data-grid";
import GridContainer from "../common/grid-container/grid-container";
import connect from "react-redux/es/connect/connect";
import {bindActionCreators} from "redux";
import * as w18F1017Actions from "../../actions/w18/w18F1017/w18F1017-actions";
import * as userActions from "../../actions/user-actions";
import PopupView from "../popup/popup-view";
import {Col, FormGroup} from "react-bootstrap";
import Config from "../../config";
import EventTracking from "../common/tracking/event";
import ButtonApprove from "../common/button/button-approve";
import GridActionBar from "../common/action-bar/grid-action-bar";
import ButtonSearch from "../common/button/button-search";
import saveAsyns from "../../actions/w18/w18F1017/add-async";
import notify from "devextreme/ui/notify";


class W18F1017 extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showPopup: false,
        };

        this.filter = {
            skip: 0,
            limit: 20,
            search: ''
        };
        this.popup = {
            skip: 0,
            limit: 20,
            search: '',
            warehouse: '',
        };
        this.data = []
    }

    onHide() {
        this.setState({
            showPopup: false
        });
    }

    componentDidMount = async () => {
        if (this.props.iPermission <= 0) return;
        this.loadDataWareHouse();
        this.loadDataMaster();
    };

    onChangePage(page) {
        this.filter.skip = page * this.filter.limit;
        this.loadDataMaster();
    }

    onChangePerPage(per) {
        this.filter.skip = 0;
        this.filter.limit = per;
        this.loadDataMaster();
    }

    onSearch(text) {
        this.filter.skip = 0;
        this.filter.search = text;
        this.loadDataMaster();
    }


    onPopChangePage(page) {
        this.popup.skip = page * this.popup.limit;
        this.loadDataPopUp();
    }

    onPopChangePerPage(per) {
        this.popup.skip = 0;
        this.popup.limit = per;
        this.loadDataPopUp();
    }

    onPopSearch() {
        this.popup.skip = 0;
        this.popup.limit = 20;
        this.loadDataPopUp();
    }


    loadDataWareHouse() {
        // const divisionID = Config.CompanyID();
        const {companyID} = this.props;
        const param = {
            "DivisionID": companyID ? companyID : '',
        };
        this.props.w18F1017Actions.loadWareHouse(param, () => {
        });
    }

    loadDataMaster() {
        const uName = Config.profile.UserID || '';
        const uLanguage = Config.language || '';
        const companyID = this.props.companyID;
        const params = {
            "UserID": uName,
            "Language": uLanguage,
            "CompanyID": companyID,
            "StrSearch": this.filter.search,
            "Mode": 1,
            "skip": this.filter.skip,
            "limit": this.filter.limit,
        };
        this.props.w18F1017Actions.loadMaster(params, () => {
        });
    }

    loadDataPopUp() {
        const uName = Config.profile.UserID || '';
        const divisionID = Config.profile.DivisionID || '';
        const params = {
            "UserID": uName,
            "FormID": 'W18F1017',
            "DivisionID": divisionID,
            "StrSearch": this.popup.search,
            "WareHouseID": this.popup.warehouse,
            "skip": this.popup.skip,
            "limit": this.popup.limit,
        };
        this.props.w18F1017Actions.loadGrid(params, () => {
        });
    }

    renderActionActivity(e) {
        if (!e) return null;
        const {iPermission, companyID, disabledAction} = this.props;
        return (
            <GridActionBar
                classNameIs={iPermission}
                className={(iPermission >= 4  && !disabledAction )? '' : 'isDisabled'}
                isPer={iPermission}
                tooltipDelete={Config.lang("CRM_Xoa")}
                onDeleteAction={() => {
                    if (disabledAction) {
                        return false
                    }
                    const param = {
                        Code: e.data.InventoryID,
                        Type: 'Inventory',
                        CompanyID: companyID || '',
                    };
                    Config.popup.show("YES_NO", Config.lang("CRM_Ban_co_chac_chan_muon_xoa_du_lieu_nay"), () => {
                        this.props.w18F1017Actions.deleteProduct(param, (error) => {
                            if (error) {
                                let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                                Config.popup.show('INFO', message);
                                return false;
                            } else {
                                this.loadDataMaster();
                                notify(Config.lang("CRM_Du_lieu_da_xoa_thanh_cong"), "success", 500);
                            }


                        })


                    })
                }
                }/>
        );
    }

    selectionChange(rowData) {
        this.data = rowData
    }

    async saveData() {

        let param;
        const data = this.data;
        const {companyID} = this.props;
        if (data.length <= 0) {
            Config.popup.show('INFO', Config.lang("CRM_Ban_chua_chon_nhom_hang"));
            return false;
        }
        for (let i = 0; i < data.length; i++) {
            param = {
                Code: data[i].InventoryID,
                Name: data[i].InventoryName,
                Type: 'Inventory',
                CompanyID: companyID || '',
            };
            const saveData = await saveAsyns(param);
            if (saveData.code !== 200) {
                Config.popup.show('INFO', Config.lang("CRM_Loi_chua_xac_dinh"));
                return false;
            }
            notify(Config.lang("CRM_Luu_thanh_cong"), "success", 500);
            this.loadDataMaster();
        }

    }


    render() {
        const {dataMaster, dataPopUp, dataWareHouse, iPermission,disabledAction} = this.props;

        return (
            <div>

                {this.state.showPopup && <PopupView
                    title={Config.lang("CRM_Chon_nhom_hang")}
                    show={true}
                    animation={true}
                    className={'popup-size-80'}
                    onHide={() => this.onHide()}

                >
                    {Config && Config.profile &&
                    <EventTracking category={"TRACKING_USER"} action={"W18F1017"} label={Config.profile.UserID}/>}
                    <div className="page-container">
                        <div id={'frmW18F1017'}>
                            <FormGroup className={'mgt5'}>
                                <Col md={1} sm={1} xs={1} lg={1}>
                                    <label className={'control-label'}>{Config.lang("CRM_Ma_Ten")}</label>
                                </Col>
                                <Col md={3} sm={3} xs={3} lg={3}>
                                    <input id={'searchW18F1017'} onKeyUp={(e) => {
                                        this.popup.search = e.target.value
                                    }} className={'form-control'}
                                           placeholder={Config.lang("CRM_Nhap_noi_dung_tim_kiem")}/>
                                </Col>
                                <Col md={1} sm={1} xs={1} lg={1}>
                                    <label className={'control-label'}>{Config.lang("CRM_Kho")}</label>
                                </Col>
                                <Col md={3} sm={3} xs={3} lg={3}>
                                    <select onChange={(e) => {
                                        this.popup.warehouse = e.target.value
                                    }} className={'form-control'} id={'priorityW18F1014'} >
                                        <option value={''}/>
                                        {dataWareHouse && dataWareHouse.map((value, index) => {
                                            return (
                                                <option key={index}
                                                        value={value.WareHouseID}>{value.WareHouseName}</option>
                                            )
                                        })}
                                    </select>
                                </Col>
                                <Col md={1} sm={1} xs={1} lg={1}>
                                    <ButtonSearch onClick={() => {
                                        this.onPopSearch()
                                    }} name={Config.lang("CRM_Tim_kiem")}/>
                                </Col>


                            </FormGroup>
                            <FormGroup>
                                <Col md={12} sm={12} xs={12} lg={12}>
                                    <GridContainer
                                        dataSource={dataPopUp}
                                        keyExpr={'InventoryID'}
                                        selection={{mode: 'multiple'}}
                                        onChangePage={(page) => {
                                            this.onPopChangePage(page)
                                        }}
                                        onChangePerPage={(per) => {
                                            this.onPopChangePerPage(per)
                                        }}

                                        onSelectionChanged={(e, rowData) => {
                                            this.selectionChange(rowData);
                                        }}
                                        totalItems={dataPopUp ? dataPopUp.length > 0 ? dataPopUp[0].TotalRecord : 0 : 0}


                                    >
                                        <Column
                                            dataField={'InventoryID'}
                                            caption={Config.lang("CRM_Ma_hang_hoa")}
                                            alignment={'center'}
                                            allowSorting={false}
                                            showInColumnChooser={false}
                                            width={150}
                                        />
                                        <Column
                                            dataField={'InventoryName'}
                                            caption={Config.lang("CRM_Ten_hang_hoa")}
                                            allowSorting={true}
                                            allowReordering={false}
                                            width={300}
                                        />
                                        <Column
                                            dataField={'ICode10Name'}
                                            caption={Config.lang("CRM_Loai_hang_hoa")}
                                            allowSorting={true}
                                            allowReordering={false}
                                            width={300}
                                        />
                                        <Column
                                            dataField={'UnitID'}
                                            caption={Config.lang("CRM_Don_vi_tinh")}
                                            allowSorting={true}
                                            allowReordering={false}
                                            width={300}
                                        />
                                        <Column
                                            dataField={'UnitPrice'}
                                            caption={Config.lang("CRM_Don_gia_ban")}
                                            allowSorting={true}
                                            allowReordering={false}
                                            width={300}
                                        />

                                        <Column
                                            dataField={'B1'}
                                            caption={Config.lang("CRM_SL_ton_thuc_te")}
                                            allowSorting={true}
                                            allowReordering={false}
                                            width={300}
                                        />
                                        <Column
                                            dataField={'C1'}
                                            caption={Config.lang("CRM_SL_co_the_xuat")}
                                            allowSorting={true}
                                            allowReordering={false}
                                            width={300}
                                        />
                                        <Column
                                            dataField={'B3'}
                                            caption={Config.lang("CRM_So_luong_dang_dat_ban")}
                                            allowSorting={true}
                                            allowReordering={false}
                                            width={300}
                                        />
                                        <Column
                                            dataField={'C2'}
                                            caption={Config.lang("CRM_So_luong_co_the_dat_ban")}
                                            allowSorting={true}
                                            allowReordering={false}
                                            width={300}
                                        />
                                        <Column
                                            dataField={'B4'}
                                            caption={Config.lang("CRM_So_luong_dang_dat_hoa_don_ban")}
                                            allowSorting={true}
                                            allowReordering={false}
                                            width={300}
                                        />
                                    </GridContainer>
                                </Col>
                            </FormGroup>
                            <FormGroup>
                                <Col md={12} sm={12} xs={12} lg={12}>
                                    <ButtonApprove className={'pull-right'} onClick={() => {
                                        this.saveData()
                                    }} name={Config.lang("CRM_Dong_y")}/>
                                </Col>
                            </FormGroup>
                        </div>
                    </div>
                </PopupView>}
                <GridContainer
                    allowSearch ={disabledAction ? "isDisabled" : ""}
                    disabledAddNew ={disabledAction}
                    dataSource={dataMaster}
                    allowAdding={this.state.isPer}
                    isPer={iPermission}
                    onAddNew={() => {
                        this.setState({
                            showPopup: true
                        });
                        this.loadDataPopUp()
                    }}
                    totalItems={dataMaster ? dataMaster.length > 0 ? dataMaster[0].TotalRecord : 0 : 0}
                    itemPerPage={this.filter.limit}
                    onSearch={(text) => {
                        this.onSearch(text)
                    }}
                    onChangePage={(page) => {
                        this.onChangePage(page)
                    }}
                    onChangePerPage={(per) => {
                        this.onChangePerPage(per)
                    }}
                >
                    <Column
                        cellRender={(e) => this.renderActionActivity(e)}
                        dataField={'Action'}
                        caption={Config.lang("CRM_Hanh_dong")}
                        alignment={'center'}
                        allowSorting={false}
                        showInColumnChooser={false}
                        width={100}
                        fixed={true}
                    />
                    <Column
                        dataField={'InventoryID'}
                        caption={Config.lang("CRM_Ma_hang_hoa")}
                        alignment={'center'}
                        allowSorting={false}
                        showInColumnChooser={false}
                        width={150}
                    />
                    <Column
                        dataField={'InventoryName'}
                        caption={Config.lang("CRM_Ten_hang_hoa")}
                        allowSorting={true}
                        allowReordering={false}
                    />
                </GridContainer>
            </div>

        );
    }
}

W18F1017.propTypes = {};
export default connect(state => ({
        dataMaster: state.w18f1017.dataMaster,
        dataPopUp: state.w18f1017.dataPopUp,
        dataWareHouse: state.w18f1017.dataWareHouse
    }),
    (dispatch) => ({
        w18F1017Actions: bindActionCreators(w18F1017Actions, dispatch),
        userActions: bindActionCreators(userActions, dispatch),
    })
)(W18F1017);
