/**
 * @copyright 2019 @ DigiNet
 * @author VINHLE
 * @create 2019/01/11 10:37
 * @update 2019/01/11 10:37
 * @file src/components/header/header.js
 */

import { CheckBox, DateBox } from "devextreme-react";
import notify from "devextreme/ui/notify";
import _ from 'lodash';
import moment from "moment";
import 'moment/locale/vi';
import React from 'react';
import { Collapse, Dropdown, Form, MenuItem } from "react-bootstrap";
import DropdownMenu from "react-bootstrap/es/DropdownMenu";
import DropdownToggle from "react-bootstrap/es/DropdownToggle";
import { connect } from "react-redux";
import { browserHistory } from "react-router";
import { bindActionCreators } from "redux";
import * as w18Actions from "../../actions/w18-actions";
import * as w18F1011Actions from "../../actions/w18F1011-actions";
import W18F1011Async from "../../actions/w18F1011-async";
import Config from "../../config";
import ButtonCustom from "../common/button/button-custom";
import ButtonSave from "../common/button/button-save";
import DropDownSalePerson from "../common/dropdown/template/dropdown-sale-person";
import EventTracking from "../common/tracking/event";
import { Row, Col } from 'diginet-core-ui/components';
import { useTheme } from "diginet-core-ui/theme";

moment.locale('vi');
const style = {
    inputControl: {
        display: 'flex',
        justifyContent: 'space-between'
    }
};

const stateName = new Map([
    ['CompanyNameU', { id: 'companyNameW18F1011', name: 'companyName', tab: 'collapse1' }],
    ['CompanyKindID', { id: 'customerLevelW18F1011', name: 'companyKindID', tab: 'collapse1' }],
    ['CompetitorTypeID', { id: 'competitorW18F1011', name: 'competitorTypeID', tab: 'collapse1' }],
    ['PartnerTypeID', { id: 'partnerW18F1011', name: 'partnerTypeID', tab: 'collapse1' }],
    ['Email', { id: 'emailW18F1011', name: 'email', tab: 'collapse1' }],
    ['CompanyStatus', { id: 'companyStatusW18F1011', name: 'companyStatusID', tab: 'collapse1' }],
    ['Telephone', { id: 'telephoneW18F1011', name: 'telephone', tab: 'collapse1' }],
    ['CompanyFaxNo', { id: 'faxW18F1011', name: 'fax', tab: 'collapse1' }],
    ['SalesPersonID', { id: 'salesPersonW18F1011', name: 'salesPersonID', tab: 'collapse1' }],
    ['AddressLineU', { id: 'addressLineW18F1011', name: 'addressLine', tab: 'collapse1' }],
    ['ShipPlace', { id: 'shipPlaceW18F1011', name: 'shipPlace', tab: 'collapse1' }],
    ['NotesU', { id: 'notesW18F1011', name: 'notes', tab: 'collapse1' }],
    ['ProvinceID', { id: 'provinceW18F1011', name: 'provinceID', tab: 'collapse3' }],
    ['DistrictID', { id: 'districtW18F1011', name: 'districtID', tab: 'collapse3' }],
    ['WardID', { id: 'wardW18F1011', name: 'wardID', tab: 'collapse3' }],
    ['Quarter', { id: 'quarterW18F1011', name: 'quarter', tab: 'collapse3' }],
    ['CaseSourceID', { id: 'caseSourceW18F1011', name: 'caseSourceID', tab: 'collapse2' }],
    ['CompanyGroupID', { id: 'companyGroupW18F1011', name: 'companyGroupID', tab: 'collapse2' }],
    ['IndustryGroupID', { id: 'industryGroupID', name: 'indutryGroupW18F1011', tab: 'collapse2' }],
    ['CompanySizeID', { id: 'companySizeID', name: 'companySizeW18F1011', tab: 'collapse2' }],
    ['RevenueID', { id: 'revenueW18F1011', name: 'revenueID', tab: 'collapse2' }],
    ['Website', { id: 'websiteW18F1011', name: 'website', tab: 'collapse2' }],
    ['TaxNo', { id: 'taxNoW18F1011', name: 'taxNo', tab: 'collapse2' }],
    ['CompanyShort', { id: 'companyShortW18F1011', name: 'companyShort', tab: 'collapse2' }],
    ['DateOfBirth', { id: 'dateOfBirthW18f1011', name: 'dateOfBirth', tab: 'collapse2' }]
]);

const { spacing } = useTheme();
class W18F1011Page extends React.Component {
    constructor(props) {
        super(props);
        if (!this.getInfo()) return;
        const { mode, type } = this.getInfo();
        let isCustomer = '';
        let isCompetitor = '';
        let isPartner = '';
        isCustomer = type === 'Customers';
        isCompetitor = type === 'Competitor';
        isPartner = type === 'Partner';
        this.toggleDropDown = this.toggleDropDown.bind(this);
        this.state = {
            dataMain: null,
            collapse1: true,  // thong tin chung
            collapse2: mode !== 'add', // thong tin bo sung
            collapse3: mode !== 'add',// dia ly
            dropdownOpen: false,
            dropdownSelected: [],
            dataDropDown: [],
            objectID: '',
            objectTypeID: '',
            quarter: '',
            provincename: '',
            provinceID: '',
            districtID: '',
            districtname: '',
            wardname: '',
            wardID: '',
            dateOfBirth: null,
            companyName: '',
            companyShort: '',
            companyKindID: '',
            competitorTypeID: '',
            partnerTypeID: '',
            competitorTypeName: '',
            partnerTypeName: '',
            companyStatusID: '',
            salesPersonID: '',
            industryGroupID: '',
            companyGroupID: '',
            notes: '',
            telephone: '',
            fax: '',
            email: '',
            website: '',
            taxNo: '',
            shipPlace: '',
            addressLine: '',
            caseSourceID: '',
            companySizeID: '',
            IsCompetitor: isCompetitor,
            IsCustomer: isCustomer,
            IsPartner: isPartner,
            revenueID: '',
            disabledSP: false,
            isSaved: false,
            btnSaveStatus: false,
            requiredField: []
        };
        this.timer = null;
        this.timeDeplay = Config.getSetting("TIME_SUBMIT_DELAY") || 3000;
        this.oldDataMaster = {};
    };

    getInfo = (flag) => {
        const { location } = this.props;
        const url = new window.URLSearchParams(window.location.search);
        if (url && url.get('CompanyID')) {
            return { companyID: url.get('CompanyID'), type: url.get('type'), mode: url.get('mode'), FormID: url.get('FormID') };
        } else if (location && location.state) {
            return {
                companyID: location.state.CompanyID,
                mode: location.state.mode,
                modeW18F1061: location.state.modeW18F1061,
                type: location.state.type,
                FormID: location.state.FormID,
                CaseCode: location.state.CaseCode,
                oldFormID: location.state.oldFormID,
                permission: location.state.permission,
            }
        } else {
            if (flag) {
                browserHistory.push(Config.env.url + '/W18F1010?type=Customers');
                return null;
            }
            return false;
        }
    };

    componentDidMount() {
        if (!this.getInfo()) return;
        const { mode, type, companyID } = this.getInfo();
        this.setState({ loading: true, dataMain: null });
        setTimeout(() => {
            this.setState({ loading: false });
        }, this.timeDeplay);

        const uName = Config.profile.UserID || '';
        const uLanguage = Config.language || '';

        this.props.w18Actions.cboCompanyStatusW18F1011({});

        this.props.w18Actions.cboCaseSourceW18F1011({});

        this.props.w18Actions.cboCompanyGroupW18F1011({});

        // this.props.w18Actions.cboSalesPersonW18F1011({});

        this.props.w18Actions.cboIndutryGroupW18F1011({});

        this.props.w18Actions.cboProvinceW18F1011({});

        this.props.w18Actions.cboCustomerLevelW18F1011({});

        this.props.w18Actions.cboCompetitorW18F1011({});

        this.props.w18Actions.cboPartnerW18F1011({});

        this.props.w18Actions.cboNormW18F1011({}, (error, data) => {
            if (mode !== 'add') {
                const paramGrid = {
                    "UserID": uName,
                    "Language": uLanguage,
                    "FormID": 'W18F1011',
                    "CompanyID": companyID,
                };
                this.props.w18F1011Actions.loadDataW18F1011(paramGrid, (error, data) => {
                    if (error) {
                        let errorCode = error.code || null;
                        let message = "";
                        switch (errorCode) {
                            case "US004":
                                message = "Tài khoản đăng nhập hoặc mật khẩu không đúng.";
                                break;
                            default:
                                message = error.message || "Lỗi chưa xác định";
                                Config.popup.show('INFO', message);
                                break;
                        }

                        this.setState({ isSubmit: false, message: message });
                        return false;
                    }
                    if (data) {
                        const dataf = data.data;
                        for (let i = 0; i < dataf.length; i++) {
                            this.selectDropDown({
                                NormID: dataf[i].NormID,
                                NormNameU: dataf[i].NormName,
                                ControlType: dataf[i].ControlType,
                                Value: dataf[i].ControlType === 'C' ? dataf[i].ValueID : dataf[i].Note,
                            })
                        }
                    }
                });
            }
            this.setState({
                dataDropDown: data
            });
        });

        this.props.w18Actions.cboCompanySizeW18F1011({});

        this.props.w18Actions.cboRevenueW18F1011({});

        this.setState({
            addressLine: '',
            quarter: '',
            provinceID: '',
            provincename: '',
            districtID: '',
            districtname: '',
            wardID: '',
            wardname: '',
            dateOfBirth: null,
            companyName: '',
            companyShort: '',
            companyKindID: '',
            competitorTypeID: '',
            partnerTypeID: '',
            competitorTypeName: '',
            partnerTypeName: '',
            companyStatusID: '',
            salesPersonID: Config.profile.SalesPersonID,
            industryGroupID: '',
            notes: '',
            fax: '',
            email: '',
            website: '',
            taxNo: '',
            shipPlace: '',
            caseSourceID: '',
            companyGroupID: '',
            companySizeID: '',
            IsCustomer: '',
            IsCompetitor: '',
            IsPartner: '',
            revenueID: ''
        });
        if (companyID) {
            const paramMaster = { CompanyID: companyID };
            this.props.w18F1011Actions.getDetailW18F1011(paramMaster, (error, data) => {
                if (error) {
                    let errorCode = error.code || null;
                    let message = "";
                    switch (errorCode) {
                        case "US004":
                            message = "Tài khoản đăng nhập hoặc mật khẩu không đúng.";
                            break;
                        default:
                            message = error.message || "Lỗi chưa xác định";
                            Config.popup.show('INFO', message);
                            break;
                    }

                    this.setState({ isSubmit: false, message: message });
                    return false;
                } else {
                    this.loadDataProvinceDistrict({
                        ProvinceID: data.data.ProvinceID,
                    });
                    this.setState({
                        dataMain: data.data,
                        addressLine: data.data.AddressLineU || '',
                        quarter: data.data.Quarter || '',
                        provinceID: data.data.ProvinceID || '',
                        provincename: data.data.ProvinceName || '',
                        districtID: data.data.DistrictID || '',
                        districtname: data.data.Districtname || '',
                        wardID: data.data.WardID || '',
                        wardname: data.data.WardName || '',
                        dateOfBirth: data.data.DateOfBirth || null,
                        companyName: data.data.CompanyNameU || '',
                        companyShort: data.data.CompanyShort || '',
                        companyKindID: data.data.CompanyKindID || '',
                        competitorTypeID: data.data.CompetitorTypeID || '',
                        partnerTypeID: data.data.PartnerTypeID || '',
                        competitorTypeName: data.data.CompetitorTypeName || '',
                        partnerTypeName: data.data.PartnerTypeName || '',
                        companyStatusID: data.data.CompanyStatus || '',
                        salesPersonID: data.data.SalesPersonID || '',
                        industryGroupID: data.data.IndustryGroupID || '',
                        notes: data.data.NotesU || '',
                        telephone: data.data.Telephone || '',
                        fax: data.data.CompanyFaxNo || '',
                        email: data.data.Email || '',
                        website: data.data.Website || '',
                        taxNo: data.data.TaxNo || '',
                        shipPlace: data.data.ShipPlace || '',
                        caseSourceID: data.data.CaseSourceID || '',
                        companyGroupID: data.data.CompanyGroupID || '',
                        companySizeID: data.data.CompanySizeID || '',
                        IsCustomer: data.data.IsCustomer === 1 ? true : false || '',
                        IsCompetitor: data.data.IsCompetitor === 1 ? true : false || '',
                        IsPartner: data.data.IsPartner === 1 ? true : false || '',
                        revenueID: data.data.RevenueID || '',
                        objectID: data.data.ObjectID || '',
                        objectTypeID: data.data.ObjectTypeID || ''
                    }, () => {
                        if (data.data) {
                            const notifyChangedField = ["companyName", "addressLine", "taxNo", "salesPersonID"];
                            const cloneState = { ...this.state };
                            notifyChangedField.forEach(item => {
                                this.oldDataMaster[item] = cloneState[item];
                            });
                        }
                    });
                }
            });
        } else {
            let isCustomer = '';
            let isCompetitor = '';
            let isPartner = '';
            if (type === 'Customers') {
                isCustomer = true;
            } else {
                isCustomer = false;
            }
            if (type === 'Competitor') {
                isCompetitor = true;
            } else {
                isCompetitor = false;
            }
            if (type === 'Partner') {
                isPartner = true;
            } else {
                isPartner = false;
            }
            this.setState({
                dataMain: 1,
                addressLine: '',
                quarter: '',
                provinceID: '',
                provincename: '',
                districtID: '',
                districtname: '',
                wardID: '',
                wardname: '',
                dateOfBirth: null,
                companyName: '',
                companyShort: '',
                companyKindID: '',
                competitorTypeID: '',
                partnerTypeID: '',
                competitorTypeName: '',
                partnerTypeName: '',
                companyStatusID: '',
                salesPersonID: Config.profile.SalesPersonID,
                industryGroupID: '',
                notes: '',
                telephone: '',
                fax: '',
                email: '',
                website: '',
                taxNo: '',
                shipPlace: '',
                caseSourceID: '',
                companyGroupID: '',
                companySizeID: '',
                IsCustomer: isCustomer,
                IsCompetitor: isCompetitor,
                IsPartner: isPartner,
                revenueID: ''
            });
        }
        this.loadCheckRequired();
    }

    loadCheckRequired = () => {
        const params = {
            CodeType: "Customer"
        };
        this.props.w18Actions.loadCheckRequired(params, (error, data) => {
            if (error) {
                Config.popup.show('INFO', error);
                return false;
            } else if (data) {
                if (_.isArray(data)) this.setState({ requiredField: data });
            }
        });
    };

    componentWillUnmount() {
        if (this.timer) clearTimeout(this.timer);
    }

    toggleDropDown() {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));
    }

    toggle1() {
        this.setState({ collapse1: !this.state.collapse1 });
    }

    changeAddress(e) {
        this.setState({
            addressLine: `${this.state.quarter ? this.state.quarter + ',' : ''}${this.state.wardname ? this.state.wardname + ',' : ''} ${this.state.districtname ? this.state.districtname + ', ' : ''}${this.state.provincename ? this.state.provincename : ''}`
        });
    }

    loadDataProvinceDistrict(e) {
        const paramCboDistrict = {
            "provinceID": e.ProvinceID
        };
        this.props.w18Actions.cboDistrictW18F1011(paramCboDistrict);
    }

    loadDataWard(ID) {
        const paramWard = {
            "districtID": ID
        };
        this.props.w18Actions.cboWardW18F1011(paramWard);
    }

    toggle2() {
        this.setState({ collapse2: !this.state.collapse2 });
    }

    toggle3() {
        this.setState({ collapse3: !this.state.collapse3 });

    }

    checkValidate(type, value) {
        const { mode, companyID } = this.getInfo();
        let CompanyID = '';
        if (mode === 'edit') {
            CompanyID = companyID;
        }
        value = Config.replaceSymbolTEPhone(value);
        if (this.timer) {
            clearTimeout(this.timer);
        }
        this.timer = setTimeout(() => {
            if (value !== '') {
                const param = {
                    "type": type,
                    "value": value,
                    "companyID": CompanyID,
                };
                this.props.w18Actions.getCompanyNameW18F1011(param, (error, data) => {
                    if (error) {
                        Config.popup.show('INFO', "Có lỗi trong quá trình xử lý dữ liệu");
                        return false;
                    } else if (data && data.length > 0) {
                        document.getElementById('telephoneW18F1011').blur();
                        Config.popup.show('INFO', `Khách hàng nãy đã tồn tại trong hệ thống. Có tên là ${data[0].CompanyNameU}`);
                    }
                })
            }
        }, this.timeDeplay / 3);
    }

    selectDropDown(e) {
        const dropdownSelected = this.state.dropdownSelected || [];
        const dataDropDown = this.state.dataDropDown || [];
        for (let i = 0; i < dataDropDown.length; i++) {
            if (dataDropDown[i].NormID === e.NormID) {
                dataDropDown[i].Hide = true;
            }
        }
        if (dropdownSelected.filter(obj => obj.NormID === e.NormID).length > 0) {
            return;
        }
        const paramCboData = {
            "normID": e.NormID,
        };
        this.props.w18Actions.cboDataW18F1011(paramCboData, (error, data) => {
            this.setState((state) => {
                state.dropdownSelected = state.dropdownSelected.concat({
                    NormID: e.NormID,
                    NormNameU: e.NormNameU,
                    ControlType: e.ControlType,
                    DataComBo: data ? data : [],
                    Value: e.Value
                });
                state.dataDropDown = dataDropDown
            });

        });

    }

    handlePushNotify = () => {
        let data = [];
        const typeValue = ["companyName", "addressLine", "taxNo"];
        const typeID = ["salesPersonID"];
        const oldDataMaster = this.oldDataMaster;
        const newDataMaster = this.state;
        const notifyChangedField = Object.keys(oldDataMaster);
        notifyChangedField.forEach((value) => {
            if (newDataMaster.hasOwnProperty(value) && oldDataMaster && newDataMaster[value] !== oldDataMaster[value]) {
                const obj = {
                    FieldChange: value,
                    ObjectTypeID: newDataMaster.objectTypeID,
                    ObjectID: newDataMaster.objectID,
                    IDFrom: typeID.includes(value) ? oldDataMaster[value] : "",
                    IDTo: typeID.includes(value) ? newDataMaster[value] : "",
                    ValueFrom: typeValue.includes(value) ? oldDataMaster[value] : "",
                    ValueTo: typeValue.includes(value) ? newDataMaster[value] : "",
                    TypeValue: typeValue.includes(value) ? "V" : typeID.includes(value) ? "ID" : "",
                }
                data.push(obj);
            }
        });
        if (data.length > 0) {
            const param = {
                data: JSON.stringify(data)
            };
            this.props.w18Actions.updateCustomer(param, (error, dataUpdate) => {
                // console.log("=========dataUpdate==========>", dataUpdate);
                if (error) {
                    const message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                    Config.popup.show('INFO', message);
                    return false
                }
            });
        }
    }

    //mode(string) = 'id' thì trả ra id, mode = 'tab' thì trả ra tên tab của field đó || mặc định trả ra tên đặt trong state theo Field Name
    getStateName = (name = "", mode = "") => {
        if (!_.isString(name)) return;
        if (mode === "id") return stateName.get(name)?.id || "";
        else if (mode === "tab") return stateName.get(name)?.tab || "collapse1"; // mặc định là tab 1
        return stateName.get(name)?.name || "";
    };

    /** Func filter ra những field có required và chưa có value
    * INPUT:
    *   data(type array)
    *   value(type string): mode filter
    * OUTPUT:  
    *   trả ra list filter (type array)
    */
    handleFilterDataRequired = (data = [], value = "") => {
        if (_.isEmpty(data) || !_.isArray(data) || !value) return [];
        return data.filter(item => item?.ValidMode === value && Object.hasOwn(this.state, this.getStateName(item?.FieldName)) && Config.isEmpty(this.state[this.getStateName(item?.FieldName)], true));
    };

    /** Func kiểm tra required từ store
    * OUTPUT:  
    *   status(type number): 0: là không bắt buộc nhập, 1: Required bắt buộc nhập, 2: required Yes_no bạn có chắc ?
    *   dataName(type array): danh sách name Caption
    */
    requiredStatus = () => {
        const { requiredField } = this.state;
        if (_.isEmpty(requiredField)) return 0;
        let status = 0;
        let requiredData = "";
        const equiredOStatus = this.handleFilterDataRequired(requiredField, "O");
        const equiredWStatus = this.handleFilterDataRequired(requiredField, "W");
        if (!_.isEmpty(equiredOStatus)) {
            requiredData = equiredOStatus.filter(item => item?.ValidMode === "O");
            status = 1;
        } else if (!_.isEmpty(equiredWStatus)) {
            requiredData = equiredWStatus.filter(item => item?.ValidMode === "W");
            status = 2;
        }
        return { status, requiredData };
    };

    //Func xử lý focus vào field đang required
    handleFocus = (requiredID = []) => {
        if (!_.isEmpty(requiredID) && _.isArray(requiredID)) {
            let idElm = "";
            const idDateList = ['DateOfBirth']; // Những thẻ mà input nằm bên trong
            idElm = document.getElementById(this.getStateName(requiredID[0], "id"));
            if (idElm && idDateList.includes(requiredID[0])) idElm = idElm.querySelectorAll("input[type=text]")?.[0]; // lấy thể input của element
            if (idElm) {
                const tabName = this.getStateName(requiredID[0], "tab");
                if (tabName) {
                    if (!this.state[tabName]) { // Tab chưa mở
                        this.setState({ [tabName]: true }, () => setTimeout(() => idElm.focus(), 300));
                    } else { // Tab đang mở
                        setTimeout(() => idElm.focus(), 300);
                    }
                }
            }
        }
    };

    saveData = () => {
        if (this.isSubmit) return;
        this.isSubmit = true;
        this.setState({
            isSaved: true
        });
        const companyName = document.getElementById("companyNameW18F1011").value;
        if (!companyName.trim()) {
            Config.popup.show('INFO', `${Config.lang("Ten_khach_hang")} ${Config.lang("Khong_duoc_de_trong").toLowerCase()}`);
            document.getElementById("companyNameW18F1011").focus();
            this.setState({
                isSaved: false
            });
            this.isSubmit = false;
            return;
        }
        const { status = 0, requiredData = [] } = this.requiredStatus();
        if (status) {
            this.isSubmit = false;
            let requiredCaption = [];
            let requiredID = [];
            for (let i = 0; i < requiredData.length; i++) {
                const obj = requiredData[i];
                requiredCaption.push(obj?.Caption);
                requiredID.push(obj?.FieldName);
            }
            if (status === 1) {
                Config.popup.show('INFO', `${requiredCaption.join(', ')} ${Config.lang("Khong_duoc_de_trong").toLowerCase()}`, null, null, () => {
                    this.handleFocus(requiredID);
                });
                return;
            } else if (status === 2) {
                Config.popup.show("YES_NO", Config.lang(`Ban_chua_nhap_truong:_%${requiredCaption.join(', ')}%_Ban_co_muon_luu_khong?`),
                    () => { // Yes Lưu
                        this.onSave();
                    }, () => { // No Focus and Return
                        this.handleFocus(requiredID);
                        return;
                    });
            } else {
                this.onSave();
            }
        } else {
            this.onSave();
        }
    };

    onSave = () => {
        const btnSave = document.getElementById('btnSaveW18F1011');
        const { mode, companyID } = this.getInfo();
        const companyName = document.getElementById("companyNameW18F1011").value;
        const companyShort = document.getElementById("companyShortW18F1011").value;
        const customerLevel = document.getElementById("customerLevelW18F1011").value;
        const competitorW18F1011 = document.getElementById("competitorW18F1011").value;
        const partnerW18F1011 = document.getElementById("partnerW18F1011").value;
        const companyStatus = document.getElementById("companyStatusW18F1011").value;
        const salesPerson = this.state.salesPersonID;
        const caseSource = document.getElementById("caseSourceW18F1011").value;
        const indutryGroup = document.getElementById("indutryGroupW18F1011").value;
        const companyGroup = document.getElementById("companyGroupW18F1011").value;
        const province = document.getElementById("provinceW18F1011").value;
        const provinceName = document.getElementById("provinceW18F1011").options[document.getElementById("provinceW18F1011").selectedIndex].text;
        const district = document.getElementById("districtW18F1011").value;
        const districtName = document.getElementById("districtW18F1011").options[document.getElementById("districtW18F1011").selectedIndex].text;
        const ward = document.getElementById("wardW18F1011").value;
        const wardName = document.getElementById("wardW18F1011").options[document.getElementById("wardW18F1011").selectedIndex].text;
        const quarter = document.getElementById("quarterW18F1011").value;
        const addressLine = document.getElementById("addressLineW18F1011").value;
        const notes = document.getElementById("notesW18F1011").value;
        const telephone = document.getElementById("telephoneW18F1011").value;
        const fax = document.getElementById("faxW18F1011").value;
        const taxNo = document.getElementById("taxNoW18F1011").value;
        const email = document.getElementById("emailW18F1011").value;
        const website = document.getElementById("websiteW18F1011").value;
        const shipPlace = document.getElementById("shipPlaceW18F1011").value;
        const companySizeID = document.getElementById("companySizeW18F1011").value;
        const revenue = document.getElementById("revenueW18F1011").value;
        const isCustomer = this.state.IsCustomer ? "1" : "0";
        const isCompetitor = this.state.IsCompetitor ? "1" : "0";
        const isPartner = this.state.IsPartner ? "1" : "0";
        let dateOfBirth = this.state.dateOfBirth;
        let paramOptionalInfo = '';
        if (mode === 'add') {
            const param = {
                "CompanyName": companyName,
                "CompanyShort": companyShort,
                "CompanyKindID": customerLevel,
                "CompetitorTypeID": competitorW18F1011,
                "PartnerTypeID": partnerW18F1011,
                "CompanyStatus": companyStatus,
                "CaseSourceID": caseSource,
                "CompanyGroupID": companyGroup,
                "SalesPersonID": salesPerson,
                "IndustryGroupID": indutryGroup,
                "ProvinceID": province,
                "ProvinceName": provinceName,
                "DistrictID": district,
                "DistrictName": districtName,
                "WardID": ward,
                "WardName": wardName,
                "Quarter": quarter,
                "AddressLine": addressLine,
                "AddressLineU": addressLine,
                "Notes": notes,
                "NotesU": notes,
                "Telephone": telephone,
                "CompanyFaxNo": fax,
                "Email": email,
                "Website": website,
                "TaxNo": taxNo,
                "DateOfBirth": dateOfBirth,
                "ShipPlace": shipPlace,
                "IsCustomer": isCustomer,
                "IsCompetitor": isCompetitor,
                "IsPartner": isPartner,
            };
            this.props.w18Actions.addNewCompanyW18F1010(param, async (error, data) => {
                this.isSubmit = false;
                if (data) {
                    this.setState({ btnSaveStatus: true });
                    const optionalInfo = this.state.dropdownSelected;
                    for (let i = 0; i < optionalInfo.length; i++) {
                        paramOptionalInfo = {
                            'CompanyTypeID': 'TN',
                            'CompanyID': data.KeyID,
                            'NormID': optionalInfo[i].NormID,
                            'ValueID': optionalInfo[i].ControlType !== 'T' ? document.getElementsByClassName('optionalInfo')[i].value : '',
                            'NotesU': optionalInfo[i].ControlType === 'T' ? document.getElementsByClassName('optionalInfo')[i].value : '',
                            'Type': optionalInfo[i].ControlType
                        };

                        const addOptionalInfo = await W18F1011Async(paramOptionalInfo);
                        if (addOptionalInfo.code !== 200) {
                            Config.popup.show('INFO', 'Có lỗi trong quá trình xử lý dữ liệu');
                            return false
                        }
                    }
                    this.toggleControl();
                    btnSave.classList.add('hide');
                    const { FormID } = this.getInfo();
                    if (FormID === 'W18F1012' || FormID === 'dashboard') {
                        browserHistory.push({
                            pathname: Config.getRootPath() + FormID,
                            state: { ...this.props.location.state, CompanyID: data.KeyID, mode: this.props.location.state.modeW18F1012, customerNew: { CompanyID: data.KeyID, CompanyNameU: data.CompanyName } }
                        });
                        return;
                    }
                    if (FormID === 'W18F1061') {
                        const { modeW18F1061, CaseCode, oldFormID, permission } = this.getInfo();
                        browserHistory.replace({
                            pathname: Config.getRootPath() + FormID,
                            state: { companyID: data.KeyID, mode: modeW18F1061, CaseCode: CaseCode, companyName: data.CompanyName, telephone: data.Telephone, email: data.Email, address: data.AddressLineU, FormID: oldFormID, permission: permission }
                        });
                        return;
                    }
                    if (mode !== 'edit') {
                        const btnNext = document.getElementById('btnNextW18F1011');
                        if (btnNext) btnNext.classList.remove('hide');
                    }
                } else {
                    Config.popup.show('INFO', "Có lỗi trong quá trình xử lý dữ liệu");
                    return false;
                }
            });
            notify('Dữ liệu lưu thành công', "success", 2000);
        } else {
            const param = {
                "CompanyID": companyID,
                "RevenueID": revenue,
                "CompanySizeID": companySizeID,
                "CompanyName": companyName,
                "CompanyShort": companyShort,
                "CompanyKindID": customerLevel,
                "CompetitorTypeID": competitorW18F1011,
                "PartnerTypeID": partnerW18F1011,
                "CompanyStatus": companyStatus,
                "CaseSourceID": caseSource,
                "CompanyGroupID": companyGroup,
                "SalesPersonID": salesPerson,
                "IndustryGroupID": indutryGroup,
                "ProvinceID": province,
                "ProvinceName": provinceName,
                "DistrictID": district,
                "DistrictName": districtName,
                "WardID": ward,
                "WardName": wardName,
                "Quarter": quarter,
                "AddressLine": addressLine,
                "AddressLineU": addressLine,
                "Notes": notes,
                "NotesU": notes,
                "Telephone": telephone,
                "CompanyFaxNo": fax,
                "Email": email,
                "Website": website,
                "TaxNo": taxNo,
                "DateOfBirth": dateOfBirth,
                "ShipPlace": shipPlace,
                "IsCustomer": isCustomer,
                "IsCompetitor": isCompetitor,
                "IsPartner": isPartner,
            };
            this.props.w18Actions.editCompanyW18F1010(param, (error, data) => {
                if (error) {
                    Config.popup.show('INFO', 'Có lỗi trong quá trình xử lý dữ liệu');
                    return false
                } else if (data) {
                    this.setState({ btnSaveStatus: true });
                }
            });
            let paramDelete = {
                'CompanyID': companyID
            };
            this.props.w18F1011Actions.deleteNorm(paramDelete, async (error, data) => {
                this.isSubmit = false;
                if (error) {
                    Config.popup.show('INFO', 'Có lỗi trong quá trình xử lý dữ liệu');
                    return false
                } else {
                    this.setState({ btnSaveStatus: true });
                    if (mode === "edit" && !_.isEmpty(this.state.objectID)) this.handlePushNotify();
                    const optionalInfo = this.state.dropdownSelected;
                    for (let i = 0; i < optionalInfo.length; i++) {
                        paramOptionalInfo = {
                            'CompanyTypeID': 'TN',
                            'CompanyID': companyID,
                            'NormID': optionalInfo[i].NormID,
                            'ValueID': optionalInfo[i].ControlType !== 'T' ? document.getElementsByClassName('optionalInfo')[i].value : '',
                            'NotesU': optionalInfo[i].ControlType === 'T' ? document.getElementsByClassName('optionalInfo')[i].value : '',
                            'Type': optionalInfo[i].ControlType
                        };

                        const editOptionalInfo = await W18F1011Async(paramOptionalInfo);
                        if (editOptionalInfo.code !== 200) {
                            Config.popup.show('INFO', 'Có lỗi trong quá trình xử lý dữ liệu');
                            return false

                        }

                    }
                }
            });
            notify('Dữ liệu lưu thành công', "success", 2000);
        }
    };

    setDateOfBirth(e) {
        this.setState({
            dateOfBirth: e.value
        })
    }

    removeControl(e) {
        const dropdownSelected = this.state.dropdownSelected || [];
        const dataDropDown = this.state.dataDropDown || [];
        let selectedFilter = dropdownSelected.filter(function (item) {
            return item.NormID !== e
        });
        for (let i = 0; i < dataDropDown.length; i++) {
            if (dataDropDown[i].NormID === e) {
                dataDropDown[i].Hide = false;
            }
        }
        this.setState({
            dropdownSelected: selectedFilter,
            dataDropDown: dataDropDown
        })
    }

    onIsCustomerChanged(e) {
        this.setState({
            IsCustomer: e.value
        });
    }

    onIsCompetitorChanged(e) {
        this.setState({
            IsCompetitor: e.value
        });
    }

    onIsPartnerChanged(e) {
        this.setState({
            IsPartner: e.value
        });
    }

    comeBack() {
        const { modeW18F1061, type, FormID, CaseCode } = this.getInfo();
        if (FormID === "W18F1012" || FormID === "dashboard") {
            browserHistory.push({
                pathname: Config.getRootPath() + FormID,
                state: { ...this.props.location.state, mode: this.props.location.state.modeW18F1012, customerNew: { CompanyID: '', CompanyNameU: '' } }
            });
            return;
        }
        if (FormID === 'W18F1061') {
            browserHistory.replace({
                pathname: Config.getRootPath() + FormID,
                state: { mode: modeW18F1061, CaseCode: CaseCode }
            });
            return;
        }
        if (type === 'Customers') {
            browserHistory.push(Config.env.url + '/W18F1010?type=Customers');
        } else if (type === 'Competitor') {
            browserHistory.push(Config.env.url + '/W18F1010?type=Competitor');
        } else {
            browserHistory.push(Config.env.url + '/W18F1010?type=Partner');
        }
    }

    toggleControl(mode = true) {
        document.getElementById('companyNameW18F1011').disabled = mode;
        document.getElementById('customerLevelW18F1011').disabled = mode;
        document.getElementById('competitorW18F1011').disabled = mode;
        document.getElementById('partnerW18F1011').disabled = mode;
        document.getElementById('emailW18F1011').disabled = mode;
        document.getElementById('companyStatusW18F1011').disabled = mode;
        document.getElementById('telephoneW18F1011').disabled = mode;
        document.getElementById('faxW18F1011').disabled = mode;
        // document.getElementById('salesPersonW18F1011').disabled = mode;
        document.getElementById('addressLineW18F1011').disabled = mode;
        document.getElementById('notesW18F1011').disabled = mode;
        document.getElementById('provinceW18F1011').disabled = mode;
        document.getElementById('districtW18F1011').disabled = mode;
        document.getElementById('wardW18F1011').disabled = mode;
        document.getElementById('quarterW18F1011').disabled = mode;
        document.getElementById('caseSourceW18F1011').disabled = mode;
        document.getElementById('companyGroupW18F1011').disabled = mode;
        document.getElementById('revenueW18F1011').disabled = mode;
        document.getElementById('companySizeW18F1011').disabled = mode;
        document.getElementById('websiteW18F1011').disabled = mode;
        document.getElementById('taxNoW18F1011').disabled = mode;
        document.getElementById('optionalControlW81F1011').disabled = mode;
        document.getElementById('companyShortW18F1011').disabled = mode;
        document.getElementById('indutryGroupW18F1011').disabled = mode;
        document.getElementById('shipPlaceW18F1011').disabled = mode;
        this.setState({
            disabledSP: mode
        });

        const dateOfBirthW18f1011 = document.getElementById('dateOfBirthW18f1011');
        const IsCompetitor = document.getElementById('IsCompetitor');
        const IsPartner = document.getElementById('IsPartner');

        if (mode) {
            dateOfBirthW18f1011.classList.add('dx-state-disabled');
            IsCompetitor.classList.add('dx-state-disabled');
            IsPartner.classList.add('dx-state-disabled');
        } else {
            dateOfBirthW18f1011.classList.remove('dx-state-disabled');
            IsCompetitor.classList.remove('dx-state-disabled');
            IsPartner.classList.remove('dx-state-disabled');

        }
        const optionalControl = document.getElementsByClassName('optionalInfo');
        let i = 0;
        for (i; i < optionalControl.length; i++) {
            optionalControl[i].disabled = mode;
        }
    }

    nextData() {
        this.toggleControl(false);
        const btnNext = document.getElementById('btnNextW18F1011');
        btnNext.classList.add('hide');
        const btnSave = document.getElementById('btnSaveW18F1011');
        btnSave.classList.remove('hide');
        const { type } = this.getInfo();
        let isCustomer = '';
        let isCompetitor = '';
        let isPartner = '';
        if (type === 'Customers') {
            isCustomer = true;
        } else {
            isCustomer = false;
        }
        if (type === 'Competitor') {
            isCompetitor = true;
        } else {
            isCompetitor = false;
        }
        if (type === 'Partner') {
            isPartner = true;
        } else {
            isPartner = false;
        }

        this.setState({
            dataMain: 1,
            addressLine: '',
            quarter: '',
            provinceID: '',
            provincename: '',
            districtID: '',
            districtname: '',
            wardID: '',
            wardname: '',
            dateOfBirth: null,
            companyName: '',
            companyShort: '',
            companyKindID: '',
            competitorTypeID: '',
            partnerTypeID: '',
            competitorTypeName: '',
            partnerTypeName: '',
            companyStatusID: '',
            salesPersonID: Config.profile.SalesPersonID,
            industryGroupID: '',
            notes: '',
            telephone: '',
            fax: '',
            email: '',
            website: '',
            taxNo: '',
            shipPlace: '',
            caseSourceID: '',
            companyGroupID: '',
            companySizeID: '',
            IsCustomer: isCustomer,
            IsCompetitor: isCompetitor,
            IsPartner: isPartner,
            revenueID: '',
            dropdownSelected: []
        })

    }

    renderRequired = (name = "") => {
        let status = "";
        const { requiredField } = this.state;
        if (requiredField.findIndex(item => item?.FieldName === name && item?.ValidMode === "O") > -1) status = <span className={'text-red'}> (*)</span>;
        return status;
    };

    render() {
        if (!this.getInfo(true)) return null;
        const { mode, type, FormID } = this.getInfo();
        const { disabledSP } = this.state;
        const {
            cboCompanyStatusW18F1011, cboCaseSourceW18F1011,
            cboCompanyGroupW18F1011,
            cboIndutryGroupW18F1011, cboProvinceW18F1011, cboDistrictW18F1011, cboWardW18F1011,
            cboCustomerLevelW18F1011, cboCompanySizeW18F1011, cboRevenueW18F1011, cboCompetitorW18F1011, cboPartnerW18F1011
        } = this.props;
        const btnSaveStatus = (this.state.isSaved && (FormID === 'W18F1012' || FormID === 'dashboard') && mode === 'add') || this.state.btnSaveStatus;
        let titleGird = '';
        let titleLable = '';
        if (type === 'Customers') {
            titleGird = Config.lang("CRM_Khach_hang");
            titleLable = Config.lang("CRM_Ten_khach_hang");
        } else if (type === 'Competitor') {
            titleGird = Config.lang("CRM_Doi_thu");
            titleLable = Config.lang("CRM_Ten_doi_thu");
        } else {
            titleGird = Config.lang("CRM_Doi_tac");
            titleLable = Config.lang("CRM_Ten_doi_tac");
        }
        if (!this.state.dataMain && mode === 'edit') return null;

        return (
            <div className="page-container" style={{ margin: 0 }}>
                {Config && Config.profile &&
                    <EventTracking category={"TRACKING_USER"} action={"W18F1011"} label={Config.profile.UserID} />}

                <div className="grid-title" style={{ marginLeft: spacing(3) }}>{titleGird}</div>
                <Form horizontal={true} id={'frmW18F1011'} style={{ margin: spacing([0, 4]) }}>
                    <Row>
                        <Col md={12} sm={12} xl={12} xs={12} lg={12}>
                            <ButtonCustom color={'text-orange'} icon={'fas fa-arrow-alt-left'} isAwesome={true}
                                name={Config.lang("CRM_Quay_lai")}
                                onClick={() => {
                                    this.comeBack()
                                }} />
                            {mode !== 'view' &&
                                <ButtonSave id={'btnSaveW18F1011'}
                                    className={'mgl5'}
                                    name={Config.lang("CRM_Luu")}
                                    disabled={btnSaveStatus}
                                    onClick={() => this.saveData()} />}
                            <ButtonCustom icon={'fas fa-plus'} isAwesome={true}
                                id={'btnNextW18F1011'}
                                className={'hide'}
                                onClick={() => this.nextData()}
                                color={'text-black'} name={Config.lang("CRM_Nhap_tiep")}
                            />
                        </Col>
                        <Col md={12} sm={12} xl={12} xs={12} lg={12}>
                            <span onClick={() => this.toggle1()}><i
                                className={this.state.collapse1 ? 'fas fa-caret-down fa-lg ' : 'fas fa-caret-right fa-lg '} /> <label
                                    className={'text-blue'}>&nbsp;{Config.lang("CRM_Thong_tin_chung")}</label></span>
                        </Col>
                        <Col md={12} sm={12} xl={12} xs={12} lg={12}>
                            <Collapse in={this.state.collapse1}>
                                <div>
                                    <Row>
                                        <Col md={2} sm={2} xs={6} lg={2}><label
                                            className={"control-label"}>{titleLable}<span
                                                className={'text-red'}> (*)</span></label></Col>
                                        <Col md={10} sm={10} xs={6} lg={10}>
                                            <input id={'companyNameW18F1011'} className={'form-control'}
                                                onChange={(e) => {
                                                    this.setState({
                                                        companyName: e.target.value
                                                    })
                                                }}
                                                value={this.state.companyName}
                                            />
                                        </Col>
                                        {type && <>
                                            <Col md={2} sm={2} xs={6} lg={2} className={type === 'Customers' ? '' : 'hide'}><label
                                                className="control-label">{Config.lang("CRM_Phan_loai")}
                                                {this.renderRequired("CompanyKindID")}</label></Col>
                                            <Col md={4} sm={4} xs={6} lg={4} className={type === 'Customers' ? '' : 'hide'}>
                                                <select className={'form-control'}
                                                    id={'customerLevelW18F1011'}
                                                    disabled={mode === 'view'}
                                                    onChange={(e) => {
                                                        this.setState({
                                                            companyKindID: e.target.value
                                                        })
                                                    }}
                                                    value={this.state.companyKindID}
                                                >
                                                    <option value={''} />
                                                    {cboCustomerLevelW18F1011 && cboCustomerLevelW18F1011.length > 0 && cboCustomerLevelW18F1011.map((value) => {
                                                        return (<option key={value.CustomerLevelID}
                                                            value={value.CustomerLevelID}>{value.CustomerLevelName}</option>)
                                                    })}
                                                </select>
                                            </Col>
                                        </>}
                                        {type && <>
                                            <Col md={2} sm={2} xs={6} lg={2} className={type === 'Competitor' ? '' : 'hide'}><label
                                                className="control-label">{Config.lang("CRM_Phan_loai_doi_thu")}
                                                {this.renderRequired("CompetitorTypeID")}</label></Col>
                                            <Col md={4} sm={4} xs={6} lg={4} className={type === 'Competitor' ? '' : 'hide'}>
                                                <select className={'form-control'}
                                                    id={'competitorW18F1011'}
                                                    disabled={mode === 'view'}
                                                    onChange={(e) => {
                                                        this.setState({
                                                            competitorTypeID: e.target.value
                                                        })
                                                    }
                                                    }
                                                    value={this.state.competitorTypeID}
                                                >
                                                    <option value={''} />
                                                    {cboCompetitorW18F1011 && cboCompetitorW18F1011.length > 0 && cboCompetitorW18F1011.map((value) => {
                                                        return (<option key={value.ItemID}
                                                            value={value.ItemID}>{value.DescriptionU}</option>)
                                                    })}
                                                </select>
                                            </Col>
                                        </>}
                                        {type && <>
                                            <Col md={2} sm={2} xs={6} lg={2} className={type === 'Partner' ? '' : 'hide'}><label
                                                className="control-label">{Config.lang("CRM_Phan_loai_doi_tac")}
                                                {this.renderRequired("PartnerTypeID")}</label></Col>
                                            <Col md={4} sm={4} xs={6} lg={4} className={type === 'Partner' ? '' : 'hide'}>
                                                <select className={'form-control'}
                                                    id={'partnerW18F1011'}
                                                    disabled={mode === 'view'}
                                                    onChange={(e) => {
                                                        this.setState({
                                                            partnerTypeID: e.target.value
                                                        })
                                                    }}
                                                    value={this.state.partnerTypeID}
                                                >
                                                    <option value={''} />
                                                    {cboPartnerW18F1011 && cboPartnerW18F1011.length > 0 && cboPartnerW18F1011.map((value) => {
                                                        return (<option key={value.ItemID}
                                                            value={value.ItemID}>{value.DescriptionU}</option>)
                                                    })}
                                                </select>
                                            </Col>
                                        </>}
                                        <Col md={2} sm={2} xs={6} lg={2}><label className={"control-label"}>Email
                                            {this.renderRequired("Email")}</label></Col>
                                        <Col md={4} sm={4} xs={6} lg={4}>
                                            <input id={'emailW18F1011'}
                                                className={'form-control'}
                                                disabled={mode === 'view'}
                                                onChange={(e) => {
                                                    this.setState({
                                                        email: e.target.value
                                                    })
                                                }}
                                                value={this.state.email} />
                                        </Col>
                                        <Col md={2} sm={2} xs={6} lg={2}><label
                                            className="control-label">{Config.lang("CRM_Trang_thai")}
                                            {this.renderRequired("CompanyStatus")}</label></Col>
                                        <Col md={4} sm={4} xs={6} lg={4}>
                                            <select className={'form-control'}
                                                id={'companyStatusW18F1011'}
                                                disabled={mode === 'view'}
                                                onChange={(e) => {
                                                    this.setState({
                                                        companyStatusID: e.target.value
                                                    })
                                                }}
                                                value={this.state.companyStatusID}

                                            >
                                                <option value={''} />
                                                {cboCompanyStatusW18F1011 && cboCompanyStatusW18F1011.length > 0 && cboCompanyStatusW18F1011.map((value) => {
                                                    return (
                                                        <option key={value.ItemID}
                                                            value={value.ItemID}>{value.DescriptionU}</option>
                                                    )
                                                })}
                                            </select>
                                        </Col>
                                        <Col md={2} sm={2} xs={6} lg={2}><label
                                            className={'control-label'}>{Config.lang("CRM_Dien_thoai")}
                                            {this.renderRequired("Telephone")}</label></Col>
                                        <Col md={4} sm={4} xs={6} lg={4}>
                                            <input id={'telephoneW18F1011'}
                                                disabled={mode === 'view'}
                                                className={'form-control'}
                                                onKeyUp={(e) => this.checkValidate('Telephone', e.target.value)}
                                                onChange={(e) => {
                                                    this.setState({
                                                        telephone: e.target.value
                                                    })
                                                }}
                                                value={this.state.telephone} />
                                        </Col>
                                        <Col md={2} sm={2} xs={6} lg={2}><label
                                            className={'control-label'}>{Config.lang("CRM_So_fax")}
                                            {this.renderRequired("CompanyFaxNo")}</label></Col>
                                        <Col md={4} sm={4} xs={6} lg={4}>
                                            <input id={'faxW18F1011'}
                                                disabled={mode === 'view'}
                                                className={'form-control'}
                                                onChange={(e) => {
                                                    this.setState({
                                                        fax: e.target.value
                                                    })
                                                }}
                                                value={this.state.fax} />
                                        </Col>
                                        <Col md={2} sm={2} xs={6} lg={2}><label
                                            className={'control-label'}>{Config.lang("CRM_Nhan_vien")}
                                            {this.renderRequired("SalesPersonID")}</label></Col>
                                        <Col md={4} sm={4} xs={6} lg={4}>
                                            <DropDownSalePerson
                                                id={"salesPersonW18F1011"}
                                                showClearButton={true}
                                                placeholder={Config.lang("CRM_Nhan_vien_kinh_doanh")}
                                                value={this.state.salesPersonID}
                                                disabled={disabledSP}
                                                onChange={(e) => {
                                                    const salesPersonID = e ? e.SalesPersonID : null;
                                                    this.setState({
                                                        salesPersonID: salesPersonID
                                                    })
                                                }} />
                                        </Col>
                                        <Col md={2} sm={2} xs={6} lg={2}><label
                                            className={"control-label"}>{Config.lang("CRM_Dia_chi_DKKD")}
                                            {this.renderRequired("AddressLineU")}</label></Col>
                                        <Col md={10} sm={10} xs={6} lg={10}>
                                            <input id={'addressLineW18F1011'}
                                                className={'form-control'}
                                                disabled={mode === 'view'}
                                                onChange={(e) => this.setState({ addressLine: e.target.value })}
                                                value={this.state.addressLine}
                                            />
                                        </Col>
                                        <Col md={2} sm={2} xs={6} lg={2}><label
                                            className={'control-label'}>{Config.lang("CRM_Dia_diem_giao_dich")}
                                            {this.renderRequired("ShipPlace")}</label></Col>
                                        <Col md={10} sm={10} xs={6} lg={10}>
                                            <input id={'shipPlaceW18F1011'}
                                                className={'form-control'}
                                                disabled={mode === 'view'}
                                                onChange={(e) => {
                                                    this.setState({
                                                        shipPlace: e.target.value
                                                    })
                                                }}
                                                value={this.state.shipPlace} />
                                        </Col>
                                        <Col md={2} sm={2} xs={6} lg={2}><label
                                            className={'control-label'}>{Config.lang("CRM_Mo_ta")}
                                            {this.renderRequired("NotesU")}</label></Col>
                                        <Col md={10} sm={10} xs={6} lg={10}>
                                            <textarea id={'notesW18F1011'} className={'form-control'} rows={4}
                                                disabled={mode === 'view'}
                                                onChange={(e) => {
                                                    this.setState({
                                                        notes: e.target.value
                                                    })
                                                }}
                                                value={this.state.notes} />
                                        </Col>
                                    </Row>
                                </div>
                            </Collapse>
                        </Col>
                        <Col md={12} sm={12} xs={12} lg={12}>
                            <span onClick={() => this.toggle3()}><i
                                className={this.state.collapse3 ? 'fas fa-caret-down fa-lg ' : 'fas fa-caret-right fa-lg '} /><label
                                    className={'text-blue'}>&nbsp;&nbsp;{Config.lang("CRM_Vi_tri_dia_ly")}</label></span>
                        </Col>
                        <Col md={12} sm={12} xs={12} lg={12}>
                            <Collapse in={this.state.collapse3}>
                                <div>
                                    <Row>
                                        <Col md={2} sm={2} xs={6} lg={2}><label
                                            className={'control-label'}>{Config.lang("CRM_Tinh")}/{Config.lang("CRM_Thanh_pho")}
                                            {this.renderRequired("ProvinceID")}</label></Col>
                                        <Col md={4} sm={4} xs={6} lg={4} className={'mgb15'}>
                                            <select className={'form-control'}
                                                id={'provinceW18F1011'}
                                                disabled={mode === 'view'}
                                                onChange={(e) => {
                                                    // this.changeAddress();
                                                    this.setState({ provincename: e.target.selectedOptions[0].text });
                                                    document.getElementById("districtW18F1011").value = "";
                                                    document.getElementById("wardW18F1011").value = "";
                                                    document.getElementById("quarterW18F1011").value = "";
                                                    this.setState({
                                                        addressLine: `${e.target.selectedOptions[0].text}`,
                                                        provinceID: e.target.value
                                                    });
                                                    this.loadDataProvinceDistrict({
                                                        ProvinceID: e.target.value,
                                                    });

                                                }}
                                                value={this.state.provinceID}>
                                                <option value={''} />
                                                {cboProvinceW18F1011 && cboProvinceW18F1011.length > 0 && cboProvinceW18F1011.map((value) => {
                                                    return (
                                                        <option key={value.ItemID}
                                                            value={value.ItemID}>{value.ItemName}</option>)
                                                })}
                                            </select>
                                        </Col>
                                        <Col md={2} sm={2} xs={6} lg={2}><label
                                            className={'control-label'}>{Config.lang("CRM_Quan")}/{Config.lang("CRM_Huyen")}
                                            {this.renderRequired("DistrictID")}</label></Col>
                                        <Col md={4} sm={4} xs={6} lg={4}>
                                            <select className={'form-control'}
                                                id={'districtW18F1011'}
                                                disabled={mode === 'view'}
                                                onChange={(e) => {
                                                    this.setState({
                                                        districtname: e.target.selectedOptions[0].text,
                                                        districtID: e.target.value
                                                    }, () => {
                                                        this.loadDataWard(this.state.districtID);
                                                        this.changeAddress();
                                                    })
                                                }}
                                                value={this.state.districtID}>
                                                <option value={''} />
                                                {cboDistrictW18F1011 && cboDistrictW18F1011.length > 0 && cboDistrictW18F1011.map((value) => {
                                                    return (
                                                        <option key={value.ItemID}
                                                            value={value.ItemID}>{value.ItemName}</option>)
                                                })}
                                            </select>
                                        </Col>
                                        <Col md={2} sm={2} xs={6} lg={2}><label
                                            className={'control-label'}>{Config.lang("CRM_Xa")}/{Config.lang("CRM_Phuong")}
                                            {this.renderRequired("WardID")}</label></Col>
                                        <Col md={4} sm={4} xs={6} lg={4} className={'mgb15'}>
                                            <select className={'form-control'}
                                                id={'wardW18F1011'}
                                                disabled={mode === 'view'}
                                                value={this.state.wardID}
                                                onChange={(e) => this.setState({
                                                    wardname: e.target.selectedOptions[0].text,
                                                    wardID: e.target.value
                                                }, () => {
                                                    this.changeAddress()
                                                })}
                                            >
                                                <option value={''} />
                                                {cboWardW18F1011 && cboWardW18F1011.length > 0 && cboWardW18F1011.map((value) => {
                                                    return (
                                                        <option key={value.ItemID}
                                                            value={value.ItemID}>{value.ItemName}</option>)
                                                })}
                                            </select>
                                        </Col>
                                        <Col md={2} sm={2} xs={6} lg={2}><label
                                            className={'control-label'}>{Config.lang("CRM_Ap")}/{Config.lang("CRM_Khu_pho")}
                                            {this.renderRequired("Quarter")}</label></Col>
                                        <Col md={4} sm={4} xs={6} lg={4} className={'mgb15'}>
                                            <input className={'form-control'}
                                                id={'quarterW18F1011'}
                                                disabled={mode === 'view'}
                                                value={this.state.quarter}
                                                onChange={(e) => this.setState({ quarter: e.target.value }, () => {
                                                    this.changeAddress()
                                                })}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            </Collapse>
                        </Col>
                        <Col md={12} sm={12} xl={12} xs={12} lg={12}>
                            <span onClick={() => this.toggle2()}><i
                                className={this.state.collapse2 ? 'fas fa-caret-down fa-lg ' : 'fas fa-caret-right fa-lg '} /><label
                                    className={'text-blue'}>&nbsp;&nbsp;{Config.lang("CRM_Thong_tin_bo_sung")}</label></span>
                        </Col>
                        <Col md={12} sm={12} xl={12} xs={12} lg={12}>
                            <Collapse in={this.state.collapse2}>
                                <div>
                                    <Row>
                                        <Col md={2} sm={2} xs={6} lg={2}><label
                                            className={'control-label'}>{Config.lang("CRM_Nguon_goc")}
                                            {this.renderRequired("CaseSourceID")}</label> </Col>
                                        <Col md={4} sm={4} xs={6} lg={4} className={'mgb15'}>
                                            <select className={'form-control'}
                                                id={'caseSourceW18F1011'}
                                                disabled={mode === 'view'}
                                                onChange={(e) => {
                                                    this.setState({
                                                        caseSourceID: e.target.value
                                                    })
                                                }}
                                                value={this.state.caseSourceID}

                                            >
                                                <option value={''} />
                                                {cboCaseSourceW18F1011 && cboCaseSourceW18F1011.length > 0 && cboCaseSourceW18F1011.map((value) => {
                                                    return (<option key={value.LookupID}
                                                        value={value.LookupID}>{value.DescriptionU}</option>)
                                                })}
                                            </select>
                                        </Col>
                                        <Col md={2} sm={2} xs={6} lg={2}><label
                                            className={'control-label'}>{Config.lang("CRM_Nhom_khach_hang")}
                                            {this.renderRequired("CompanyGroupID")}</label></Col>
                                        <Col md={4} sm={4} xs={6} lg={4}>
                                            <select className={'form-control'}
                                                id={'companyGroupW18F1011'}
                                                disabled={mode === 'view'}
                                                onChange={(e) => {
                                                    this.setState({
                                                        companyGroupID: e.target.value
                                                    })
                                                }}
                                                value={this.state.companyGroupID}>
                                                <option value={''} />
                                                {cboCompanyGroupW18F1011 && cboCompanyGroupW18F1011.length > 0 && cboCompanyGroupW18F1011.map((value) => {
                                                    return (
                                                        <option key={value.CustomerGroupID}
                                                            value={value.CustomerGroupID}>{value.CustomerGroupName}</option>)
                                                })}
                                            </select>
                                        </Col>
                                        <Col md={2} sm={2} xs={6} lg={2}><label
                                            className={'control-label'}>{Config.lang("CRM_Nhom_nganh_nghe")}
                                            {this.renderRequired("IndustryGroupID")}</label></Col>
                                        <Col md={4} sm={4} xs={6} lg={4} className={'mgb15'}>
                                            <select className={'form-control'}
                                                id={'indutryGroupW18F1011'}
                                                disabled={mode === 'view'}
                                                onChange={(e) => {
                                                    this.setState({
                                                        industryGroupID: e.target.value
                                                    })
                                                }}
                                                value={this.state.industryGroupID}

                                            >
                                                <option value={''} />
                                                {cboIndutryGroupW18F1011 && cboIndutryGroupW18F1011.length > 0 && cboIndutryGroupW18F1011.map((value) => {
                                                    return (<option
                                                        key={value.LookupID}
                                                        value={value.LookupID}>{value.DescriptionU}</option>)
                                                })}
                                            </select>
                                        </Col>
                                        <Col md={2} sm={2} xs={6} lg={2}><label
                                            className={'control-label'}>{Config.lang("CRM_Quy_mo_cong_ty")}
                                            {this.renderRequired("CompanySizeID")}</label></Col>
                                        <Col md={4} sm={4} xs={6} lg={4}>
                                            <select className={'form-control'}
                                                id={'companySizeW18F1011'}
                                                disabled={mode === 'view'}
                                                onChange={(e) => {
                                                    this.setState({
                                                        companySizeID: e.target.value
                                                    })
                                                }}
                                                value={this.state.companySizeID}>
                                                <option value={''} />
                                                {cboCompanySizeW18F1011 && cboCompanySizeW18F1011.length > 0 && cboCompanySizeW18F1011.map((value) => {
                                                    return (
                                                        <option key={value.RefInfoID}
                                                            value={value.RefInfoID}>{value.RefInfoNameU}</option>)
                                                })}
                                            </select>
                                        </Col>
                                        <Col md={2} sm={2} xs={6} lg={2}><label
                                            className={'control-label'}>{Config.lang("CRM_Doanh_thu_nam")}
                                            {this.renderRequired("RevenueID")}</label></Col>
                                        <Col md={4} sm={4} xs={6} lg={4} className={'mgb15'}>
                                            <select className={'form-control'}
                                                id={'revenueW18F1011'}
                                                disabled={mode === 'view'}
                                                onChange={(e) => {
                                                    this.setState({
                                                        revenueID: e.target.value
                                                    })
                                                }}
                                                value={this.state.revenueID}>
                                                <option value={''} />
                                                {cboRevenueW18F1011 && cboRevenueW18F1011.length > 0 && cboRevenueW18F1011.map((value) => {
                                                    return (
                                                        <option key={value.RefInfoID}
                                                            value={value.RefInfoID}>{value.RefInfoNameU}</option>)
                                                })}
                                            </select>
                                        </Col>
                                        <Col md={2} sm={2} xs={6} lg={2}><label
                                            className={'control-label'}>Website
                                            {this.renderRequired("Website")}</label></Col>
                                        <Col md={4} sm={4} xs={6} lg={4}>
                                            <input id={'websiteW18F1011'}
                                                disabled={mode === 'view'}
                                                className={'form-control'}
                                                onChange={(e) => {
                                                    this.setState({
                                                        website: e.target.value
                                                    })
                                                }}
                                                value={this.state.website} />
                                        </Col>
                                        <Col md={2} sm={2} xs={6} lg={2}><label
                                            className={'control-label'}>{Config.lang("CRM_Ma_so_thue")}
                                            {this.renderRequired("TaxNo")}</label></Col>
                                        <Col md={4} sm={4} xs={6} lg={4} className={'mgb15'}>
                                            <input id={'taxNoW18F1011'}
                                                disabled={mode === 'view'}
                                                className={'form-control'}
                                                onKeyUp={(e) => this.checkValidate('TaxNo', e.target.value)}
                                                onChange={(e) => {
                                                    this.setState({
                                                        taxNo: e.target.value
                                                    })
                                                }}
                                                value={this.state.taxNo} />
                                        </Col>
                                        <Col md={2} sm={2} xs={6} lg={2}><label
                                            className={'control-label'}>{Config.lang("CRM_Ngay_thanh_lap")}
                                            {this.renderRequired("DateOfBirth")}</label></Col>
                                        <Col md={4} sm={4} xs={6} lg={4}>
                                            {<DateBox
                                                width={"100%"}
                                                dateSerializationFormat={'yyyy-MM-ddTHH:mm:ss'}
                                                ref={(ref) => this.dateOfBirth = ref}
                                                id={'dateOfBirthW18f1011'}
                                                openOnFieldClick={true}
                                                useMaskBehavior={true}
                                                showClearButton={true}
                                                value={this.state.dateOfBirth}
                                                type={'date'}
                                                disabled={mode === 'view'}
                                                displayFormat={'d/MM/y'}
                                                onValueChanged={(e) => this.setDateOfBirth(e)}
                                            />}
                                        </Col>
                                        <Col md={2} sm={2} xs={6} lg={2}><label
                                            className="control-label">{Config.lang("CRM_Ten_tat")}
                                            {this.renderRequired("CompanyShort")}</label></Col>
                                        <Col md={4} sm={4} xs={6} lg={4} className={'mgb15'}>
                                            <input id={'companyShortW18F1011'} type={'text'} className={'form-control'}
                                                value={this.state.companyShort}
                                                onChange={(e) => {
                                                    this.setState({
                                                        companyShort: e.target.value
                                                    })
                                                }}
                                                disabled={mode === 'view'} />
                                        </Col>
                                        <Col md={6} sm={6} xs={12} lg={6}>
                                            <div style={style.inputControl}>
                                                <CheckBox
                                                    text={Config.lang("CRM_Khach_hang")}
                                                    id={"IsCustomer"}
                                                    className={'control-label'}
                                                    disabled={type && type === 'Customers'}
                                                    onValueChanged={(e) => this.onIsCustomerChanged(e)}
                                                    value={this.state.IsCustomer}
                                                />
                                                <CheckBox
                                                    text={Config.lang("CRM_Doi_thu")}
                                                    id={"IsCompetitor"}
                                                    className={'control-label'}
                                                    disabled={type && type === 'Competitor'}
                                                    onValueChanged={(e) => this.onIsCompetitorChanged(e)}
                                                    value={this.state.IsCompetitor}
                                                />
                                                <CheckBox
                                                    text={Config.lang("CRM_Doi_tac")}
                                                    id={"IsPartner"}
                                                    className={'control-label'}
                                                    disabled={type && type === 'Partner'}
                                                    onValueChanged={(e) => this.onIsPartnerChanged(e)}
                                                    value={this.state.IsPartner}
                                                />
                                            </div>
                                        </Col>
                                        <Col md={12} sm={12} xl={12} xs={12} lg={12}>
                                            <Dropdown id={'optionalControlW81F1011'} className={'pull-right'}
                                            >
                                                <DropdownToggle title={Config.lang("CRM_Thong_tin_phu")} open={true} />
                                                <DropdownMenu>
                                                    {this.state.dataDropDown && this.state.dataDropDown.map((value) => {
                                                        if (value.Hide) return null;
                                                        return (
                                                            <MenuItem key={value.NormID} onClick={() => this.selectDropDown(
                                                                {
                                                                    NormID: value.NormID,
                                                                    NormNameU: value.NormNameU,
                                                                    ControlType: value.ControlType,
                                                                })}>{value.NormNameU} </MenuItem>

                                                        )

                                                    })}
                                                </DropdownMenu>
                                            </Dropdown>
                                        </Col>
                                        {this.state.dropdownSelected && this.state.dropdownSelected.map((value) => {
                                            let controlType;
                                            if (value.ControlType === 'T') {
                                                controlType =
                                                    <input className={'form-control optionalInfo'} type={'text'}
                                                        defaultValue={value.Value} />;
                                            } else {
                                                controlType =
                                                    <select className={'form-control optionalInfo'} defaultValue={value.Value}>
                                                        {value.DataComBo.length && value.DataComBo.map((value) => {
                                                            return (
                                                                <option key={value.ValueID}
                                                                    value={value.ValueID}>{value.DescriptionU}</option>)
                                                        })}
                                                    </select>;
                                            }
                                            return (
                                                <Col key={value.NormID} xs={12}>
                                                    <Row>
                                                        <Col md={2} sm={2} xs={6} lg={2}>
                                                            <span>
                                                                <i id={value.NormID}
                                                                    style={{ cursor: 'pointer' }}
                                                                    className="delete-icon fas fa-trash-alt text-red"
                                                                    onClick={() => this.removeControl(value.NormID)} />
                                                                <label
                                                                    className={'control-label'}>&nbsp;{value.NormNameU}</label>
                                                            </span>
                                                        </Col>
                                                        <Col md={10} sm={10} xs={6} lg={10}>
                                                            {controlType}
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            )
                                        })}
                                    </Row>
                                </div>
                            </Collapse>
                        </Col>
                    </Row>
                </Form>
            </div>

        )
    }
}

export default connect(state => ({
    cboCompanyStatusW18F1011: state.w18.cboCompanyStatusW18F1011,
    cboCaseSourceW18F1011: state.w18.cboCaseSourceW18F1011,
    cboCompanyGroupW18F1011: state.w18.cboCompanyGroupW18F1011,
    cboGroupSalesW18F1011: state.w18.cboGroupSalesW18F1011,
    cboSalesPersonW18F1011: state.w18.cboSalesPersonW18F1011,
    cboIndutryGroupW18F1011: state.w18.cboIndutryGroupW18F1011,
    cboProvinceW18F1011: state.w18.cboProvinceW18F1011,
    cboDistrictW18F1011: state.w18.cboDistrictW18F1011,
    cboWardW18F1011: state.w18.cboWardW18F1011,
    dataGridW18F1011: state.w18.dataGridW18F1011,
    cboCustomerLevelW18F1011: state.w18.cboCustomerLevelW18F1011,
    cboNormW18F1011: state.w18.cboNormW18F1011,
    cboDataW18F1011: state.w18.cboDataW18F1011,
    cboCompanySizeW18F1011: state.w18.cboCompanySizeW18F1011,
    cboRevenueW18F1011: state.w18.cboRevenueW18F1011,
    cboCompetitorW18F1011: state.w18.cboCompetitorW18F1011,
    cboPartnerW18F1011: state.w18.cboPartnerW18F1011,
    getCompanyNameW18F1011: state.w18.getCompanyNameW18F1011,
}
),
    (dispatch) => ({
        w18Actions: bindActionCreators(w18Actions, dispatch),
        w18F1011Actions: bindActionCreators(w18F1011Actions, dispatch),

    })
)(W18F1011Page);
