/**
 * @copyright 2019 @ DigiNet
 * @author VINHLE
 * @create 2019/01/17 10:37
 * @update 2019/01/17 10:37
 * @file src/components/header/header.js
 */

import React from 'react';
import {connect} from "react-redux";
import {Form,FormText,FormGroup,Col,Input,Label}from 'reactstrap';
import 'devextreme/dist/css/dx.common.css';
import EventTracking from "../common/tracking/event";

import ButtonSave from "../common/button/button-save";
import ButtonCustom from "../common/button/button-custom";
import {browserHistory} from "react-router";
import Config from "../../config";

class W18F1003Page extends React.PureComponent {

    render(){
        return(
            <div className="page-container">
                {Config && Config.profile && <EventTracking category={"TRACKING_USER"} action={"W18F1003"} label={Config.profile.UserID} /> }

                <div className="lb-cus-title">Danh mục/Phân loại khách hàng</div>
                <FormGroup row>
                    <Col md={12} sm={12} xl={12} xs={12} lg={12}>
                        <ButtonCustom icon={'fas fa-arrow-alt-left'} isAwesome={true} name={'Quay lại'} onClick={() => {
                            browserHistory.push(Config.env.url + '/W18F1002')
                        }}/>
                        <ButtonSave name={'Lưu'} onClick={() => {

                        }}/>
                    </Col>

                </FormGroup>
                <Form>
                    <FormGroup row>
                        <Label for="exampleEmail" md={3} sm={3} xl={3} xs={3} lg={3}>Tên phân loại khách hàng <span className="text-red">(*)</span></Label>
                        <Col md={9} sm={9} xl={9} xs={9} lg={9}>
                            <Input type="email" name="email" id="exampleEmail"  />
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Label for="exampleText" md={3} sm={3} xl={3} xs={3} lg={3}>Mô tả</Label>
                        <Col  md={9} sm={9} xl={9} xs={9} lg={9}>
                            <Input type="textarea" name="text" id="exampleText" />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="exampleFile" md={3} sm={3} xl={3} xs={3} lg={3}>Cấp độ</Label>
                        <Col  md={4} sm={4} xl={4} xs={4} lg={4}>
                            <Input  name="file" id="exampleFile" />

                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="exampleFile" md={3} sm={3} xl={3} xs={3} lg={3}>Tỷ lệ chiết khấu</Label>
                        <Col  md={4} sm={4} xl={4} xs={4} lg={4}>
                            <Input  name="file" id="exampleFile" />

                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Label for="checkbox2" md={3} sm={3} xl={3} xs={3} lg={3}>Ngừng hoạt động</Label>
                        <Col md={9} sm={9} xl={9} xs={9} lg={9}>
                            <FormGroup check>
                                <Label check>
                                    <Input type="checkbox" id="checkbox2" />{' '}
                                </Label>
                            </FormGroup>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col md={12} sm={12} xl={12} xs={12} lg={12}>
                            <FormText className={''}>Created at</FormText>
                        </Col>

                    </FormGroup>
                    <FormGroup row>
                        <Col md={12} sm={12} xl={12} xs={12} lg={12}>
                            <FormText className={''}>Last modified  at</FormText>
                        </Col>

                    </FormGroup>

                </Form>


                <div>
                </div>
            </div>
        )
    }
}

export default connect(state => ({
        // loadW18F1002: state.w18.loadW18F1002
    }),
    (dispatch) => ({
        // w18Actions: bindActionCreators(w18Actions, dispatch)
    })
)(W18F1003Page);