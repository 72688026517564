/**
 * @copyright 2019 @ DigiNet
 * @author VINHLE
 * @create 2019/01/17 10:37
 * @update 2019/01/17 10:37
 * @file src/components/header/header.js
 */

import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import Config from "../../config";
import {Row,Col,Input,InputGroup,InputGroupAddon,InputGroupText,Label}from 'reactstrap';
import DataGrid, {
    Column,
    Editing,
    Pager,
    Paging,
    FilterRow,
    Selection
} from 'devextreme-react/data-grid';
import 'devextreme/dist/css/dx.common.css';
import EventTracking from "../common/tracking/event";
import * as w18Actions from "../../actions/w18-actions";
import ButtonAdd from "../common/button/button-add";
import {browserHistory} from "react-router";
const LOGIN_LANG_VN = {
    INPUT_REQUIRED: "Vui lòng nhập đầy đủ thông tin.",
    USERNAME_REQUIRED: "Tên đăng nhập không được để trống.",
    PASSWORD_REQUIRED: "Mật khẩu không được để trống."
};
const LOGIN_LANG_EN = {
    INPUT_REQUIRED: "Vui lòng nhập đầy đủ thông tin.",
    USERNAME_REQUIRED: "Tên đăng nhập không được để trống.",
    PASSWORD_REQUIRED: "Mật khẩu không được để trống."
};
class W18F1002Page extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            message: "",
            isSubmit: false,
            eUsernameFlag: null,
            ePasswordFlag: null
        };
    };

    onLogout() {
        if(this.props.onLogout) {
            this.props.onLogout();
        }
    };

    getLanguage(name) {
        let language = {};
        const currentLanguage = "vn";

        if (currentLanguage === "vn") {
            language = LOGIN_LANG_VN;
        }
        if (currentLanguage === "en") {
            language = LOGIN_LANG_EN;
        }

        if (language[name]) {
            return language[name];
        } else {
            return null;
        }
    }

    componentDidMount() {
        const param = {
            "skip" : 0,
            "limit" : 10,
            "select": "*"
        };
        this.props.w18Actions.loadW18F1002(param, (error, data) => {
            if(error){
                let errorCode = error.code || null;
                let message = "";
                switch (errorCode) {
                    case "US004":
                        message = "Tài khoản đăng nhập hoặc mật khẩu không đúng.";
                        break;
                    default:
                        message = error.message || "Lỗi chưa xác định";
                        Config.popup.show('INFO',message);
                        break;
                }

                this.setState({isSubmit: false, message: message});
                return false;
            }
        });
    }

    render(){
        const {loadW18F1002} = this.props;

        let dataSource = [];

        if (loadW18F1002) {
            loadW18F1002.map(o=>{
                const item = {Discount:o.Discount,CustomerlevelName: o.CustomerlevelName, CustomerGroupName: o.CustomerlevelName, CustomerTypeName: o.Discount, Description: o.Description, Disabled:o.Disabled===1?true:false };
                dataSource.push(item);
                return o;
            });
        }

        return(
            <div className="page-container">
                {Config && Config.profile && <EventTracking category={"TRACKING_USER"} action={"W18F1002"} label={Config.profile.UserID} /> }

                <div className="lb-cus-title">Danh mục/Phân loại khách hàng</div>
                        <Row className="form-group">
                            <Col md={1} sm={1} xl={1} xs={1} lg={1}>
                                <Label className="control-label">Tìm kiếm</Label>
                            </Col>
                            <Col md={4} sm={4} xl={4} xs={4} lg={4} >
                                <InputGroup>
                                    <Input/>
                                    <InputGroupAddon addonType={'prepend'}>
                                        <InputGroupText className={'fas fa-search'}/>
                                    </InputGroupAddon>
                                </InputGroup>
                            </Col>
                        </Row>
                <Row className="form-group">
                    <Col md={12} sm={12} xl={12} xs={1} lg={12}>
                        <ButtonAdd name={'Thêm mới'} onClick={()=>{
                            browserHistory.push(Config.env.url + '/W18F1003')
                        }}/>
                    </Col>
                </Row>

                        <Row className="form-group">
                            <Col  md={12} sm={12} xl={12} xs={12} lg={12}>
                                <DataGrid
                                    showBorders={true}
                                    dataSource={dataSource}
                                    showColumnLines={true}
                                    showRowLines={true}
                                    rowAlternationEnabled={true}
                                >
                                    <FilterRow visible={true}/>
                                    <Paging enabled={true}/>
                                    <Selection mode={'single'} allowSelectAll={'none'}/>
                                    <Pager visible={true} showInfo={true} showNavigationButtons={true} showPageSizeSelector={true} infoText={'Trang {0} của {1} ({2} Dòng)'}/>

                                    <Editing
                                        mode={'row'}
                                        showEditorAlways={false}
                                    >
                                    </Editing>
                                    <Column dataField={'CustomerlevelName'} caption={'Levelname'} width={200} />
                                    <Column dataField={'Discount'} caption={'Discout'} width={200}/>
                                    <Column dataField={'Description'} caption={'Description'} width={500}/>
                                    <Column dataField={'Level'} caption={'Level'} dataType={'date'} />
                                    <Column dataField={'Disabled'} dataType={'boolean'}    caption={'Disabled'} width={100} />
                                    <Column type={'buttons'} caption={'Hành động'}  width={150}
                                            buttons={['edit',  {
                                                icon: 'trash',
                                                onClick: this.cloneIconClick
                                            },]}
                                    />
                                </DataGrid>
                            </Col>
                        </Row>
                <div>
                </div>
            </div>
        )
    }
}

export default connect(state => ({
        loadW18F1002: state.w18.loadW18F1002
    }),
    (dispatch) => ({
        w18Actions: bindActionCreators(w18Actions, dispatch)
    })
)(W18F1002Page);