/**
 * @copyright 2019 @ DigiNet
 * @author rocachien
 * @create 2019/02/15 10:25
 * @update 2019/02/15 10:25
 */

import React, {Component} from 'react';
import {Column} from "devextreme-react/data-grid";
import GridContainer from "../common/grid-container/grid-container";
import connect from "react-redux/es/connect/connect";
import {bindActionCreators} from "redux";
import * as w18F1018Actions from "../../actions/w18/w18F1018/w18F1018-actions";
import * as userActions from "../../actions/user-actions";
import {Col, FormGroup} from "react-bootstrap";
import Config from "../../config";
import EventTracking from "../common/tracking/event";
import ButtonApprove from "../common/button/button-approve";
import GridActionBar from "../common/action-bar/grid-action-bar";
import saveAsyns from "../../actions/w18/w18F1018/add-async";
import notify from "devextreme/ui/notify";
import PopupScroll from "../popup/popup-scroll";


class W18F1018 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showPopup: false,
        };
        this.filter = {
            skip: 0,
            limit: 20,
            search: ''
        };
        this.popup = {
            skip: 0,
            limit: 10,
            search: ''
        };
        this.data = []
    }

    onHide() {
        this.setState({
            showPopup: false
        });
    }

    componentDidMount = async () => {
        if (this.props.iPermission <= 0) return;
        this.loadDataMaster();
    };

    onChangePage(page) {
        this.filter.skip = page * this.filter.limit;
        this.loadDataMaster();
    }

    onChangePerPage(per) {
        this.filter.skip = 0;
        this.filter.limit = per;
        this.loadDataMaster();
    }

    onSearch(text) {
        this.filter.skip = 0;
        this.filter.search = text;
        this.loadDataMaster();
    }


    onPopChangePage(page) {
        this.popup.skip = page * this.popup.limit;
        this.loadDataPopUp();
    }

    onPopChangePerPage(per) {
        this.popup.skip = 0;
        this.popup.limit = per;
        this.loadDataPopUp();
    }

    onPopSearch(text) {
        this.popup.skip = 0;
        this.popup.search = text;
        this.loadDataPopUp();
    }

    loadDataMaster() {
        const uName = Config.profile.UserID || '';
        const uLanguage = Config.language || '';
        const {companyID} = this.props;
        const params = {
            "UserID": uName,
            "Language": uLanguage,
            "CompanyID": companyID,
            "StrSearch": this.filter.search,
            "Mode": 0,
            "skip": this.popup.skip,
            "limit": this.popup.limit,
        };

        this.props.w18F1018Actions.loadMaster(params, () => {
        });
    }

    loadDataPopUp() {
        const uName = Config.profile.UserID || '';
        const divisionID = Config.profile.DivisionID || '';
        const params = {
            "UserID": uName,
            "FormID": 'W18F1018',
            "DivisionID": divisionID,
            "StrSearch": this.popup.search,
            "WareHouseID": '',
            "skip": this.popup.skip,
            "limit": this.popup.limit,
        };
        this.props.w18F1018Actions.loadGridW18F1018(params, () => {
        });
    }

    componentWillUnmount() {
        this.popup.search = '';
        this.popup.skip = 0;
        this.popup.limit = 10;
    }

    renderActionActivity(e) {
        if (!e) return null;
        const {isPermission, companyID} = this.props;


        return (
            <GridActionBar
                classNameIs={isPermission}
                className={isPermission >= 4 ? '' : 'isDisabled'}
                isPer={isPermission}
                tooltipDelete={"Xóa"}
                onDeleteAction={() => {
                    const param = {
                        Code: e.data.ICode10ID,
                        Type: 'ICode10',
                        CompanyID: companyID
                    };
                    Config.popup.show("YES_NO", Config.lang("CRM_Ban_co_chac_chan_muon_xoa_du_lieu_nay"), () => {
                        this.props.w18F1018Actions.deleteProduct(param, (error) => {
                            if (error) {
                                let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                                Config.popup.show('INFO', message);
                                return false;
                            } else {
                                this.loadDataMaster();
                                notify(Config.lang("CRM_Du_lieu_da_xoa_thanh_cong"), "success", 500);
                            }
                        })
                    })
                }}/>
        );
    }

    selectionChange(rowData) {
        this.data = rowData;
    }

    async saveData() {
        let param;
        const data = this.data;
        const {companyID} = this.props;

        if (data.length <= 0) {
            Config.popup.show('INFO', Config.lang("CRM_Ban_chua_chon_nhom_hang"));
            return false;
        }
        for (let i = 0; i < data.length; i++) {
            param = {
                Code: data[i].ICode10ID,
                Name: data[i].ICode10Name,
                Type: 'ICode10',
                CompanyID: companyID,
            };
            const saveData = await saveAsyns(param);

            if (saveData.code !== 200) {
                Config.popup.show('INFO', Config.lang("CRM_Loi_chua_xac_dinh"));
                return false;
            }
            notify(Config.lang("CRM_Luu_thanh_cong"), "success", 500);
            this.loadDataMaster();
        }

    }


    render() {
        const {dataMaster, dataPopUp, isPermission, disabledAction,} = this.props;

        return (
            <div>
                {this.state.showPopup && <PopupScroll
                    title={Config.lang("CRM_Chon_nhom_hang")}
                    show={true}
                    animation={true}
                    onHide={() => this.onHide()}

                >
                    {Config && Config.profile &&
                    <EventTracking category={"TRACKING_USER"} action={"W18F1018"} label={Config.profile.UserID}/>}

                    <div className="page-container">
                        <div id={'frmW18F1018'}>
                            <FormGroup className={'mgt5'}>
                                <Col md={12} sm={12} xs={12} lg={12}>
                                    <ButtonApprove onClick={() => {
                                        this.saveData()
                                    }} name={Config.lang("CRM_Dong_y")}/>
                                </Col>
                            </FormGroup>
                            <FormGroup>
                                <Col md={12} sm={12} xs={12} lg={12}>
                                    <GridContainer
                                        // allowAdding={this.state.isAllow}
                                        dataSource={dataPopUp}
                                        selection={{mode: 'multiple'}}
                                        onChangePage={(page) => {
                                            this.onPopChangePage(page)
                                        }}
                                        onChangePerPage={(per) => {
                                            this.onPopChangePerPage(per)
                                        }}
                                        onSearch={(text) => {
                                            this.onPopSearch(text)
                                        }}
                                        onSelectionChanged={(e, rowData) => {
                                            this.selectionChange(rowData);
                                        }}
                                        totalItems={dataPopUp ? dataPopUp.length > 0 ? dataPopUp[0].TotalRecord : 0 : 0}
                                    >
                                        <Column
                                            dataField={'ICode10ID'}
                                            caption={Config.lang("CRM_Ma_nhom_hang")}
                                            alignment={'center'}
                                            allowSorting={false}
                                            showInColumnChooser={false}
                                            width={120}
                                            fixed={true}
                                        />
                                        <Column
                                            dataField={'ICode10Name'}
                                            caption={Config.lang("CRM_Ten_nhom_hang")}
                                            allowSorting={true}
                                            allowReordering={false}
                                            width={600}
                                            fixed={true}
                                        />

                                    </GridContainer>
                                </Col>
                            </FormGroup>
                        </div>
                    </div>
                </PopupScroll>}
                <GridContainer
                    allowSearch ={disabledAction ? "isDisabled" : ""}
                    disabledAddNew ={disabledAction}
                    allowAdding={this.state.isPer}
                    dataSource={dataMaster}
                    isPer={isPermission}
                    onAddNew={() => {
                        this.popup.limit = 10;
                        this.popup.skip = 0;
                        this.loadDataPopUp();
                        this.setState({
                            showPopup: true
                        })
                    }}
                    totalItems={dataMaster ? dataMaster.length > 0 ? dataMaster[0].TotalRecord : 0 : 0}
                    itemPerPage={this.filter.limit}
                    onSearch={(text) => {
                        this.onSearch(text)
                    }}
                    onChangePage={(page) => {
                        this.onChangePage(page)
                    }}
                    onChangePerPage={(per) => {
                        this.onChangePerPage(per)
                    }}
                >
                    <Column
                        cellRender={(e) => this.renderActionActivity(e)}
                        dataField={'Action'}
                        caption={Config.lang("CRM_Hanh_dong")}
                        alignment={'center'}
                        allowSorting={false}
                        showInColumnChooser={false}
                        width={120}
                        fixed={true}
                    />
                    <Column
                        dataField={'ICode10ID'}
                        caption={Config.lang("CRM_Ma_nhom_hang")}
                        alignment={'center'}
                        allowSorting={false}
                        showInColumnChooser={false}
                        width={120}
                    />
                    <Column
                        dataField={'ICode10Name'}
                        caption={Config.lang("CRM_Ten_nhom_hang")}
                        allowSorting={true}
                        allowReordering={false}
                    />
                </GridContainer>
            </div>
        );
    }
}

W18F1018.propTypes = {};
export default connect(state => ({
        dataMaster: state.w18f1018.dataMaster,
        dataPopUp: state.w18f1018.dataPopUp
    }),
    (dispatch) => ({
        w18F1018Actions: bindActionCreators(w18F1018Actions, dispatch),
        userActions: bindActionCreators(userActions, dispatch),
    })
)(W18F1018);
