/**
 * @copyright 2019 @ DigiNet
 * @author XUANVINH
 * @create 2019/01/11 10:37
 * @update 2019/01/11 10:37
 * @file src/components/header/header.js
 */

import React from 'react';
import {Col, Form, FormGroup, Image, Row,} from 'react-bootstrap';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import Attachment from "../common/attachment/attachment";
import ButtonSave from "../common/button/button-save";
import ButtonCustom from "../common/button/button-custom";
import Config from "../../config";
import * as w18F1014Actions from "../../actions/w18F1014-actions";
import PopupView from "../popup/popup-view";
import * as fileActions from "../../actions/file-actions";
import * as w17Actions from "../../actions/w17-actions";
import FileUploadAsync from "../../actions/file-upload-new-async";
import FileRemoveAsync from "../../actions/file-remove-async";
import FileDeleteAsync from "../../actions/file-delete-async";
import EventTracking from "../common/tracking/event";
import {DateBox} from "devextreme-react";
import notify from 'devextreme/ui/notify';
import W18F1015PopUp from "../W18/W18F1015";
import Combo from "../common/dropdown/combo";
import {Column} from "devextreme-react/data-grid";
import {Loading} from "../common/loading/loading";
import AnimateHeight from 'react-animate-height';


import moment from "moment";
import 'moment/locale/vi'
import TextareaAutosize from 'react-autosize-textarea';
import {browserHistory} from "react-router";
import * as w18Actions from "../../actions/w18-actions";

moment.locale('vi');

const cssForm = {margin: '0 15px 0 15px'};

class W18F1014Page extends React.PureComponent {
    constructor(props) {
        super(props);
        this.taskDateFrom = null;
        this.next = false;
        this.taskDateTo = null;
        this.finishDate = null;

        this.state = {
            resetContact: false,
            taskType: '',
            showW18f1015: false,
            dataAttach: [],
            dateStart: '',
            dateFinish: '',
            showAttachment: false,
            finishDate: null,
            taskStatus: null,
            companyID: null,
            companyName: null,
            salesPersonName: null,
            salesPersonID: null,
            disabled: false,
            attached: [], //Du lieu da dinh kem
            toggleSave: null,
            showComment: false,
            idEdit: null,
            dataComment:[]
        };
        this.OldAttachData = [];//Du lieu da dinh kem cu bi xoa;
        this.upLoadAttach = this.upLoadAttach.bind(this);
    }

    componentDidMount () {

        this.loadDataPriority();
        this.loadDataTaskStatus();
        this.loadDataTaskType();
        if (this.props.loadData) {
            this.props.loadData(async (data) => {
                if (!data) {
                    this.onHide();
                    return;
                }
                let companyID, companyName, finishDate, taskStatus, salesPersonName, salesPersonID, taskType, salesPersonURL, taskID;
                let toggleSave = false;
                let disabled = false;
                this.mode = data.mode;
                if (this.mode === 'edit' || this.mode === 'view') {
                    companyName = data.rowData.CompanyName;
                    companyID = data.rowData.CompanyID;
                    finishDate = data.rowData.FinishDate;
                    taskStatus = data.rowData.TaskStatus;
                    salesPersonName = data.rowData.SalesPersonName;
                    salesPersonID = data.rowData.SalesPersonID;
                    taskType = data.rowData.TaskType;
                    taskID = data.rowData.TaskID;
                    if (salesPersonID !== Config.profile.SalesPersonID) {
                        toggleSave = true
                    }
                    this.loadComment({FormID:'W18F1014', CodeID:taskID});
                } else if (this.mode === 'add') {
                    companyID = data.rowData.CompanyID;
                    companyName = data.rowData.CompanyName;
                    taskStatus = 'NEW';
                    salesPersonName = Config.profile.SalesPersonNameU || '';
                    salesPersonURL = Config.profile.URL;
                    salesPersonID = Config.profile.SalesPersonID || '';
                }

                if (this.mode === 'view') {
                    disabled = true;
                }

                this.setState({
                    taskType: taskType,
                    finishDate: finishDate,
                    taskStatus: taskStatus,
                    companyID: companyID,
                    companyName: companyName,
                    salesPersonName: salesPersonName,
                    salesPersonURL: salesPersonURL,
                    salesPersonID: salesPersonID,
                    toggleSave: toggleSave,
                    disabled: disabled,
                    taskID: taskID,
                });
                this.loadDataContact(companyID);
                // this.loadDataPriority();
                // this.loadDataTaskStatus();
                // this.loadDataTaskType();
                if (this.mode !== 'add') {
                    this.taskStatusW18F1014.setValueDropDown(taskStatus);
                    this.taskContactW18F1014.setValueDropDown(data.rowData.ContactID);
                    this.loadDataAttach(data.rowData.TaskID);
                } else {
                    this.taskStatusW18F1014.setValueDropDown('NEW');
                }
            });
        }

        const {showComment} = this.props;
        if(showComment) {
            this.setState({
                showComment: showComment
            });
        }
    }

    loadDataAttach(taskID) {
        const param = {
            "KeyID": taskID,
        };
        this.props.w18F1014Actions.dataAttachedW18F1014(param, (error, data) => {
            if (error) {
                let errorCode = error.code || null;
                let message = "";
                switch (errorCode) {
                    case "US004":
                        message = "Tài khoản đăng nhập hoặc mật khẩu không đúng.";
                        break;
                    default:
                        message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                        break;
                }
                Config.popup.show('INFO', message);
            } else {
                if (data) {
                    this.setState({
                        attached: data
                    })
                }
            }
        });
    }

    loadDataContact(companyID) {
        const param = {
            "CompanyID": companyID,
        };
        this.props.w18F1014Actions.cboContactW18F1014(param, (error) => {

            this.setState({loading: false});
            if (error) {
                let errorCode = error.code || null;
                let message = "";
                switch (errorCode) {
                    case "US004":
                        message = "Tài khoản đăng nhập hoặc mật khẩu không đúng.";
                        break;
                    default:
                        message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                        break;
                }
                Config.popup.show('INFO', message);
            }
        });
    }

    async deleteAttach(file) {
        const {dataAttach} = this.state;
        let arr = dataAttach.filter((value) => {
            return value.FileName !== file.FileName;
        });

        this.setState({
            dataAttach: arr
        });

        // if (file.complete) {
        //     this.OldAttachData.push(file);
        // } else {
        //     let paramRemoveAttach;
        //     paramRemoveAttach = {
        //         'path': file.URL
        //     };
        //     const resRemove = await FileRemoveAsync(paramRemoveAttach);
        //     if (resRemove.code !== 200) {
        //         Config.popup.show('INFO', Config.lang("CRM_Co_loi_khi_xoa_dinh_kem"));
        //         return false;
        //     }
        // }
    }

    loadDataPriority() {
        this.props.w18F1014Actions.cboPriorityW18F1014({}, (error) => {

            this.setState({loading: false});
            if (error) {
                let errorCode = error.code || null;
                let message = "";
                switch (errorCode) {
                    case "US004":
                        message = "Tài khoản đăng nhập hoặc mật khẩu không đúng.";
                        break;
                    default:
                        message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                        break;
                }
                Config.popup.show('INFO', message);
            }
        });
    }

    loadComment = (params, cb) => {
        this.props.w18F1014Actions.getListComment(params, (err, data)=>{
            this.setState({
                dataComment: data && data.data && data.data.length > 0 ? data.data : []
            },()=>{
                cb && cb();
                setTimeout(()=>{
                    this.scrollToBottom();
                }, 500)
            })
        });
    };

    loadDataTaskStatus() {
        this.props.w18F1014Actions.cboTaskStatusW18F1014({}, (error, data) => {

            this.setState({loading: false});
            if (error) {
                let errorCode = error.code || null;
                let message = "";
                switch (errorCode) {
                    case "US004":
                        message = "Tài khoản đăng nhập hoặc mật khẩu không đúng.";
                        break;
                    default:
                        message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                        break;
                }
                Config.popup.show('INFO', message);
            }
            if (this.props.loadData) {
                this.props.loadData((data) => {
                    if (data.length > 0 && data.mode !== 'edit') {
                        this.setState({
                            taskStatus: 'NEW'
                        })
                    }
                })
            }
        });
    }

    hideW18F1015(companyID, companyName) {
        this.setState({
            showW18f1015: false,

        }, () => {
            if (companyID) {
                this.setState({
                    companyName: companyName,
                    companyID: companyID,
                    resetContact: true,
                })

            }
        })
    }

    loadActionTabW18F1012=(TaskID)=>{
        const { data } = this.props;
        const params = {
            UserID: Config.profile.UserID,
            Language: Config.language || 84,
            TaskID: TaskID || '',
            CompanyID: data?.CompanyID || '',
            skip: 0,
            limit: 1,
            StrSearch: '',
            FFormID: data?.params?.FFormID || '',
            DataType: data?.unRedirect ? 'Savetask' : 'LoadGrid',
            KeyID: data?.params?.KeyID || '',
        }
        this.props.w18Actions.loadActionTabW18F1012(params);
    }


    saveDataW18F1014() {
        const {data} = this.props;
        const dataAttach = [...this.state.dataAttach];
        const companyID = this.state.companyID;
        const OldAttachData = this.OldAttachData;
        const mode = this.mode;
        const salesPersonID = this.state.salesPersonID;
        const title = document.getElementById('titleW18F1014').value.trim();
        let taskType = this.state.taskType ? this.state.taskType : (data.rowData && data.rowData.TaskType ? data.rowData.TaskType : '');
        let taskID = '';
        if (mode === 'edit') {
            taskID = data.rowData.TaskID;
        }
        if (title === "") {
            Config.popup.show('INFO', Config.lang("CRM_Ten_khong_duoc_de_trong"));
            document.getElementById("titleW18F1014").focus();
            return false;
        }
        if (!taskType) {
            Config.popup.show('INFO', `${Config.lang("CRM_Loai_cong_viec_khong_duoc_de_trong")}`);
            document.getElementById("taskTypeW18F1014").focus();
            return false;
        }
        let taskDateTo = '';
        if (this.taskDateTo.instance.option('value')) {
            taskDateTo = moment(this.taskDateTo.instance.option('value')).format('YYYY-MM-DD HH:mm:ss.SSS');

        } else {
            Config.popup.show('INFO', Config.lang("CRM_Han_xu_ly_khong_duoc_de_trong"));
            return false;
        }
        let taskDateFrom = '';
        if (this.taskDateFrom.instance.option('value')) {
            taskDateFrom = moment(this.taskDateFrom.instance.option('value')).format('YYYY-MM-DD HH:mm:ss.SSS');
            taskDateTo = moment(this.taskDateTo.instance.option('value')).format('YYYY-MM-DD HH:mm:ss.SSS');
            if (taskDateTo < taskDateFrom) {
                Config.popup.show('INFO', Config.lang("CRM_Han_xu_ly_phai_lon_hon_ngay_bat_dau"));
                return false;
            }
        }

        let finishDate = '';

        if (!this.finishDate.instance.option('value') && this.state.taskStatus === 'COMPLETE') {
            Config.popup.show('INFO', Config.lang("CRM_Ngay_hoan_thanh_khong_duoc_de_trong"));
            return false;

        } else {
            const finishD = this.finishDate.instance.option('value');
            finishDate = finishD ? moment(finishD).format('YYYY-MM-DD HH:mm:ss.SSS') : '';
            if (finishDate && finishDate < taskDateFrom) {
                Config.popup.show('INFO', Config.lang("CRM_Ngay_hoan_thanh_phai_lon_hon_ngay_bat_dau"));
                return false;
            }

        }

        const note = document.getElementById('noteW18F1014').value;
        const priority = this.vlPriority;
        const taskStatsus = this.state.taskStatus;

        const contact = this.vlContact;
        const result = document.getElementById('resultW18F1014').value;
        const actEvaluation = document.getElementById('actEvaluatutionW18F1014').value;
        const location = document.getElementById('locationW18F1014').value;
        let paramAttach = '';
        const param = {
            "TaskID": taskID,
            "CompanyID": companyID,
            "TaskDateFrom": taskDateFrom,
            "TaskDateTo": taskDateTo,
            "FinishDate": finishDate,
            "TitleU": title,
            "NotesU": note,
            "Priority": priority,
            "TaskStatus": taskStatsus,
            "TaskType": taskType,
            "ContactID": contact,
            "ResultsU": result,
            "ActEvaluationU": actEvaluation,
            "SalesPersonID": salesPersonID,
            "Location": location,
        };

        if (this.mode === 'add') {
            this.props.w18F1014Actions.addNewTask(param, async (error, res) => {
                if (error) {
                    let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                    Config.popup.show('INFO', message);
                    return false;
                }
                this.loadActionTabW18F1012(res.KeyID);
                this.setState({ toggleSave: true}); 
                let uploadError = {is: false, message: Config.lang("CRM_Co_loi_khi_dinh_kem")};
                if (dataAttach.length > 0) {
                    let attached = [...this.state.attached];
                    let isAttachmentUpdate = false;
                    for (let i = 0; i < dataAttach.length; i++) {
                        const exp = dataAttach[i].file.name.split('.');
                        paramAttach = {
                            'KeyID': res.KeyID,
                            'FileName': dataAttach[i].file.name,
                            'FileSize': dataAttach[i].file.size,
                            'FileExp': exp[exp.length - 1],
                            'TableName': res.TableName
                        };

                        const upload = await FileUploadAsync(dataAttach[i].file, paramAttach);
                        if (upload) {
                            dataAttach[i].AttachmentID = upload.KeyID;
                            attached.push(dataAttach[i]);
                            isAttachmentUpdate = true;
                        }
                        if (upload.code !== 200) {
                            uploadError.is = true;
                        }

                    }
                    if (isAttachmentUpdate) {
                        this.setState({
                            dataAttach: [],
                            attached: attached
                        });
                    }
                }
                this.setState({
                    disabled: true,
                    toggleSave: false
                });
                if (this.props.loadGrid) this.props.loadGrid();
                if (uploadError.is) {
                    Config.popup.show('INFO', uploadError.message);
                } else {
                    notify(Config.lang("CRM_Luu_thanh_cong"), "success", 2000);
                }

            });
        } else {
            this.props.w18F1014Actions.editTask(param, async (error) => {
                if (error) {
                    let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                    Config.popup.show('INFO', message);
                    return false;
                }
                this.setState({
                    toggleSave: true,
                    disabled: true,
                });
                let uploadError = {is: false, message: Config.lang("CRM_Co_loi_khi_dinh_kem")};
                if (dataAttach.length > 0) {
                    let attached = [...this.state.attached];
                    let isAttachmentUpdate = false;
                    for (let i = 0; i < dataAttach.length; i++) {
                        const exp = dataAttach[i].file.name.split('.');
                        paramAttach = {
                            'KeyID': data.rowData.TaskID,
                            'FileName': dataAttach[i].file.name,
                            'FileSize': dataAttach[i].file.size,
                            'FileExp': exp[exp.length - 1],
                            'TableName': 'D17T2110'
                        };
                        const upload = await FileUploadAsync(dataAttach[i].file, paramAttach);
                        if (upload) {
                            dataAttach[i].AttachmentID = upload.KeyID;
                            attached.push(dataAttach[i]);
                            isAttachmentUpdate = true;
                        }
                        if (upload.code !== 200) {
                            uploadError.is = true;
                        }
                    }
                    if (isAttachmentUpdate) {
                        this.setState({
                            dataAttach: [],
                            attached: attached,
                            disabled: false
                        });
                    }
                }

                if (OldAttachData.length > 0) {
                    let paramDeleteAttach;
                    let paramRemoveAttach;
                    for (let i = 0; i < OldAttachData.length; i++) {
                        paramDeleteAttach = {
                            'AttachmentID': OldAttachData[i].AttachmentID
                        };
                        paramRemoveAttach = {
                            'path': OldAttachData[i].URL
                        };
                        await FileDeleteAsync(paramDeleteAttach);

                        await FileRemoveAsync(paramRemoveAttach);
                    }
                    OldAttachData.length = 0;
                }
                if (this.props.loadGrid) this.props.loadGrid();
                this.setState({
                    disabled: false,
                    toggleSave: false
                });
                if (uploadError.is) {
                    Config.popup.show('INFO', uploadError.message);
                } else {
                    notify(Config.lang("CRM_Luu_thanh_cong"), "success", 2000);
                }
            });

        }

    }

    upLoadAttach = (e) => {
        if (!e) return;
        const files = e?.target?.files || [];
        const sizeLimit = 20;
        if (files.length === 0) {
            return null;
        } else if (this.checkFileType(files[0].type) === false) {
            Config.popup.show("INFO", `${Config.lang("CRM_Dinh_dang_file_khong_hop_le")}`);
        } else if ((files[0].size / 1024 / 1024) > Number(sizeLimit)) {
            Config.popup.show("INFO", `${Config.lang("CRM_Dung_luong_File_khong_duoc_lon_hon")} ${sizeLimit} MB`);
        } else {
            let dataAttach = [...this.state.dataAttach];
            for (let file of files) {
                file.uploading = true;
                dataAttach.push({ file: file, FileName: file.name, URL: window.URL.createObjectURL(file) });
            }
            this.setState({ dataAttach });
        }
    }

    nextDataW18F1014 = () => {
        this.taskStatusW18F1014.setValueDropDown('NEW');
        this.taskContactW18F1014.setValueDropDown(null);
        this.setState({
            next: true,
            taskStatus: 'NEW',
            finishDate: '',
            dataAttach: [],
            attached: [],
            taskType: null,
            disabled: false,
            //mode:'add'
        }, () => {
            this.setState({
                next: false,
            })
        });
        this.mode = 'add';
        this.vlPriority = null;

        document.getElementById('titleW18F1014').value = '';
        document.getElementById('noteW18F1014').value = '';
        document.getElementById('resultW18F1014').value = '';
        document.getElementById('actEvaluatutionW18F1014').value = '';
        document.getElementById('locationW18F1014').value = '';

        this.taskDateFrom.instance.option('value', null);
        this.taskDateTo.instance.option('value', null);
    };

    checkFileType(filetype) {
        const allowedExtensions = [
            'text/plain',
            'image/jpeg',
            'image/png',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/pdf'
        ];
        if (filetype.length > 0) {
            if (allowedExtensions.indexOf(filetype) === -1) {
                return false;
            }
            return true;
        } else {
            return false;
        }

    }

    loadDataTaskType() {
        this.props.w18F1014Actions.cboTaskTypeW18F1014({}, (error) => {

            this.setState({loading: false});
            if (error) {
                let errorCode = error.code || null;
                let message = "";
                switch (errorCode) {
                    case "US004":
                        message = "Tài khoản đăng nhập hoặc mật khẩu không đúng.";
                        break;
                    default:
                        message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                        break;
                }
                Config.popup.show('INFO', message);
            }
        });
    }

    deleteOldAttach(attachment) {

        this.OldAttachData.push(attachment);
        const attached = this.state.attached;
        let arr;
        arr = attached.filter(function (obj) {
            return obj.AttachmentID !== attachment.AttachmentID;
        });
        this.setState({
            attached: arr
        })

    }

    async onHide() {
        this.props.w18F1014Actions.clearAttachment();

        // Call parent close event
        if (this.props.onHide) {
            this.props.onHide()
        }
        let notSaveAttach = this.state.dataAttach;
        notSaveAttach = notSaveAttach.filter((value) => {
            return value.uploading === false && !value.complete
        });
        let paramRemoveAttach;
        for (let i = 0; i < notSaveAttach.length; i++) {
            paramRemoveAttach = {
                'path': notSaveAttach[i].URL
            };
            const resRemove = await FileRemoveAsync(paramRemoveAttach);
            if (resRemove.code !== 200) {

                Config.popup.show('INFO', Config.lang("CRM_Co_loi_khi_luu"));
                return false;
            }
        }
    }

    onChangeTaskStatus(data) {
        if (this.state.taskStatus === data.ID) return;
        this.setState({
            taskStatus: data.ID
        }, () => {
            const newDate = moment().toDate();
            if (this.state.taskStatus === 'COMPLETE') {
                this.setState({
                    finishDate: newDate
                });
            } else {
                this.setState({
                    finishDate: null
                });
            }
        });
    }

    renderIcon = (file, url) => {
        let ext = file ? file.split('.') : '';
        ext = ext[ext.length - 1];
        switch (ext) {
            case 'doc':
                return (<img alt="" src={require("../../images/file/icon-doc.png")}/>);
            case 'docx':
                return (<img alt="" src={require("../../images/file/icon-docx.png")}/>);

            case 'xls':
                return (<img alt="" src={require("../../images/file/icon-xls.png")}/>);
            case 'xlsx':
                return (<img alt="" src={require("../../images/file/icon-xlsx.png")}/>);

            case 'ppt':
                return (<img alt="" src={require("../../images/file/icon-ppt.png")}/>);
            case 'pptx':
                return (<img alt="" src={require("../../images/file/icon-pptx.png")}/>);

            case 'pdf':
                return (<img alt="" src={require("../../images/file/icon-pdf.png")}/>);

            case 'txt':
                return (<img alt="" src={require("../../images/file/icon-txt.png")}/>);

            default:
                return (<img alt="" src={url} className="img-background"/>);
        }
    };

    editComment = (item, text) => {
        this.setState({
            idEdit: null
        });
        const {taskID, dataComment} = this.state;
        const {formID} = this.props;
        this.props.w18F1014Actions.editComment({FormAction: formID ? formID : '', FormID:'W18F1014', CodeID:taskID, CommentID: item.CommentID, Message: text}, (error, data)=>{
            if(data && data.data && data.data.length > 0){
                let dataTemp = [...dataComment];
                dataTemp.forEach((i, idx)=>{
                    if(i.CommentID === data.data[0].id){
                        dataTemp[idx].updatedAt = data.data[0].updatedAt;
                        dataTemp[idx].Message = data.data[0].Message;
                        this.setState({
                            dataComment: dataTemp
                        })
                    }
                });
            }
            else if(error){
                Config.popup.show('INFO', error.message || Config.lang("CRM_Loi_chua_xac_dinh"));
            }
        });
    };

    removeComment = (item) => {
        const {dataComment} = this.state;
        this.props.w18F1014Actions.removeComment({CommentID: item.CommentID}, (error, data)=>{
            if(data && data.data){
                let dataTemp = [...dataComment];
                dataTemp.forEach((i, idx)=>{
                    if(i.CommentID === data.data.id){
                        dataTemp.splice(idx,1);
                        this.setState({
                            dataComment: dataTemp
                        })
                    }
                });
            }
            else if(error){
                Config.popup.show('INFO', error.message || Config.lang("CRM_Loi_chua_xac_dinh"));
            }
            // this.loadComment({FormID:'W18F1014', CodeID:taskID});
        });
    };

    addComment = (txt) => {
        const {taskID, dataComment} = this.state;
        const {formID} = this.props;
        this.props.w18F1014Actions.addComment({FormAction: formID ? formID : '',FormID:'W18F1014', CodeID:taskID, Message: txt}, (err, data)=>{
            if(data && data.data){
                let dataTemp = [...dataComment];
                dataTemp.push(data.data);
                this.setState({
                    dataComment: dataTemp
                },()=>{
                    this.scrollToBottom();
                });
                // this.loadComment({FormID:'W18F1014', CodeID:taskID},()=>{});
                document.querySelector('#inputComment').value="";
            }
            else if (err) {
                Config.popup.show('INFO', err.message || Config.lang("CRM_Loi_chua_xac_dinh"));
            }
        });
    };

    scrollToBottom = () => {
        const parent = document.querySelector('.scroll-popup .page-container');
        const content = document.querySelector('#frmW18F1014');
        if(parent && content) parent.scrollTop=content.scrollHeight;
    };

    onEnterPress = (e) => {
        if(e.keyCode === 13 && !e.shiftKey) {
            e.preventDefault();
            this.addComment(e.target.value);
        }
    };

    addNewCustomer = () => {
        const {data} = this.props;
        Config.popup.show("YES_NO", `${Config.lang("CRM_Them_moi_khach_hang")}`, () => {
            const state = this.props.state;
            state.FormID =  this.props.formID;
            state.mode = 'add';
            state.modeW18F1012 = data.mode;
            state.Permission =  this.props.state.permission;
            state.TaskID =  data.rowData.TaskID;
            if(!state.type) state.type = 'Customers';
            browserHistory.push({
                pathname: Config.getRootPath() + 'W18F1011',
                state: state
            })
        });
    };

    render() {
        const {dataAttach, attached, taskStatus, showComment, idEdit, dataComment, salesPersonID} = this.state;
        const {cboContactW18F1014, cboPriorityW18F1014, cboTaskStatusW18F1014, cboTaskTypeW18F1014, data, formID, iPermission} = this.props;
        let colorStatus = '#004988';
        switch (taskStatus) {
            case 'PROCESS': colorStatus = '#23691d'; break;
            case 'COMPLETE' : colorStatus = '#BC851F'; break;
            default: colorStatus = '#004988';
        }
        let user1;
        if(salesPersonID) user1 = Config.getUser({SalesPersonID: salesPersonID});
        return (
            <PopupView
                styleView={{overflow: 'auto'}}
                title={Config.lang("CRM_Cap_nhat_hoat_dong")}
                className={"popup-size-90 scroll-popup"}
                ref={ref=>{this.popupScroll = ref;}}
                show={true}
                animation={true}
                style={{minHeight: 584}}
                onHide={() => this.onHide()}>
                {Config && Config.profile && data &&
                <EventTracking category={"TRACKING_USER"} action={"W18F1014"} label={Config.profile.UserID}/>}

                {data && data.rowData && <div className="page-container">
                    <Form style={cssForm} horizontal={false} id={'frmW18F1014'}>
                        <FormGroup className={'mgt5'}>
                            <Row>
                                <Col md={12} sm={12} xs={12} lg={12}>
                                    {this.mode !== 'view' &&
                                    <ButtonSave name={'Lưu'} disabled={this.state.toggleSave || this.state.disabled}
                                                onClick={() => this.saveDataW18F1014()}/>}
                                    <ButtonCustom icon={'fas fa-cloud-upload'} isAwesome={true}
                                                  className={this.state.disabled === true ? 'hide mgl5' : 'mgl5'}
                                                  color={'text-green'} name={Config.lang("CRM_Dinh_kem")}
                                                  onClick={() => {
                                                      document.getElementById('attachmentW18F1014').click();
                                                  }}
                                    />
                                    {this.state.showAttachment && <PopupView
                                        title={Config.lang("CRM_Dinh_kem")}
                                        show={true}
                                        animation={true}
                                        onHide={() => this.setState({showAttachment: false})}>
                                        <Attachment data={this.state.row}/>
                                    </PopupView>}
                                    <input id={"attachmentW18F1014"} multiple={"multiple"} type={"file"}
                                    className={"media_upload_input hide"}
                                           onChange={this.upLoadAttach}/>
                                    <ButtonCustom icon={'fas fa-plus'} isAwesome={true}
                                                  className={(this.state.disabled === false || data.mode !== 'add') ? 'hide mgl5' : 'mgl5'}
                                                  onClick={(data) => {
                                                      this.nextDataW18F1014(data)
                                                  }}
                                                  color={'text-black'} name={Config.lang("CRM_Nhap_tiep")}
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={12} sm={8} md={8} lg={8} xl={8}>
                                    <label className={'control-label-above'}>Tên <span
                                        className={'text-red'}>*</span></label>
                                    <input className={'form-control'}
                                           id={'titleW18F1014'}
                                           readOnly={this.state.disabled === true}
                                           defaultValue={data && data.rowData.TitleU} type={'text'}/>
                                </Col>
                                <Col md={4} sm={4} xs={12} lg={4} >
                                    <label className={'control-label-above'}>{Config.lang("CRM_Trang_thai")}<i
                                        className="error">*</i></label>
                                    <Combo
                                        // reset={this.state.next === true}
                                        id={'taskStatusW18F1014'}
                                        ref={ref => this.taskStatusW18F1014 = ref}
                                        placeholder={Config.lang("CRM_Trang_thai")}
                                        disabled={this.state.disabled === true}
                                        height={'auto'}
                                        dataSource={cboTaskStatusW18F1014}
                                        value={this.state.taskStatus}
                                        valueExpr="ID"
                                        displayExpr="Name84"
                                        styleCustom={{
                                            backgroundColor:colorStatus
                                        }}
                                        onActionWhenSelectChange={(data) => {
                                            this.onChangeTaskStatus(data)
                                        }}
                                    >
                                        <Column dataField={'ID'} caption="" visible={false}
                                                dataType={'string'}/>
                                        <Column dataField={'Name84'} caption="" width={'100%'}/>
                                    </Combo>
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Row>
                                        <Col md={8} sm={8} xs={12} lg={8}>
                                            <label
                                                className={'control-label-above'}>{Config.lang("CRM_Dia_diem")}</label>
                                            <input className={'form-control'}
                                                   id={'locationW18F1014'}
                                                   type={'text'}
                                                   readOnly={this.state.disabled === true}
                                                   defaultValue={data && data.rowData.Location}/>
                                        </Col>
                                        <Col className={'control-label'}
                                             xs={12} sm={4} md={4} lg={4} xl={4}>
                                            <label
                                                className={'control-label-above'}>{Config.lang("CRM_Nguoi_thuc_hien")}</label>
                                            <div>
                                                <Image src={user1 ? user1.URL : require('../../images/user3.jpg')}
                                                       style={{
                                                           height: 30,
                                                           width: 30,
                                                           borderRadius: 100,
                                                           margin: '2px 10px 2px 0'
                                                       }}
                                                />
                                                <span id={'salesPersonW18F1014'}>{user1 ? user1.SalesPersonNameU : '---'}</span>
                                            </div>
                                        </Col>
                                        <Col md={8} sm={8} xs={12} lg={8}>
                                            <label className={'control-label-above'}>
                                                {Config.lang("CRM_Khach_hang")}
                                                {(this.mode === 'add' || (this.mode === 'edit' && formID === 'dashboard')) && iPermission > 1 && !data?.unRedirect &&
                                                    <i className="fa fa-plus-circle"
                                                        style={{ color: '#015f97', marginLeft: 10 }}
                                                        onClick={this.addNewCustomer}
                                                    />}
                                            </label>
                                            {this.state.showW18f1015 && !data?.unRedirect &&
                                            <W18F1015PopUp onHide={(data) => {
                                                this.hideW18F1015(data.CompanyID, data.CompanyNameU); //set Customer
                                                this.loadDataContact(data.CompanyID)
                                            }}
                                                           companyID={data && data.rowData && data.rowData.CompanyID ? data.rowData.CompanyID : ''}

                                            />}
                                            <input
                                                onChange={(e) => {
                                                    e.preventDefault();
                                                    this.setState({
                                                        showW18f1015: true
                                                    })
                                                }}
                                                onClick={() => {
                                                    this.setState({
                                                        showW18f1015: true
                                                    })

                                                }}
                                                value={this.state.companyName ? this.state.companyName : ''}
                                                className={'form-control'}
                                                disabled={this.state.disabled === true}/>
                                        </Col>
                                        <Col md={4} sm={4} xs={12} lg={4}>
                                            <label
                                                className={'control-label-above'}>{Config.lang("CRM_Nguoi_lien_he")}</label>
                                            <Combo
                                                reset={this.state.resetContact}
                                                id={'contactW18F1014'}
                                                ref={ref => this.taskContactW18F1014 = ref}
                                                placeholder={Config.lang("CRM_Nguoi_lien_he")}
                                                disabled={this.state.disabled === true}
                                                height={'auto'}
                                                dataSource={cboContactW18F1014}
                                                valueExpr="ContactID"
                                                displayExpr="FullNameU"
                                                onActionWhenSelectChange={(data) => {
                                                    this.setState({
                                                        resetContact: false
                                                    });
                                                    this.vlContact = data.ContactID;
                                                }}
                                            >
                                                <Column dataField={'ContactID'} caption="" visible={false}
                                                        dataType={'string'}/>
                                                <Column dataField={'FullNameU'} caption="" width={'100%'}/>
                                            </Combo>
                                        </Col>
                                        <Col md={8} sm={8} xs={12} lg={8}>
                                            <Col>
                                                <label className={'control-label-above'}>{Config.lang("CRM_Mo_ta")}</label>
                                                <textarea className={'form-control'}
                                                          id={'noteW18F1014'}
                                                          style={{
                                                              height: 84
                                                          }}
                                                          readOnly={this.state.disabled === true}
                                                          defaultValue={data && data.rowData.NotesU} rows={3}/>
                                            </Col>
                                            <Col>
                                                <label className={'control-label-above'}>
                                                    {Config.lang("CRM_Ket_qua")}
                                                </label>
                                                <textarea className={'form-control'}
                                                          id={'resultW18F1014'}
                                                          style={{
                                                              height: 84
                                                          }}
                                                          readOnly={this.state.disabled === true}
                                                          defaultValue={data && data.rowData.ResultsU} rows={3}/>
                                            </Col>
                                            <Col>
                                                <label className={'control-label-above'}>
                                                    {Config.lang("CRM_Danh_gia")}</label>
                                                <textarea className={'form-control'}
                                                          id={'actEvaluatutionW18F1014'}
                                                          style={{
                                                              height: 84
                                                          }}
                                                          readOnly={this.state.disabled === true}
                                                          defaultValue={data && data.rowData.ActEvaluationU} rows={3}/>
                                            </Col>
                                        </Col>
                                        <Col md={4} sm={4} xs={12} lg={4}>
                                            <Col>
                                                <label className={'control-label-above'}>
                                                    {Config.lang("CRM_Do_uu_tien")}
                                                </label>
                                                <Combo
                                                    reset={this.state.next === true}
                                                    id={'priorityW18F1014'}
                                                    ref={ref => this.priorityW18F1014 = ref}
                                                    placeholder={Config.lang("CRM_Do_uu_tien")}
                                                    disabled={this.state.disabled === true}
                                                    height={'auto'}
                                                    dataSource={cboPriorityW18F1014}
                                                    value={data && data.rowData && data.rowData.Priority}
                                                    valueExpr="ID"
                                                    displayExpr="Name84"
                                                    onActionWhenSelectChange={(data) => {
                                                        this.vlPriority = data.ID;
                                                    }}
                                                >
                                                    <Column dataField={'ID'} caption="" visible={false}
                                                            dataType={'string'}/>
                                                    <Column dataField={'Name84'} caption="" width={'100%'}/>
                                                </Combo>
                                            </Col>
                                            <Col>
                                                <label className={'control-label-above'}>{Config.lang("CRM_Loai_cong_viec")}
                                                    <i className="error">*</i>
                                                </label>
                                                <Combo
                                                    reset={this.state.next === true}
                                                    id={'taskTypeW18F1014'}
                                                    ref={ref => this.taskTypeW18F1014 = ref}
                                                    placeholder={Config.lang("CRM_Loai_cong_viec")}
                                                    disabled={this.state.disabled === true}
                                                    dataSource={cboTaskTypeW18F1014}
                                                    value={this.state.taskType}
                                                    valueExpr="ItemID"
                                                    displayExpr="DescriptionU"
                                                    onActionWhenSelectChange={(data) => {
                                                        this.setState({
                                                            taskType: data.ItemID
                                                        });
                                                    }}
                                                >
                                                    <Column dataField={'ItemID'} caption="" visible={false}
                                                            dataType={'string'}/>
                                                    <Column dataField={'DescriptionU'} caption="" width={'100%'}/>
                                                </Combo>
                                            </Col>
                                            <Col>
                                                <label
                                                    className={'control-label-above'}>{Config.lang("CRM_Ngay_bat_dau")}</label>
                                                <DateBox
                                                    disabled={this.state.disabled === true}
                                                    width={"100%"}
                                                    ref={(ref) => this.taskDateFrom = ref}
                                                    id={'taskDateFromW18F014'}
                                                    openOnFieldClick={true}
                                                    useMaskBehavior={true}
                                                    showClearButton={true}
                                                    defaultValue={data && data.rowData.TaskDateFrom}
                                                    type={'datetime'}
                                                    pickerType={"calendar"}
                                                    showAnalogClock={true}
                                                    displayFormat={'d/MM/y hh:mm a'}
                                                />
                                            </Col>
                                            <Col>
                                                <label
                                                    className={'control-label-above'}>{Config.lang("CRM_Ngay_hoan_thanh")}</label>
                                                <DateBox
                                                    width={"100%"}
                                                    disabled={this.state.disabled === true}
                                                    ref={(ref) => this.finishDate = ref}
                                                    id={'finishDateW18F1014'}
                                                    openOnFieldClick={true}
                                                    useMaskBehavior={true}
                                                    showClearButton={true}
                                                    value={this.state.finishDate}
                                                    onValueChanged={(e) => {
                                                        this.setState({
                                                            finishDate: e.value
                                                        })
                                                    }}
                                                    type={'datetime'}
                                                    pickerType={"calendar"}
                                                    showAnalogClock={true}
                                                    displayFormat={'dd/MM/y hh:mm a'}
                                                />
                                            </Col>
                                            <Col>
                                                <label className={'control-label-above'}>{Config.lang("CRM_Han_xu_ly")}<i
                                                    className="error">*</i></label>
                                                <DateBox
                                                    width={"100%"}
                                                    ref={(ref) => this.taskDateTo = ref}
                                                    disabled={this.state.disabled === true}
                                                    id={'taskDateToW18F014'}
                                                    openOnFieldClick={true}
                                                    useMaskBehavior={true}
                                                    showClearButton={true}
                                                    defaultValue={data && data.rowData.TaskDateTo}
                                                    type={'datetime'}
                                                    pickerType={"calendar"}
                                                    showAnalogClock={true}
                                                    displayFormat={'dd/MM/y hh:mm a'}
                                                />
                                            </Col>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={8} sm={8} xs={12} lg={8}>
                                    <label className={'control-label-above'}>
                                        {Config.lang("CRM_Tai_lieu_dinh_kem")}
                                    </label>
                                    <Row md={12} sm={12} xs={12} lg={12}
                                         className={'mg0 display_row list-attach'}>
                                        {(dataAttach.length === 0 && attached.length === 0) && <span
                                            className={'text-no-data'}>{Config.lang('CRM_Khong_co_du_lieu')}</span>}

                                        {attached && attached.map((value, index) => {
                                            return (
                                                <label className={'view-attachment mgr5 control-label small '}
                                                       key={'view-attachment' + index}>
                                                    <a href={Config.env.api + '/file/get?AttachmentID=' + value.AttachmentID}
                                                       download>
                                                        {this.renderIcon(value.FileName, value.URL)}
                                                    </a>
                                                    {value.FileName}
                                                    <i className={this.state.disabled === true ? 'fas fa-trash-alt text-red deleteAttach hide' : 'fas fa-trash-alt text-red deleteAttach'}
                                                       onClick={() => {
                                                           this.deleteOldAttach(value)
                                                       }}
                                                    />
                                                </label>
                                            )
                                        })}

                                        {dataAttach.map((value, index) => {
                                            if (value.uploading) {
                                                return (<div className={'view-loading'}>
                                                    <i key={index}
                                                       className="fas fa-spinner fa-spin"/>
                                                </div>)
                                            } else return (
                                                <label className={'view-attachment mgr5 control-label small '}
                                                       key={'view-attachment1' + index}>
                                                    {this.renderIcon(value.FileName, value.URL)}
                                                    {value.FileName}
                                                    <i className={this.state.disabled === true ? 'fas fa-trash-alt text-red deleteAttach hide' : 'fas fa-trash-alt text-red deleteAttach'}
                                                       onClick={() => {
                                                           this.deleteAttach(value)
                                                       }}/>
                                                </label>
                                            );
                                        })}
                                    </Row>
                                </Col>
                                {
                                    this.mode !== 'add' &&
                                        <Col xs={12} sm={4} md={4} lg={4} xl={4} style={{marginTop: 36}}>
                                            <div className={'note-info-schedule text-muted'}>
                                                <div className={'mgr5'}>{Config.lang('CRM_Ngay_tao')}</div>
                                                <div
                                                    className={'note-info-text'}>{data && moment(data.rowData.CreateDate).format('DD/MM/YYYY  HH:mm:ss')}</div>
                                                <div className={'note-by-schedule'}>
                                                    <div className={'mgr5'}> {Config.lang('CRM_Boi').toLowerCase()}</div>
                                                    <div
                                                        className={'note-info-text'}>{data && data.rowData.CreateUserName}</div>
                                                </div>
                                            </div>
                                            <div className={'note-info-schedule text-muted'}>
                                                <div className={'mgr5'}>{Config.lang('CRM_Ngay_sua')}</div>
                                                <div
                                                    className={'note-info-text'}>{data && moment(data.rowData.LastModifyDate).format('DD/MM/YYYY  HH:mm:ss')}</div>
                                                <div className={'note-by-schedule'}>
                                                    <div className={'mgr5'}> {Config.lang('CRM_Boi').toLowerCase()}</div>
                                                    <div
                                                        className={'note-info-text'}>{data && data.rowData.LastModifyUserName}</div>
                                                </div>
                                            </div>
                                        </Col>
                                }
                                {data && data.rowData.VoucherNum &&
                                    <Col md={8} sm={8} xs={12} lg={8}>
                                        <label className={'control-label-above'}>Link</label>
                                        <input className={'form-control'}
                                            type={'text'}
                                            readOnly
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => data.rowData.Link && !data?.unRedirect && browserHistory.push(Config.getRootPath() + data.rowData.Link)}
                                            defaultValue={data && data.rowData.VoucherNum} />
                                    </Col>
                                }
                                {this.mode !== 'add' &&
                                    <Col md={8} sm={8} xs={12} lg={8}>
                                        <label className={'control-label-above'}
                                               style={{cursor: 'pointer', color: '#0073b7'}}
                                               onClick={() => {
                                                   this.setState({
                                                       showComment: !this.state.showComment
                                                   })
                                               }}
                                        >
                                            <i className={this.state.showComment ? 'fas fa-chevron-circle-up' : 'fas fa-chevron-circle-down'}
                                               style={{marginRight: 10, color: '#0073b7'}}
                                            />
                                            {Config.lang("CRM_Thao_luan")} ({dataComment.length})
                                        </label>
                                        <AnimateHeight
                                            duration={500}
                                            height={showComment ? 'auto' : 0} // see props documentation below
                                        >
                                            {dataComment.map((item, idx) => {
                                                return <ItemComment
                                                    idEdit={idEdit}
                                                    onChangeIdEdit={(id) => {
                                                        this.setState({
                                                            idEdit: id
                                                        })
                                                    }}
                                                    data={item}
                                                    key={idx}
                                                    createdAt={item.createdAt}
                                                    editComment={(txtEdit) => this.editComment(item, txtEdit)}
                                                    addComment={(txtEdit) => this.addComment(txtEdit)}
                                                    removeComment={() => this.removeComment(item)}
                                                />
                                            })}
                                            <Col md={12} sm={12} xs={12} lg={12} className={'display_row'}
                                                 style={{margin: '0 -15px', width: 'calc(100% + 30px)'}}>
                                                <Image src={Config.profile.URL || require('../../images/user3.jpg')}
                                                       style={{
                                                           width: 34,
                                                           height: 34,
                                                           borderRadius: 100,
                                                           marginRight: 10
                                                       }}
                                                />
                                                <TextareaAutosize className={'form-control'}
                                                          // ref={ref => this.inputComment = ref}
                                                            id={'inputComment'}
                                                          onKeyDown={this.onEnterPress}
                                                          style={{width: '100%', minHeight:35, borderRadius: 4}}
                                                          placeholder={Config.lang('CRM_Viet_binh_luan')}
                                                />
                                            </Col>
                                        </AnimateHeight>
                                    </Col>
                                }
                            </Row>
                        </FormGroup>
                    </Form>
                </div>}
                {(!data || !data.rowData) && <Loading/>}
            </PopupView>
        )
    }
}

class ItemComment extends React.PureComponent{

    constructor(props){
        super(props);
        this.state={
            isEdit: false,
            isTooltip: false,
        }
    }

    cancelEdit = () => {
        const {onChangeIdEdit} = this.props;
        this.setState({
            isEdit: false
        });
        if(onChangeIdEdit) onChangeIdEdit(null);
    };

    showEditComment = () => {
        const {onChangeIdEdit, data} = this.props;
        this.setState({
            isEdit: true,
        });
        this.hideDropdownMenu();
        if(onChangeIdEdit) onChangeIdEdit(data.CommentID);
    };

    hideDropdownMenu = () => {
        this.setState({isTooltip: false}, () => {
            document.removeEventListener('click', this.hideDropdownMenu);
        });

    };

    editComment = (vl) => {
        const {editComment} = this.props;
        this.setState({
            isEdit: true
        });
        editComment && editComment(vl)
    };

    removeComment = () => {
        const {removeComment} = this.props;
        Config.popup.show("YES_NO", Config.lang('CRM_Ban_co_chac_chan_muon_xoa_du_lieu_nay'), () => {
            removeComment && removeComment();
        });
        this.setState({
            isTooltip: false
        })
    };

    onEnterPress = (e) => {
        if(e.keyCode === 13 && !e.shiftKey) {
            e.preventDefault();
            this.editComment(e.target.value);
        }
    };

    render(){
        const {data, idEdit} = this.props;
        const {isEdit, isTooltip} = this.state;
        if(!data.SalesPersonNameU && !data.URL) {
            const user = Config.getUser({UserID: data.UserID});
            if(user){
                data.URL = user ? user.URL : null;
                data.SalesPersonNameU = user ? user.SalesPersonNameU : null;
            }
        }
        return(
            <div className={'display_row'} style={{
                marginBottom:15
            }}>
                <Image src={data.URL || require('../../images/user3.jpg')}
                       style={{width: 40, height: 40, borderRadius: 100, marginRight: 10}}
                />
                {(!isEdit || idEdit!==data.CommentID) && <div style={{
                    position: 'relative',
                    background: 'rgb(248, 249, 251)',
                    borderRadius: 10,
                    padding: '10px 20px'
                }}
                >
                    <b style={{marginRight: 5, color:'#1D4C72'}}>{data.SalesPersonNameU || data.SalesPersonNameU}</b>
                    <i style={{
                        fontSize: 10,
                        color: 'grey',
                        lineHeight: '21px'
                    }}>{moment(data.createdAt).format('DD/MM/YYYY[,]HH:mm:ss')}</i>
                    <div style={{fontSize: 13,whiteSpace: 'pre-line'}}>{data.Message}</div>
                    {isTooltip &&
                        <div id={"tooltipComment"}
                                 className={'tooltipComment'}
                        >
                            <div className={'func-tooltip'}
                                 onClick={this.showEditComment}
                            >
                                <i className={'fa fa-pencil'}/> Sửa...
                            </div>
                            <div className={'func-tooltip'}
                                 onClick={this.removeComment}
                            >
                                <i className={'fa fa-trash'}/> Xoá...
                            </div>
                        </div>
                    }
                    {Config.profile.UserID === data.UserID &&
                        <i style={{position: 'absolute', right: -20, top: 22, color: 'grey', cursor: 'pointer'}}
                           className={'fa fa-ellipsis-h'} onClick={() => {
                            this.setState({
                                isTooltip: !isTooltip
                            },()=>{
                                document.addEventListener('click', this.hideDropdownMenu);
                            });
                        }}
                        />
                    }
                </div>
                }
                {isEdit && data.CommentID === idEdit &&
                    <div style={{position: 'relative', width:'100%'}}>
                        <TextareaAutosize defaultValue={data.Message}
                                  className={'form-control'}
                                  onKeyDown={this.onEnterPress}
                                  style={{width:'100%', borderRadius: 4, minHeight: 35}}
                                  placeholder={Config.lang('CRM_Viet_binh_luan')}/>
                        <span style={{position:'absolute', bottom: 5, right:20, zIndex:1, fontSize:12, color: '#1D4C72', cursor:'pointer'}}
                              onClick={this.cancelEdit}>Huỷ</span>
                    </div>
                }
            </div>
        )
    }
}

export default connect(state => ({
        cboContactW18F1014: state.w18F1014.cboContactW18F1014,
        cboPriorityW18F1014: state.w18F1014.cboPriorityW18F1014,
        cboTaskStatusW18F1014: state.w18F1014.cboTaskStatusW18F1014,
        cboTaskTypeW18F1014: state.w18F1014.cboTaskTypeW18F1014,
        dataAttachedW18F1014: state.w18F1014.dataAttachedW18F1014
    }),
    (dispatch) => ({
        w18F1014Actions: bindActionCreators(w18F1014Actions, dispatch),
        w17Actions: bindActionCreators(w17Actions, dispatch),
        fileActions: bindActionCreators(fileActions, dispatch),
        w18Actions: bindActionCreators(w18Actions, dispatch),
    })
)(W18F1014Page);
