/**
 * @copyright 2019 @ DigiNet
 * @author rocachien
 * @create 2019/01/11 10:37
 * @update 2019/01/11 10:37
 * @file src/components/header/header.js
 */

import React from 'react';
import {connect} from "react-redux";
import {browserHistory} from "react-router";
import {bindActionCreators} from "redux";

import * as w18f1010Actions from "../../actions/w18f1010-actions";
import * as userActions from "../../actions/user-actions";

import PopupView from "../popup/popup-view";
import {Tabs, Tab} from 'react-bootstrap';
import {Column} from 'devextreme-react/data-grid';
import {Loading} from "../common/loading/loading";
import GridContainer from "../common/grid-container/grid-container";
import Config from "../../config";
import Link from '../common/link/link';
import GridActionBar from "../common/action-bar/grid-action-bar";
import EventTracking from "../common/tracking/event";
import notify from 'devextreme/ui/notify';

import W18F1019Pop   from "../W18/W18F1019"
import W18F1023      from "../W18/W18F1023";
import W18F1026      from "../W18/W18F1026";
import PageContainer from "../common/page-container/page-container";
import ButtonExport  from "../common/button/button-export";

class W18F1010Page extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            activePage: 15,
            tabKey: 1,
            type: '',
            showAddNewPopup: false,
            iPermission: 0,
            showW18F1019: false,
            companyIDW18F1019: '',
            companyNameW18F1019: '',
            CompanyIDW19F1026: '',
            CompanyStatusNameW18F1026: '',
            companyNameW18F1026: '',
            iPermissionShare: 0,
            showPopupW18F1023: false,

        };

        this.timer = null;
        this.timerFocus = null;
        this.filter = {
            skip: 0,
            limit: 15,
            search: '',
            searchID: '',
            CompanyStatus: '',
            useFilter: 0
        };
    }

    loadPermission = async () => {
        await this.props.userActions.getPermission(Config.profile.UserID, ["D17F1010", "D17F0003", "D17F1012"], (arrPer) => {
            if (arrPer !== 0) {
                const iPerD17F1010 = arrPer.filter(data => data.ScreenID === 'D17F1010');
                const iPerD17F0003 = arrPer.filter(data => data.ScreenID === 'D17F0003');
                const iPerD17T1012 = arrPer.filter(data => data.ScreenID === 'D17F1012');
                this.setState({
                    iPermission: iPerD17F1010 && iPerD17F1010.length > 0 && iPerD17F1010[0] ? iPerD17F1010[0].Permission : 0,
                    iPermissionHistory: iPerD17F0003 && iPerD17F0003.length > 0 && iPerD17F0003[0] ? iPerD17F0003[0].Permission : 0,
                    iPermissionShare: iPerD17T1012 && iPerD17T1012.length > 0 && iPerD17T1012[0] ? iPerD17T1012[0].Permission : 0,

                });
            }
        });
    };

    componentWillMount() {
        const url = new URL(window.location.href);
        const type = url.searchParams.get('type');
        if (!type) {
            browserHistory.push(Config.env.url + '/W18F1010?type=Customers');
        }
    }

    componentDidMount = async () => {
        await this.loadPermission();
        if (this.state.iPermission <= 0) return;
        this.props.w18f1010Actions.clearW18F1010();
        this.loadData();
        this.loadFilter();
    };

    componentWillUnmount() {
        if (this.timer) clearTimeout(this.timer);
        if (this.timerFocus) clearTimeout(this.timerFocus);
    }

    loadData() {
        let formID = '';
        const type = this.state.type;
        if (!type) return false;
        if (type === 'Customers') {
            formID = 'W18F1010';
        } else if (type === 'Competitor') {
            formID = 'W18F1010Competitor';
        } else {
            formID = 'W18F1010Partner';
        }
        this.setState({isSubmit: false, message: '', loading: true});
        if (this.timer) clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            this.setState({isSubmit: true, message: '', loading: false});
        }, 30000);

        const uName = Config.profile.UserID || '';
        const uLanguage = Config.language || '84';
        const params = {
            "UserID": uName,
            "Language": uLanguage,
            "FormID": formID,
            "SearchID": this.filter.searchID,
            "CompanyID": '',
            "CompanyStatus": this.filter.CompanyStatus,
            "StrSearch": this.filter.search,
            "skip": this.filter.skip,
            "limit": this.filter.limit,
            UseFilter: this.filter.useFilter
        };
        this.props.w18f1010Actions.loadW18F1010fromStore(params, (error, data) => {
            if (error) {
                let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                this.setState({isSubmit: false, message: message, loading: false});
                return false;
            }
            if (data) {
                this.setState({loading: false});
            }
        });
    }

    loadFilter() {
        const uName = Config.profile.UserID || '';
        const paramsFilter = {
            "UserID": uName,
        };
        this.props.w18f1010Actions.loadFilter(paramsFilter, (error, data) => {
            if (error) {
                let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                this.setState({isSubmit: false, message: message, loading: false});
                return false;
            }
            if (data) {
                if (this.state.type === 'Customers') {
                    this.setState({
                        dataFilter: data,
                    });
                    if (this.timerFocus) clearTimeout(this.timerFocus);
                    this.timerFocus = setTimeout(() => {
                        const button = document.getElementsByClassName("filter-bar")[0].getElementsByTagName('button')[0];
                        if (button) {
                            button.focus();
                        }
                    }, 300);
                } else {
                    this.setState({
                        dataFilter: '',
                    })
                }
                //this.setState({loading: false});
                // Set focus when first time loading list
            }
        });
    }

    onDelete(e) {
        Config.popup.show("YES_NO", Config.lang("CRM_Ban_co_chac_chan_muon_xoa_du_lieu_nay"), () => {
            const CompanyID = e && e.row && e.row.data && e.row.data.CompanyID ? e.row.data.CompanyID : '';

            this.setState({isSubmit: false, message: '', loading: true});
            if (this.timer) clearTimeout(this.timer);
            this.timer = setTimeout(() => {
                this.setState({isSubmit: true, message: '', loading: false});
            }, 30000);

            this.props.w18f1010Actions.delW18F1010({CompanyID: CompanyID}, (error) => {
                if (error) {
                    let errorCode = error.code || null;
                    let message = "";
                    switch (errorCode) {
                        case "SYS053":
                            message = Config.lang("CRM_Khach_hang_da_phat_sinh_doat_dong_ban_khong_duoc_phep_xoa");
                            Config.popup.show('INFO', message);
                            break;
                        default:
                            message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                            Config.popup.show('INFO', message);
                            break;
                    }
                    this.setState({isSubmit: false, message: message, loading: false});
                    return false;
                } else {
                    notify(Config.lang("CRM_Du_lieu_da_xoa_thanh_cong"), "success", 2000);
                    this.loadData();
                }
            });

        });
    }

    onDetail = (e) => {
        const url = new window.URLSearchParams(window.location.search);
        const type = url.get('type');
        const companyID = e && e.data && e.data.CompanyID ? e.data.CompanyID : '';
        const permission = e && e.data && e.data.Permission ? e.data.Permission : '';
        if (!companyID) {
            return null;
        }
        let dataPush = null;
        if (type === 'Customers') {
            // browserHistory.push({
            //     pathname: Config.getRootPath() + 'W18F1012',
            //     state: {type: 'Customers', Permission: permission, CompanyID: companyID}
            // });
            dataPush = {
                pathname: Config.getRootPath() + 'W18F1012',
                state: {type: 'Customers', Permission: permission, CompanyID: companyID}
            }
        } else if (type === 'Competitor') {
            // browserHistory.push({
            //     pathname: Config.getRootPath() + 'W18F1012',
            //     state: {type: 'Competitor', Permission: permission, CompanyID: companyID}
            // });
            dataPush = {
                pathname: Config.getRootPath() + 'W18F1012',
                state: {type: 'Competitor', Permission: permission, CompanyID: companyID}
            };
        } else {
            // browserHistory.push({
            //     pathname: Config.getRootPath() + 'W18F1012',
            //     state: {type: 'Partner', permission: permission, CompanyID: companyID}
            // });
            dataPush = {
                pathname: Config.getRootPath() + 'W18F1012',
                state: {type: 'Partner', permission: permission, CompanyID: companyID}
            };
        }

        return dataPush;
    };

    onEdit(e) {
        const url = new window.URLSearchParams(window.location.search);
        const type = url.get('type');
        const companyID = e && e.data && e.data.CompanyID ? e.data.CompanyID : '';
        if (!companyID) return false;
        if (type === 'Customers') {
            browserHistory.push({
                pathname: Config.getRootPath() + 'W18F1011',
                state: {type: 'Customers', mode: 'edit', CompanyID: companyID}
            });
        } else if (type === 'Competitor') {
            browserHistory.push({
                pathname: Config.getRootPath() + 'W18F1011',
                state: {type: 'Competitor', mode: 'edit', CompanyID: companyID}
            });
        } else {
            browserHistory.push({
                pathname: Config.getRootPath() + 'W18F1011',
                state: {type: 'Partner', mode: 'edit', CompanyID: companyID}
            });
        }
    }

    onCopy(e) {
        const url = new window.URLSearchParams(window.location.search);
        const type = url.get('type');
        const companyID = e && e.data && e.data.CompanyID ? e.data.CompanyID : '';
        if (!companyID) return false;
        if (type === 'Customers') {
            browserHistory.push({
                pathname: Config.getRootPath() + 'W18F1011',
                state: {type: 'Customers', mode: 'add', CompanyID: companyID}
            });
        } else if (type === 'Competitor') {
            browserHistory.push({
                pathname: Config.getRootPath() + 'W18F1011',
                state: {type: 'Competitor', mode: 'add', CompanyID: companyID}
            });
        } else {
            browserHistory.push({
                pathname: Config.getRootPath() + 'W18F1011',
                state: {type: 'Partner', mode: 'add', CompanyID: companyID}
            });
        }
    }

    onAddNew() {
        const url = new window.URLSearchParams(window.location.search);
        const type = url.get('type');
        if (type === 'Customers') {
            browserHistory.push({
                pathname: Config.getRootPath() + 'W18F1011',
                state: {type: 'Customers', mode: 'add'}
            });
        } else if (type === 'Competitor') {
            browserHistory.push({
                pathname: Config.getRootPath() + 'W18F1011',
                state: {type: 'Competitor', mode: 'add'}
            });
        } else if (type === 'Partner') {
            browserHistory.push({
                pathname: Config.getRootPath() + 'W18F1011',
                state: {type: 'Partner', mode: 'add'}
            });
        } else {
            browserHistory.push({
                pathname: Config.getRootPath() + 'W18F1011',
                state: {type: 'Customers', mode: 'add'}
            });
        }
    }

    onHistory(e) {

        let labelFrom = '';
        const url = new window.URLSearchParams(window.location.search);
        const type = url.get('type');
        const auditCode = 'W18F1010_CUS';
        const valueIDParam = e && e.data && e.data.CompanyID ? e.data.CompanyID : '';
        const valueNameParam = e && e.data && e.data.CompanyNameU ? e.data.CompanyNameU : '';

        if (type === 'Customers') {
            labelFrom = Config.lang("CRM_Khach_hang");
            browserHistory.push({
                pathname: Config.getRootPath() + 'W17F0003',
                state: {
                    LabelParam: labelFrom,
                    AuditCode: auditCode,
                    ValueIDParam: valueIDParam,
                    ValueNameParam: valueNameParam,
                    FormID: 'W18F1010'
                }
            });
        } else if (type === 'Competitor') {
            labelFrom = Config.lang("CRM_Doi_thu");
            browserHistory.push({
                pathname: Config.getRootPath() + 'W17F0003',
                state: {
                    LabelParam: labelFrom,
                    AuditCode: auditCode,
                    ValueIDParam: valueIDParam,
                    ValueNameParam: valueNameParam,
                    FormID: 'W18F1010'
                }
            });
        } else if (type === 'Partner') {
            labelFrom = Config.lang("CRM_Doi_tac");
            browserHistory.push({
                pathname: Config.getRootPath() + 'W17F0003',
                state: {
                    LabelParam: labelFrom,
                    AuditCode: auditCode,
                    ValueIDParam: valueIDParam,
                    ValueNameParam: valueNameParam,
                    FormID: 'W18F1010'
                }
            });
        } else {
            labelFrom = Config.lang("CRM_Khach_hang");
            browserHistory.push({
                pathname: Config.getRootPath() + 'W17F0003',
                state: {
                    LabelParam: labelFrom,
                    AuditCode: auditCode,
                    ValueIDParam: valueIDParam,
                    ValueNameParam: valueNameParam,
                    FormID: 'W18F1010'
                }
            });
        }
    }

    onTransfer(e) {
        const rowTransfer = [{
            CompanyID: e.data.CompanyID,
            CompanyStatus: e.data.CompanyStatus,
        }];
        this.setState({rowW18F1023: rowTransfer, showPopupW18F1023: true});
    }

    hideAddNewPopup() {
        // console.log("==== hideAddNewPopup =====");
        this.setState({showAddNewPopup: false, showPopupW18F1023: false});
        this.loadData();
    }

    onChangeTab(tab) {
        this.setState({tabKey: tab});
    }

    onChangePage(page) {
        this.filter.skip = page * this.filter.limit;
        this.loadData();
    }

    onChangePerPage(per) {
        this.filter.skip = 0;
        this.filter.limit = per;
        this.loadData();
    }

    onSearch(text) {
        // console.log('== onSearch => text: ', text);
        this.filter.skip = 0;
        this.filter.search = text;
        this.loadData();
    }

    onFilter(option) {
        this.filter.skip = 0;
        this.filter.CompanyStatus = option.ItemID || '';
        this.loadData();
    }

    onExportCompany() {
        if (this.btnExport) this.btnExport.getWrappedInstance().onClick();
        // const url = new window.URLSearchParams(window.location.search);
        // let param;
        // const type = url.get('type');
        // let exportExcelID;
        // if (type === 'Customers') {
        //     exportExcelID = 'W18F1010_ExportCustomer';
        //     param = {
        //         ReportTypeID: 'W18F1010',
        //         ReportID: 'W18F1010_ExCustomer',
        //     }
        // } else if (type === 'Competitor') {
        //     exportExcelID = 'W18F1010_ExportCompetitor';
        //     param = {
        //         ReportTypeID: 'W18F1010',
        //         ReportID: 'W18F1010_Competitor',
        //     }
        // } else if (type === 'Partner') {
        //     exportExcelID = 'W18F1010_ExportPartner';
        //     param = {
        //         ReportTypeID: 'W18F1010',
        //         ReportID: 'W18F1010_Partner',
        //     }
        // }
        //
        // this.props.w18f1010Actions.exportExcelTemplateURL(param, async (error, result) => {
        //     if (error) {
        //         let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
        //         Config.popup.show('INFO', message);
        //         return false;
        //     }
        //
        //     // EXEC  W17P1010 'LEMONADMIN','84','W18F1011','',N'','', 0, 100, 'CCN';
        //     else if (result && result.length > 0 && result[0].length > 0) {
        //         let StoreParam = [
        //             {"name": "UserID", "type": "VarChar", "value": Config.profile.UserID},
        //             {"name": "Language", "type": "VarChar", "value": "84"},
        //             {"name": "FormID", "type": "VarChar", "value": "W18F1010"},
        //             {"name": "SearchID", "type": "VarChar", "value": ""},
        //             {"name": "StrSearch", "type": "NVarChar", "value": this.filter.search},
        //             {"name": "CompanyID", "type": "VarChar", "value": ""},
        //             {"name": "skip", "type": "Int", "value": 0},
        //             {"name": "limit", "type": "Int", "value": 100},
        //             {"name": "CompanyStatus", "type": "VarChar", "value": this.filter.CompanyStatus},
        //             {"name": "ReportID", "type": "VarChar", "value": exportExcelID},
        //             {"name": "UseFilter", "type": "Int", "value": this.filter.useFilter},
        //         ];
        //
        //         let url = Config.env.api + "/w17/excel-report?";
        //         let data = {
        //             'token': Config.token.id,
        //             'TemplateURL': result[0][0].URL,
        //             'StoreName': 'W17P1010',
        //             'StoreParam': JSON.stringify(StoreParam),
        //             'ReportFileName':param.ReportID
        //         };
        //         let params = Object.keys(data).map(function (k) {
        //             return encodeURIComponent(k) + '=' + encodeURIComponent(data[k])
        //         }).join('&');
        //         url = url + params;
        //         return window.open(url, "_self");

                // const API = await fetch(url, {
                //     headers: Api.headers(),
                //     // body: JSON.stringify(data),
                //     method: 'GET',
                // });


                // const blobFile = await API.blob();
                // const content_dispostion = await API.headers.get('content-disposition');
                //
                // if (!blobFile) {
                //     Config.popup.show("CRM_Co_loi_trong_qua_trinh_xu_ly_du_lieu");
                //     return false;
                // }
                // let fileName;
                //
                // if (content_dispostion) {
                //     const content = content_dispostion ? content_dispostion.split('filename=') : '';
                //     fileName = content.length > 1 ? content[1] : 'report-filename.xlsx';
                // }
                // let objectURL = window.URL.createObjectURL(blobFile);
                // let aTag = document.createElement('a');
                //
                // document.body.appendChild(aTag); // we need to append the element to the dom -> otherwise it will not work in firefox
                // aTag.href = objectURL;
                // aTag.download = fileName.replace(/"/g, '');
                // aTag.click();
                // URL.revokeObjectURL(objectURL);
                // document.body.removeChild(aTag);
            // }
        // });
    }

    onTransType() {
        const url = 'W18F1022';
        browserHistory.push(Config.env.url + '/' + url);
    }

    renderCompanyName = (data) => {
        if (!data) return null;
        const name = data && data.value ? data.value : '';
        /* eslint-disable */
        // return (
        //     <div><a onClick={() => this.onDetail(data)}>{name}</a></div>
        // );
        return (
            <Link getData={() => this.onDetail(data)}>{name}</Link>
        );
        /* eslint-enable */
    };

    renderAction(data) {
        let titleName = '';
        let titleCoppy = '';
        const type = this.state.type;
        const isPers = data.data;

        if (type === 'Customers') {
            titleName = Config.lang("CRM_Chia_se_khach_hang");
            titleCoppy = Config.lang("CRM_Sao_chep_khach_hang");
        } else if (type === 'Competitor') {
            titleName = Config.lang("CRM_Chia_se_doi_thu");
            titleCoppy = Config.lang("CRM_Sao_chep_doi_thu");
        } else {
            titleName = Config.lang("CRM_Chia_se_doi_tac");
            titleCoppy = Config.lang("CRM_Sao_chep_doi_tac");
        }
        if (!data) return null;
        return (
            <GridActionBar
                isPer={this.state.iPermission}
                tooltipEdit={"Sửa"}
                className={isPers.Permission >= 4 ? '' : 'isDisabled'}
                classNameIs={isPers.Permission}
                onEditAction={() => {
                    this.onEdit(data)
                }}
                tooltipDelete={Config.lang("CRM_Xoa")}
                onDeleteAction={() => {
                    this.onDelete(data)
                }}
                btnHamburger={[
                    {
                        name: Config.lang("CRM_Lich_su_tac_dong"),
                        disabled: this.state.iPermissionHistory < 1,
                        icon: 'far fa-newspaper text-purple',
                        onClick: () => {
                            this.onHistory(data)
                        },
                    },
                    {
                        name: titleName,
                        icon: 'fas fa-share-alt text-blue',
                        disabled: this.state.iPermissionShare < 1,
                        className: isPers.Permission === 1 ? 'isDisabled' : '',
                        onClick: () => {
                            if (isPers.Permission >= 4) {
                                const rowData = data.data;
                                this.setState({
                                    companyNameW18F1019: rowData.CompanyNameU,
                                    companyIDW18F1019: rowData.CompanyID
                                });
                                this.setState({showW18F1019: true});
                            }
                        }
                    },
                    {
                        name: Config.lang("CRM_Chuyen_chu_so_huu"),
                        icon: 'fas fa-dolly text-black',
                        disabled: this.state.iPermissionShare < 1,
                        className: isPers.Permission === 1 ? 'isDisabled' : '',
                        onClick: () => {
                            if (isPers.Permission >= 4) {
                                this.onTransfer(data)
                            }
                        }
                    },
                    {
                        name: titleCoppy,
                        icon: 'fas fa-copy text-yellow',
                        disabled: this.state.iPermission < 2,
                        onClick: () => {
                            this.onCopy(data)
                        }
                    },
                    {
                        name: Config.lang("CRM_Chuyen_doi_trang_thai"),
                        icon: 'fas fa-exchange-alt',
                        disabled: this.state.iPermissionShare < 1,
                        className: isPers.Permission === 1 ? 'isDisabled' : '',
                        onClick: () => {
                            const rowData = data.data;
                            const rowDataW18F1026 = {
                                CompanyIDW19F1026: rowData.CompanyID,
                                companyNameW18F1026: rowData.CompanyNameU,
                                CompanyStatusNameW18F1026: rowData.CompanyStatusName
                            };
                            this.setState({rowW18F1026: rowDataW18F1026, showW18F1026: true});
                        }
                    },
                ]}
            />
        );
    }

    componentWillUpdate(nextProps, nextState) {
        if (this.state.iPermission <= 0) return;
        const url = new URL(window.location.href);
        const type = url.searchParams.get('type');

        if (this.state.type !== type) {
            this.filter.search = '';
            if (document.querySelector('.toolbar-container input'))
                document.querySelector('.toolbar-container input').value = '';
            this.props.w18f1010Actions.clearW18F1010();
            this.setState({
                type: type,
            }, () => {
                this.loadFilter();
                this.loadData();
            });
        }
    }

    onSearchAdvance = () => {
        this.filter.useFilter = 1;
        this.loadData();
    };

    onClearAdvanced = () => {
        this.filter.useFilter = 0;
        this.loadData();
    };

    render() {
        if (this.state.iPermission <= 0) return null;
        let titleGird = '';
        let titleLable = '';
        let titleLableID = '';
        const type = this.state.type;
        if (type === 'Customers') {
            titleGird = Config.lang("CRM_Danh_sach_khach_hang");
            titleLable = Config.lang("CRM_Ten_khach_hang");
            titleLableID = Config.lang("CRM_Ma_khach_hang");
        } else if (type === 'Competitor') {
            titleGird = Config.lang("CRM_Danh_sach_doi_thu");
            titleLable = Config.lang("CRM_Ten_doi_thu");
            titleLableID = Config.lang("CRM_Ma_doi_thu");
        } else {
            titleGird = Config.lang("CRM_Danh_sach_doi_tac");
            titleLable = Config.lang("CRM_Ten_doi_tac");
            titleLableID = Config.lang("CRM_Ma_doi_tac");
        }
        const {loadW18F1010Store} = this.props;
        const {companyIDW18F1019, companyNameW18F1019} = this.state;
        let dataSource = [];
        let totalItems = 0;
        if (loadW18F1010Store && loadW18F1010Store.length > 0) {
            loadW18F1010Store.map(o => {
                const row = {
                    AddressLineU: o.AddressLineU,
                    CompanyID: o.CompanyID,
                    CompanyNameU: o.CompanyNameU,
                    CreateDate: o.CreateDate,
                    CompanyKindName: o.CompanyKindName,
                    DateOfBirth: o.DateOfBirth,
                    Email: o.Email,
                    NotesU: o.NotesU,
                    SalesPersonNameU: o.SalesPersonNameU,
                    TaxNo: o.TaxNo,
                    Telephone: o.Telephone,
                    CompanyStatus: o.CompanyStatus,
                    CompanyStatusName: o.CompanyStatusName,
                    Permission: o.Permission,
                    ProvinceName: o.ProvinceName,
                    DistrictName: o.DistrictName,
                    GroupSalesNameU: o.GroupSalesNameU,
                    ObjectID: o.ObjectID,
                };
                totalItems = o.TotalRecord;
                dataSource.push(row);
                return o;
            });
        }

        const storeParam = [
            {"id": "UserID", "type": "VarChar", "value": Config.profile.UserID},
            {"id": "Language", "type": "VarChar", "value": "84"},
            {"id": "FormID", "type": "VarChar", "value": "W18F1010"},
            {"id": "SearchID", "type": "VarChar", "value": ""},
            {"id": "StrSearch", "type": "NVarChar", "value": this.filter.search},
            {"id": "CompanyID", "type": "VarChar", "value": ""},
            {"id": "skip", "type": "Int", "value": 0},
            {"id": "limit", "type": "Int", "value": 100},
            {"id": "CompanyStatus", "type": "VarChar", "value": this.filter.CompanyStatus},
            {"id": "UseFilter", "type": "Int", "value": this.filter.useFilter},
        ];

        return (
            <PageContainer>
                {Config && Config.profile &&
                <EventTracking category={"TRACKING_USER"} action={"W18F1010"} label={Config.profile.UserID}/>}
                <ButtonExport ref={ref => this.btnExport = ref}
                              name={Config.lang('CRM_In1')}
                              reportTypeID={"W18F1010"}
                              param={storeParam}
                              onlyShowPopup={true}
                              className={'mgl5 hidden'}/>
                {dataSource && <GridContainer
                    title={titleGird}
                    isPer={this.state.iPermission}
                    showAdvancedSearch={type === 'Customers'}
                    formID={'W18F1010'}
                    onAdvancedSearch={this.onSearchAdvance}
                    onClearAdvanced={this.onClearAdvanced}
                    dataSource={dataSource}
                    totalItems={totalItems}
                    listPerPage={[15, 20, 30, 50]}
                    itemPerPage={this.filter.limit}
                    filterData={this.state.dataFilter}
                    columnResizingMode={'widget'}
                    buttonCRM={[
                        {
                            buttonName: Config.lang("CRM_Thao_tac"),
                            buttonType: 'dropdown',
                            icon: 'fas fa-wrench text-blue',
                            align: 'right',
                            disabled: false,
                            menuItem: [
                                {
                                    name: Config.lang("CRM_Danh_sach_khach_hang_dung_chung"),
                                    disabled: this.state.iPermission < 1,
                                    icon: 'fas fa-list',
                                    onClick: () => {
                                        browserHistory.push(Config.env.url + '/W18F1016')
                                    }
                                },
                                {
                                    name: Config.lang("CRM_Xuat_Excel"),
                                    icon: 'fas fa-file-excel text-green',
                                    onClick: () => {
                                        this.onExportCompany()
                                    }
                                },
                                {
                                    name: Config.lang("CRM_Lich_su_thay_doi"),
                                    icon: 'fas fa-external-link-square-alt text-darkcyan',
                                    onClick: () => {
                                        this.onTransType()
                                    }
                                }
                            ]
                        }]}
                    onAddNew={() => this.onAddNew()}
                    onFilter={(o) => {
                        this.onFilter(o)
                    }}
                    onSearch={(text) => {
                        this.onSearch(text)
                    }}
                    onChangePage={(page) => {
                        this.onChangePage(page)
                    }}
                    onChangePerPage={(per) => {
                        this.onChangePerPage(per)
                    }}>
                    <Column
                        cellRender={(data) => this.renderAction(data)}
                        dataField={'CompanyID'}
                        caption={Config.lang("CRM_Hanh_dong")}
                        alignment={'center'}
                        allowSorting={false}
                        showInColumnChooser={false}
                        width={120}
                        fixed={true}
                    />
                    <Column
                        cellRender={(data) => this.renderCompanyName(data)}
                        dataField={'CompanyNameU'}
                        caption={titleLable}
                        sortOrder={'asc'}
                        fixed={window.innerWidth > 600}
                        minWidth={150}
                    />
                    <Column
                        dataField={'AddressLineU'}
                        caption={Config.lang("CRM_Dia_chi")}
                        width={450}
                    />
                    <Column
                        dataField={'Telephone'}
                        caption={Config.lang("CRM_Dien_thoai")}
                        width={120}/>
                    <Column
                        dataField={'Email'}
                        width={200}
                        caption={'Email'}/>
                    <Column
                        dataField={'SalesPersonNameU'}
                        caption={Config.lang("CRM_Nhan_vien")}/>
                    <Column
                        dataField={'GroupSalesNameU'}
                        width={220}
                        caption={Config.lang("CRM_Nhom_kinh_doanh")}/>
                    <Column
                        dataField={'CreateDate'}
                        caption={Config.lang("CRM_Ngay_tao")}
                        dataType={'date'}
                        format={'dd-MM-yyyy'}
                        alignment={'center'}
                        width={120}/>
                    <Column
                        dataField={'DateOfBirth'}
                        caption={Config.lang("CRM_Sinh_nhat")}
                        dataType={'date'}
                        format={'dd-MM-yyyy'}
                        alignment={'center'}
                        width={120}/>
                    <Column
                        dataField={'TaxNo'}
                        caption={Config.lang("CRM_Ma_so_thue")}
                        width={120}/>
                    <Column
                        dataField={'NotesU'}
                        caption={Config.lang("CRM_Mo_ta")}
                        width={300}/>
                    <Column
                        dataField={'CompanyKindName'}
                        caption={Config.lang("CRM_Phan_loai")}
                        width={250}/>
                    <Column
                        dataField={'ProvinceName'}
                        caption={Config.lang("CRM_Tinh_Thanh_pho")}
                        sortOrder={'asc'}
                        width={250}
                    />
                    <Column
                        dataField={'DistrictName'}
                        caption={Config.lang("CRM_Quan_Huyen")}
                        sortOrder={'asc'}
                        width={250}
                    />
                    <Column
                        dataField={'ObjectID'}
                        caption={Config.lang("CRM_Ma_doi_tuong")}
                        sortOrder={'asc'}
                        width={200}
                    />
                    <Column
                        dataField={'CompanyID'}
                        caption={titleLableID}
                        allowSorting={true}
                        width={180}
                    />
                </GridContainer>}
                {this.state.loading && <Loading/>}
                {this.state.showW18F1019 &&
                <W18F1019Pop companyID={companyIDW18F1019} companyName={companyNameW18F1019} onHide={() => {
                    this.setState({showW18F1019: false});
                    this.loadData();
                }}/>
                }
                {this.state.showAddNewPopup &&
                <PopupView
                    title={Config.lang("CRM_Chuc_nang_them_moi")}
                    show={true}
                    animation={true}
                    onHide={() => this.hideAddNewPopup()}>
                    <Tabs activeKey={this.state.tabKey} onSelect={(key) => this.onChangeTab(key)}
                          id="controlled-tab-example">
                        <Tab eventKey={1} title={Config.lang("CRM_Thong_tin_chung")}
                             style={{padding: 10, border: '1px solid #ddd', borderTop: 0}}>
                            <div>helo ca nha 1</div>
                        </Tab>
                        <Tab eventKey={2} title={Config.lang("CRM_Thong_tin_chung")}
                             style={{padding: 10, border: '1px solid #ddd', borderTop: 0}}>
                            <div>helo ca nha 2</div>
                        </Tab>
                    </Tabs>
                </PopupView>
                }

                {this.state.showPopupW18F1023 &&
                <PopupView
                    title={Config.lang("CRM_Co_cau_kinh_doanh")}
                    show={true}
                    animation={true}
                    onHide={() => this.hideAddNewPopup()}>
                    <W18F1023 data={this.state.rowW18F1023} onHide={() => this.hideAddNewPopup()}/>
                </PopupView>
                }

                {this.state.showW18F1026 &&
                <PopupView
                    title={Config.lang("CRM_Chuyen_doi_trang_thai")}
                    show={true}
                    animation={true}
                    onHide={() => {
                        this.setState({showW18F1026: false});
                    }}>
                    <W18F1026 data={this.state.rowW18F1026}
                              onHide={() => {
                                  this.setState({showW18F1026: false});
                              }}/>
                </PopupView>
                }
            </PageContainer>
        )
    }
}

export default connect(state => ({
        dataFilter: state.w18f1010.dataFilter,
        delW18F1010: state.w18f1010.delW18F1010,
        loadW18F1010Store: state.w18f1010.loadW18F1010Store,
    }),
    (dispatch) => ({
        w18f1010Actions: bindActionCreators(w18f1010Actions, dispatch),
        userActions: bindActionCreators(userActions, dispatch)
    })
)(W18F1010Page);
