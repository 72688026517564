/**
 * @copyright 2019 @ DigiNet
 * @author VINHLE
 * @create 2019/01/11 10:37
 * @update 2019/01/11 10:37
 * @file src/components/header/header.js
 */

import React from 'react';
import {Collapse, Form, Tab, Tabs} from 'react-bootstrap';
import {Col, FormGroup, Row} from "reactstrap";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {browserHistory} from "react-router";
import * as w17Actions from "../../actions/w17-actions";
import * as w18Actions from "../../actions/w18-actions";
import * as w18F1012Actions from "../../actions/w18F1012-actions";
import * as userActions from "../../actions/user-actions";

import Attachment from "../common/attachment/attachment";
import ButtonEdit from "../common/button/button-edit";
import ButtonConvert from "../common/button/button-convert";
import ButtonCustom from "../common/button/button-custom";
import {Column} from 'devextreme-react/data-grid';
import EventTracking from "../common/tracking/event";
import GridContainer from "../common/grid-container/grid-container";
import GridActionBar from "../common/action-bar/grid-action-bar";
import {Loading} from "../common/loading/loading";
import Detail from "../W18/W18F1013";
import ActionTab from "../W18/W18F1014";
import PopupView from "../popup/popup-view";
import Config from "../../config";
import notify from 'devextreme/ui/notify';
import SendMail from "../W17/W17F1002";
import W18F1018 from "./W18F1018"
import W18F1017 from "./W18F1017"
import W18F1012Price from "./W18F1012_Price"
import W18F1012Order from "./W18F1012_Order"
import PopupScroll from "../popup/popup-scroll";

import moment from "moment";
import 'moment/locale/vi'
import W18F1012Case from "./W18F1012_Case";
import W18F1012Delivery from "./W18F1012_Delivery";

moment.locale('vi');

class W18F1012Page extends React.PureComponent {
    constructor(props) {
        super(props);
        if (!this.getInfo()) return;
        this.filter = {
            skip: 0,
            limit: 20,
            search: ''
        };
        this.totalRecordsActionTab = 0;
        this.dataW18F1014 = {
            mode: '',
            rowData: '',
        };
        this.state = {
            showPopup: false,
            showAttachment: false,
            showW18F1014: false,
            showSendMail: false,
            disableConvertBtn:false,
            hideBtnConvert: true,
            iPermission: 0,
            iPermissionDoc: 0,
            iPermissionActivity: 0,
            iPermissionDelivery: 0,
            iPermissionContact: 0,
            iPermissionW18F1018: 0,
            iPermissionW18F1017: 0,
            iPermissionPrice: 0,
            iPermissionOrder: 0,
            iPermissionCase: 0,
            iPermissionConvert: 0,
            key: 1,
            dataMasterW18F1012: null,

            columns: [
                {name: 'CustomerGroupName', title: Config.lang("CRM_Ten_nhom_khach_hang")},
                {name: 'Description', title: Config.lang("CRM_Ten_nhom_khach_hang")},
                {name: 'Disabled', title: Config.lang("CRM_Ngung_hoat_dong")},
            ],
            rows: [],
            callAdd: false,
            row: {
                id: '',
                FullNameU: '',
                JobPositionU: '',
                Birthday: '',
                Gender: '',
                ContactStatus: '',
                NoteU: '',
                MobileNo: '',
                OfficeTelNo: '',
                HomeAddress: '',
                Email: '',
                Email2: '',
                Skype: '',
                IsAdmin: false,
                mode: 'add',
            },
            rowDoc: {
                attachmentID: '',
                tableName: '',
                notes: '',
                description: '',
                fileName: '',
                fileSize: 0,
                url: '',
                createDate: '',
            },
            collapse1: true,  // thong tin chung
            collapse2: false, // thong tin bo sung
            collapse3: false,// dia chi
            loading: true,
            selectedTab: 1,
            attachmentID: '',
        };
        this.timeDeplay = Config.getSetting("TIME_SUBMIT_DELAY") || 2000;
        this.filterW18F1013 = {
            skip: 0,
            limit: 20,
            search: ''
        };
        const {permission} = this.getInfo();
        this.isPermission = permission;
        this.firstTime = true;
    };

    getInfo = (flag) => {
        const {location} = this.props;
        const url = new window.URLSearchParams(window.location.search);
        if (url && url.get('CompanyID')) {
            return {companyID: url.get('CompanyID'), type: url.get('type'), permission: url.get('Permission'), TaskID: url.get('TaskID')};
        } else if (location && location.state) {
            return {
                companyID: location.state.CompanyID,
                permission: location.state.Permission,
                type: location.state.type,
                screenID: location.state.screenID,
                TaskID: location.state.TaskID,
                customerNew: location.state.customerNew,
            }
        } else {
            if (flag) {
                browserHistory.push(Config.env.url + '/W18F1010?type=Customers');
                return null;
            }
            return false;
        }
    };

    async loadPermission() {
        await this.props.userActions.getPermission(Config.profile.UserID, ["D17F1010", "D17F2110", "D17F1027", "D17F1125", "D17F1126", "D17F1017", "D17F1018", "D17F1611", "D17F1021", "D17F1861", "D17F1011"], (arrPer) => {
            if (arrPer !== 0) {
                const iPerD17F1010 = arrPer.filter(data => data.ScreenID === 'D17F1010');
                const iPerD17F2110 = arrPer.filter(data => data.ScreenID === 'D17F2110');
                const iPerD17F1027 = arrPer.filter(data => data.ScreenID === 'D17F1027');
                const iPerD17F1125 = arrPer.filter(data => data.ScreenID === 'D17F1125');
                const iPerD17F1126 = arrPer.filter(data => data.ScreenID === 'D17F1126');
                const iPerD17F1018 = arrPer.filter(data => data.ScreenID === 'D17F1018');
                const iPerD17F1017 = arrPer.filter(data => data.ScreenID === 'D17F1017');
                const iPerD17F1611 = arrPer.filter(data => data.ScreenID === 'D17F1611');
                const iPerD17F1021 = arrPer.filter(data => data.ScreenID === 'D17F1021');
                const iPerD17T1860 = arrPer.filter(data => data.ScreenID === 'D17F1861');
                const isPerD17F1011 = arrPer.filter(data => data.ScreenID === 'D17F1011');
                this.setState({
                    iPermission: iPerD17F1010 && iPerD17F1010.length > 0 && iPerD17F1010[0] ? iPerD17F1010[0].Permission : 0,
                    iPermissionActivity: iPerD17F2110 && iPerD17F2110.length > 0 && iPerD17F2110[0] ? iPerD17F2110[0].Permission : 0,
                    iPermissionDelivery: iPerD17F1027 && iPerD17F1027.length > 0 && iPerD17F1027[0] ? iPerD17F1027[0].Permission : 0,
                    iPermissionContact: iPerD17F1125 && iPerD17F1125.length > 0 && iPerD17F1125[0] ? iPerD17F1125[0].Permission : 0,
                    iPermissionDoc: iPerD17F1126 && iPerD17F1126.length > 0 && iPerD17F1126[0] ? iPerD17F1126[0].Permission : 0,
                    iPermissionW18F1018: iPerD17F1018 && iPerD17F1018.length > 0 && iPerD17F1018[0] ? iPerD17F1018[0].Permission : 0,
                    iPermissionW18F1017: iPerD17F1017 && iPerD17F1017.length > 0 && iPerD17F1017[0] ? iPerD17F1017[0].Permission : 0,
                    iPermissionPrice: iPerD17F1611 && iPerD17F1611.length > 0 && iPerD17F1611[0] ? iPerD17F1611[0].Permission : 0,
                    iPermissionOrder: iPerD17F1021 && iPerD17F1021.length > 0 && iPerD17F1021[0] ? iPerD17F1021[0].Permission : 0,
                    iPermissionCase: iPerD17T1860 && iPerD17T1860.length > 0 && iPerD17T1860[0] ? iPerD17T1860[0].Permission : 0,
                    iPermissionConvert: isPerD17F1011 && isPerD17F1011.length > 0 && isPerD17F1011[0] ? isPerD17F1011[0].Permission : 0,
                });
            }
        });
    }

    componentDidMount = async () => {
        await this.loadPermission();
        if (this.state.iPermission <= 0) return;
        if (!this.getInfo()) return;
        const {companyID} = this.getInfo();
        this.setState({loading: true});
        setTimeout(() => {
            this.setState({loading: false});
        }, 60000);
        const uName = Config.profile.UserID || '';
        const uLanguage = Config.language || '';
        const paramMaster = {
            "UserID": uName,
            "Language": uLanguage,
            "FormID": 'W18F1012',
            "SearchID": '',
            "StrSearch": '',
            "CompanyID": companyID,
        };
        this.props.w18Actions.loadMasterW18F1012(paramMaster, (error, data) => {
            this.setState({loading: false});
            if (error || data.length === 0) {
                let message = error ? error.message : Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                this.setState({isSubmit: false, message: message, loading: false});
                return false;
            } else if (data && data.length > 0) {
                let hideBtnConvert = false;
                if(!Config.isEmpty(data[0], true)){
                    hideBtnConvert = data[0].IsChange !== 0 && !Config.isEmpty(data[0].ObjectID, true);
                }
                this.setState({
                    dataMasterW18F1012: data[0],
                    hideBtnConvert
                })
            }
        });

        const paramGrid = {
            "UserID": uName,
            "Language": uLanguage,
            "FormID": 'W18F1011',
            "CompanyID": companyID,
        };
        this.props.w18Actions.loadGridW18F1012(paramGrid, () => {
        });

        this.loadDataW13();
        this.loadDataTabAction();
    };

    /**
     * @Load..
     */

    loadDataW13() {
        const uName = Config.profile.UserID || '';
        const uLanguage = Config.language || '';
        const {companyID} = this.getInfo();
        const contactID = window.location.search.split('ContactID=')[1];
        const mnParam = {
            "UserID": uName,
            "Language": uLanguage,
            "CompanyID": companyID,
            "ContactID": contactID,
            "skip": this.filterW18F1013.skip,
            "limit": this.filterW18F1013.limit,
            "StrSearch": this.filterW18F1013.search,
        };
        this.props.w18Actions.loadW18F1013(mnParam, (error) => {
            if (error) {
                let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                this.setState({isSubmit: false, message: message, loading: false});
                return false;
            }

        });
        this.loadDataTabAction();
        this.loadGridTabDocument();
    }

    loadDataTabAction() {
        const uName = Config.profile.UserID || '';
        const uLanguage = Config.language || '';
        const {companyID} = this.getInfo();
        const paramActionTab = {
            "UserID": uName,
            "Language": uLanguage,
            "CompanyID": companyID,
            "TaskID": '',
            "skip": this.filter.skip,
            "limit": this.filter.limit,
            "StrSearch": this.filter.search,
        };
        this.props.w18Actions.loadActionTabW18F1012(paramActionTab, (error, data) => {
            this.setState({loading: false});
            if (error) {
                let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                this.setState({isSubmit: false, message: message, loading: false});
                return false;
            } else {
                const {TaskID, customerNew} = this.getInfo();
                if(customerNew && !TaskID && customerNew.CompanyNameU && customerNew.CompanyID){
                    this.addActionTab({
                        CompanyName: customerNew.CompanyNameU,
                        CompanyID: customerNew.CompanyID,
                    });
                    setTimeout(()=>{
                        const state = this.props.location.state;
                        state.customerNew = null;
                        state.showPopup= false;
                        browserHistory.push({
                            pathname: Config.getRootPath() + 'W18F1012',
                            state: state
                        })
                    },100);
                }
                if (data.length > 0){
                    this.totalRecordsActionTab = data[data.length - 1].TotalRecord;
                    if((TaskID) && this.firstTime){
                        const item = data.find(i=>i.TaskID === TaskID);
                        if(item && !customerNew){
                            this.viewActionTab({row:{data:item}});
                        }
                        else{
                            if(customerNew && customerNew.CompanyNameU && customerNew.CompanyID){
                                item.CompanyName = customerNew ? customerNew.CompanyNameU : '';
                                item.CompanyID = customerNew ? customerNew.CompanyID : '';
                            }
                            this.editActionTab({row:{data:item}});
                            setTimeout(()=>{
                                const state = this.props.location.state;
                                state.customerNew = null;
                                state.showPopup= false;
                                state.TaskID= null;
                                browserHistory.push({
                                    pathname: Config.getRootPath() + 'W18F1012',
                                    state: state
                                })
                            },100);
                        }
                    }
                }
            }

        });

    }

    //TAB Document
    loadGridTabDocument() {
        const {companyID} = this.getInfo();
        // const where = "KeyID='" + companyID + "' AND TableName='D17T1010'";
        const where = JSON.stringify(
            {
                fieldName: 'KeyID',
                operator: '=',
                value: companyID
            });
        const param = {
            "where": where,
            "skip": this.filter.skip,
            "limit": this.filter.limit,
            "search": this.filter.search,
            "tableName": 'D17T1010'
        };
        this.props.w17Actions.loadW17F0001(param, (error) => {
            if (error) {
                let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
        });
    }

    loadData = (cb) => {
        cb && cb(this.dataW18F1014);
    };

    /**
     * @End Load...
     */

    /**
     * @Actions
     */

    //General..
    comeBack() {
        const {type, screenID} = this.getInfo();
        if (screenID) {
            browserHistory.goBack();
        }
        if (type === 'Customers') {
            browserHistory.push(Config.env.url + '/W18F1010?type=Customers');
        } else if (type === 'Competitor') {
            browserHistory.push(Config.env.url + '/W18F1010?type=Competitor');
        } else {
            browserHistory.push(Config.env.url + '/W18F1010?type=Partner');
        }
    }

    Detail() {
        const {companyID, type} = this.getInfo();
        if (type === 'Customers') {
            browserHistory.push({
                pathname: Config.getRootPath() + 'W18F1011',
                state: {type: 'Customers', mode: 'edit', CompanyID: companyID}
            });
            // const url = 'W18F1011?type=Customers&mode=edit&CompanyID=' + encodeURIComponent(companyID);
            // browserHistory.push(Config.env.url + '/' + url);
        } else if (type === 'Competitor') {
            browserHistory.push({
                pathname: Config.getRootPath() + 'W18F1011',
                state: {type: 'Competitor', mode: 'edit', CompanyID: companyID}
            });
            // const url = 'W18F1011?type=Competitor&mode=edit&CompanyID=' + encodeURIComponent(companyID);
            // browserHistory.push(Config.env.url + '/' + url);
        } else {
            browserHistory.push({
                pathname: Config.getRootPath() + 'W18F1011',
                state: {type: 'Partner', mode: 'edit', CompanyID: companyID}
            });
            // const url = 'W18F1011?type=Partner&mode=edit&CompanyID=' + encodeURIComponent(companyID);
            // browserHistory.push(Config.env.url + '/' + url);
        }
    }

    toggle3() {
        this.setState({collapse3: !this.state.collapse3});
    }

    handleSelect(key) {
        this.setState({key: key});
    }

    deleteRowActionTab(e) {
        const data = e.row.data;
        const param = {
            TaskID: data.TaskID,

        };
        Config.popup.show("YES_NO", Config.lang("CRM_Ban_co_chac_chan_muon_xoa_du_lieu_nay"), () => {
            this.props.w18F1012Actions.deleteTask(param, (error) => {
                if (error) {
                    let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                    Config.popup.show('INFO', message);
                    return false;
                }

                notify(Config.lang("CRM_Du_lieu_da_xoa_thanh_cong"), "success", 300);
                this.loadDataTabAction();
            })
        })


    }

    closeActionPopup() {
        this.loadDataTabAction();
        this.dataW18F1014.rowData = '';
        this.dataW18F1014.mode = '';
        this.setState({showW18F1014: false});
    }

    onAddNew() {
        const {companyID} = this.getInfo();
        this.setState({showPopup: true});
        const rowNew = {
            CompanyID: companyID,
            FullNameU: '',
            JobPositionU: '',
            Birthday: '',
            Gender: '',
            ContactStatus: '',
            NoteU: '',
            MobileNo: '',
            OfficeTelNo: '',
            HomeAddressU: '',
            Email: '',
            Email2: '',
            Facebook: '',
            Skype: '',
            IsAdmin: false,
            mode: 'add'
        };
        this.setState({row: rowNew});
    }

    onEdit(row) {
        const rowEdit = {
            CompanyID: row.CompanyID,
            ContactID: row.ContactID,
            FullNameU: row.FullNameU,
            JobPositionU: row.JobPositionU,
            Birthday: row.Birthday,
            ContactStatus: row.ContactStatus,
            Gender: row.Gender,
            NoteU: row.NoteU,
            MobileNo: row.MobileNo,
            OfficeTelNo: row.OfficeTelNo,
            HomeAddressU: row.HomeAddressU,
            Email: row.Email,
            IsAdmin: row.IsAdmin,
            Email2: row.Email2,
            Facebook: row.Facebook,
            Skype: row.Skype,
        };
        this.setState({row: rowEdit});
        this.setState({showPopup: true});
    }

    onDelete(row) {
        Config.popup.show("YES_NO", Config.lang("CRM_Ban_co_chac_chan_muon_xoa_du_lieu_nay"), () => {
            const mnParam = {
                "CompanyID": row.CompanyID,
                "ContactID": row.ContactID,
            };
            this.props.w18Actions.deleteW18F1013(mnParam, (error) => {
                if (error) {
                    let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                    Config.popup.show('INFO', message);
                    this.setState({isSubmit: false, message: message, loading: false});
                    return false;
                } else {
                    notify(Config.lang("CRM_Du_lieu_da_xoa_thanh_cong"), "success", 2000);
                    this.loadDataW13();
                }
            });
        });

    }

    onChangePageW13(page) {
        this.filterW18F1013.skip = page * this.filterW18F1013.limit;
        this.loadDataW13();
    }

    onChangePerPageW13(per) {
        this.filterW18F1013.skip = 0;
        this.filterW18F1013.limit = per;
        this.loadDataW13();
    }

    onSearchW13(text) {
        this.filterW18F1013.skip = 0;
        this.filterW18F1013.search = text;
        this.loadDataW13();
    }

    hideAddNewPopup() {
        this.setState({showPopup: false});
        this.loadDataW13();
    }

    onChangePageTabAction(page) {
        this.filter.skip = page * this.filter.limit;
        this.loadDataTabAction();
    }

    onChangePerPageTabAction(per) {
        this.filter.limit = per;
        this.loadDataTabAction();
    }

    onSearchTabAction(text) {
        this.filter.search = text;
        this.loadDataTabAction();
    }

    editActionTab(e) {
        const data = e.row.data;
        this.dataW18F1014.mode = 'edit';
        this.dataW18F1014.rowData = data;
        this.setState({
            showW18F1014: true,
        },()=>{
            if(this.firstTime){
                this.firstTime = false;
            }
        })
    }

    viewActionTab = (e) => {
        const data = e.row.data;
        this.dataW18F1014.mode = 'view';
        this.dataW18F1014.rowData = data;
        this.setState({
            showW18F1014: true,
        },()=>{
            if(this.firstTime){
                this.firstTime = false;
            }
        })
    };

    addActionTab = (data) => {
        const {dataMasterW18F1012} = this.state;
        this.dataW18F1014.mode = 'add';
        if(data && data.CompanyName && data.CompanyID){
            this.dataW18F1014.rowData = data;
        }
        else{
            this.dataW18F1014.rowData = {
                CompanyName: dataMasterW18F1012 ? dataMasterW18F1012.CompanyName : '',
                CompanyID: dataMasterW18F1012 ? dataMasterW18F1012.CompanyID : '',
            };
        }
        this.setState({showW18F1014: true})
    };

    onDeleteTabDocument(row) {
        Config.popup.show("YES_NO", Config.lang("CRM_Ban_co_chac_chan_muon_xoa_du_lieu_nay"), () => {
            const param = {"AttachmentID": row.AttachmentID};
            this.props.w17Actions.deleteW17F0001(param, (error) => {
                if (error) {
                    let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                    Config.popup.show('INFO', message);
                    return false;
                } else {
                    notify(Config.lang("CRM_Du_lieu_da_xoa_thanh_cong"), "success", 2000);
                    this.loadGridTabDocument();
                }
            });
        });
    }

    onDownloadDocument = (row) => {
        let element = document.createElement('a');
        element.setAttribute('href', row.URL);
        element.setAttribute('target', "_blank");
        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    };

    onEditTabDocument(row) {
        const rowEdit = {
            tableName: row.TableName,
            notes: row.NotesU,
            description: row.DescriptionU,
            fileName: row.FileName,
            fileSize: row.FileSize,
            url: row.URL,
            createDate: row.CreateDate,
        };
        this.setState({
            attachmentID: row.AttachmentID,
            rowDoc: rowEdit,
            showAttachment: true
        });
    }

    onChangePageTabDocument(page) {
        this.filter.skip = page * this.filter.limit;
        this.loadGridTabDocument();
    }

    onChangePerPageTabDocument(per) {
        this.filter.limit = per;
        this.loadGridTabDocument();
    }

    onSearchTabDocument(text) {
        this.filter.search = text;
        this.loadGridTabDocument();
    }

    onAddNewTabDocument() {
        const rowAdd = {
            tableName: '',
            notes: '',
            description: '',
            fileName: '',
            fileSize: 0,
            url: '',
            createDate: '',
        };
        this.setState({
            attachmentID: '',
            rowDoc: rowAdd,
            showAttachment: true
        });
    }

    hideTabDocumentPopup() {
        this.setState({showAttachment: false});
    }

    onSendMail() {
        this.setState({showSendMail: true});
    }

    hideSendMailPopup() {
        this.setState({showSendMail: false});
    }

    /**
     * @End Actions..
     */

    /**
     * @Render
     */

    renderActionDoc(e) {
        if (!e) return null;
        return (
            <GridActionBar
                isPer={this.state.iPermissionDoc}
                classNameIs={this.isPermission}
                className={this.isPermission >= 4 ? '' : 'isDisabled'}
                tooltipEdit={Config.lang("CRM_Sua")}
                onEditAction={() => {
                    this.onEditTabDocument(e.row.data)
                }}

                tooltipDelete={Config.lang("CRM_Xoa")}
                onDeleteAction={() => {
                    this.onDeleteTabDocument(e.row.data)
                }}

                tooltipDownload={Config.lang("CRM_Tap_tin")}
                onDownloadAction={() => {
                    this.onDownloadDocument(e.row.data)
                }}/>
        );
    }

    renderActionActivity(e) {
        if (!e) return null;
        return (
            <GridActionBar
                isPer={this.state.iPermissionActivity}
                classNameIs={this.isPermission}
                className={this.isPermission >= 4 ? '' : 'isDisabled'}
                tooltipEdit={Config.lang("CRM_Sua")}
                onEditAction={() => {
                    this.editActionTab(e)
                }}

                tooltipDelete={Config.lang("CRM_Xoa")}
                onDeleteAction={() => {
                    this.deleteRowActionTab(e)
                }}/>
        );
    }

    renderActionContact(e) {
        if (!e) return null;
        return (
            <GridActionBar
                isPer={this.state.iPermissionContact}
                classNameIs={this.isPermission}
                className={this.isPermission >= 4 ? '' : 'isDisabled'}
                tooltipEdit={Config.lang("CRM_Sua")}
                onEditAction={() => {
                    this.onEdit(e.row.data)
                }}

                tooltipDelete={Config.lang("CRM_Xoa")}
                onDeleteAction={() => {
                    this.onDelete(e.row.data)
                }}/>
        );
    }

    renderCompanyName = (e) => {
        if (!e) return null;
        /* eslint-disable */
        return (
            <a onClick={() => {
                this.viewActionTab(e)
            }}>{e.row.data.TitleU}</a>
        );
        /* eslint-enable */
    };

    renderIsAttach = (e) => {
        if (!e || e.row.data.CountAttach < 1) return null;
        return (
            <div>{e.row.data.CountAttach}&nbsp;&nbsp;<i className={"fas fa-paperclip text-orange "}/></div>
        )
    };

    /**
     * @End Render..
     */

     btnConvertAction = () => {
         const {companyID: CompanyID = ""} = this.getInfo();
         const {dataMasterW18F1012} = this.state;
         const param = {
            CompanyID: CompanyID || dataMasterW18F1012.CompanyID,
            CompanyName: dataMasterW18F1012.CompanyName || ""
         }
         this.props.w18Actions.btnConvertAction(param, (error, data) => {
            this.setState({loading: false});
            if (error) {
                let message = error ? error.message : Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
            if (data) {
                if(data && data.Status === 0){
                    this.setState({disableConvertBtn: true});
                    notify(Config.lang("CRM_Yeu_cau_chuyen_doi_thanh_cong"), "success", 2000)
                  }else if (data && data.Status !== 0) {
                    let message = data.Message || Config.lang("CRM_Loi_chua_xac_dinh");
                    Config.popup.show('INFO', message);
                 }
            }
        });
     }

    render() {
        if (!this.getInfo(true)) return null;
        const {companyID, type, permission, TaskID} = this.getInfo();
        const {dataMasterW18F1012, iPermission, iPermissionConvert, hideBtnConvert, disableConvertBtn} = this.state;
        const {actionTabDataW18F1012, loadW18F1013, loadW17F0001, gridDataW18F1012} = this.props;
        const {loading} = this.state;
        let totalItemsW13 = 0;
        let totalItemDocumentTab = 0;
        let dataSourceW13 = [];
        let dsDocument = [];
        if (loadW18F1013 && loadW18F1013.length > 0) {
            loadW18F1013.map(o => {
                const row = {
                    CompanyID: o.CompanyID,
                    ContactID: o.ContactID,
                    FullNameU: o.FullNameU,
                    JobPositionU: o.JobPositionU,
                    Gender: o.Gender,
                    GenderName: o.GenderName,
                    Birthday: o.Birthday,
                    IsAdmin: o.IsAdmin,
                    MobileNo: o.MobileNo,
                    OfficeTelNo: o.OfficeTelNo,
                    HomeAddressU: o.HomeAddressU,
                    ContactStatus: o.ContactStatus,
                    NoteU: o.NoteU,
                    Email: o.Email,
                    Email2: o.Email2,
                    Facebook: o.Facebook,
                    Skype: o.Skype,
                    ContactStatusName: o.ContactStatusName,
                };
                totalItemsW13 = o.TotalRecord;
                dataSourceW13.push(row);
                return o;
            });
        }
        if (loadW17F0001) {
            loadW17F0001.data.rows.map(o => {
                const row = {
                    CompanyID: companyID,
                    DescriptionU: o.DescriptionU,
                    NotesU: o.NotesU,
                    FileName: o.FileName,
                    CreateDate: o.CreateDate,
                    // URL: o.URL,
                    URL: Config.env.api + '/file/get?AttachmentID=' + o.AttachmentID,
                    AttachmentID: o.AttachmentID,
                };
                totalItemDocumentTab = loadW17F0001.data.total;
                dsDocument.push(row);
                return o;
            });
        }

        let titleGird = '';
        let titleLable = '';
        let titleLableID = '';
        if (type === 'Customers') {
            titleGird = Config.lang("CRM_Khach_hang");
            titleLable = Config.lang("CRM_Ten_khach_hang");
            titleLableID = Config.lang("CRM_Ma_khach_hang");
        } else if (type === 'Competitor') {
            titleGird = Config.lang("CRM_Doi_thu");
            titleLable = Config.lang("CRM_Ten_doi_thu");
            titleLableID = Config.lang("CRM_Ma_doi_thu");
        } else {
            titleGird = Config.lang("CRM_Doi_tac");
            titleLable = Config.lang("CRM_Ten_doi_tac");
            titleLableID = Config.lang("CRM_Ma_doi_tac");
        }

        return (
            <div className="page-container" style={{margin: 0}}>
                {Config && Config.profile &&
                <EventTracking category={"TRACKING_USER"} action={"W18F1012"} label={Config.profile.UserID}/>}
                {this.state.showW18F1014 &&
                <ActionTab loadData={this.loadData}
                           showComment={this.firstTime && TaskID}
                           iPermission={iPermission}
                           data={this.dataW18F1014}
                           formID={'W18F1012'}
                           state={this.getInfo()}
                           onHide={() => this.closeActionPopup()}
                />
                }
                <div className="grid-title" style={{marginLeft: '10px'}}>{titleGird}</div>
                <Form horizontal={true} id={'frmW18F1012'} style={{margin: '0 15px'}}>
                    <Row className="form-group">
                        <Col md={12} sm={12} xl={12} xs={12} lg={12}>
                            <ButtonCustom color={'text-orange'} icon={'fas fa-arrow-alt-left'} isAwesome={true}
                                          name={Config.lang("CRM_Quay_lai")}
                                          onClick={() => {
                                              this.comeBack()
                                          }}/>
                            <ButtonEdit
                                className={`${this.state.iPermission > 1 && this.isPermission <= 1 ? 'mgl5' : 'isDisabled mgl5'}` && permission <= 1 ? 'hide mgl5' : 'mgl5'}
                                name={Config.lang("CRM_Sua")} onClick={() => {
                                if (this.state.iPermission > 1) {
                                    this.Detail()
                                }
                            }}/>
                            <ButtonConvert
                                className={(permission <= 1 || iPermissionConvert !== 4 || hideBtnConvert) ? 'hide mgl5' : 'mgl5' && disableConvertBtn ? 'isDisabled mgl5' : 'mgl5'}
                                name={Config.lang("CRM_Yeu_cau_chuyen_doi")} onClick={this.btnConvertAction}/>
                        </Col>

                    </Row>
                    <FormGroup>
                        <Col lg={2} md={2} sm={2} xs={12}>
                            <label className={'control-label'}>{titleLableID}</label>
                        </Col>
                        <Col className={'control-label'} xl={4} lg={4} md={4} sm={4} xs={12}>
                            {dataMasterW18F1012 && dataMasterW18F1012.CompanyID}
                        </Col>
                        <Col md={2} lg={2} sm={2} xs={12}>
                            <label className={'control-label'}>{titleLable}</label>
                        </Col>
                        <Col className={'control-label'} xl={4} lg={4} md={4} sm={4} xs={12}>
                            {dataMasterW18F1012 && dataMasterW18F1012.CompanyName}
                        </Col>
                    </FormGroup>
                    <FormGroup>
                        <Col md={2} lg={2} sm={2} xs={12}>
                            <label className={'control-label'}>{Config.lang("CRM_Nhan_vien")}</label>
                        </Col>
                        <Col className={'control-label'} xl={4} lg={4} md={4} sm={4} xs={12}>
                            {dataMasterW18F1012 && dataMasterW18F1012.SalesPersonName}
                        </Col>
                        <Col md={2} lg={2} sm={2} xs={12}>
                            <label className={'control-label'}>{Config.lang("CRM_Dia_chi_DKKD")}</label>
                        </Col>
                        <Col className={'control-label'} xl={4} lg={4} md={4} sm={4} xs={12}>
                            {dataMasterW18F1012 && dataMasterW18F1012.AddressLine}
                        </Col>
                    </FormGroup>
                    <FormGroup >
                        <Col md={2} sm={2} xs={12} lg={2}>
                            <label className={'control-label'}>{Config.lang("CRM_Dien_thoai")}</label>
                        </Col>
                        <Col className={'control-label'} md={4} sm={4} xl={4} xs={12} lg={4}>
                            {dataMasterW18F1012 && dataMasterW18F1012.Telephone}
                        </Col>
                        <Col md={2} sm={2} xs={12} lg={2}>
                            <label className={'control-label'}>{Config.lang("CRM_Dia_diem_giao_dich")}</label>
                        </Col>
                        <Col className={'control-label'} md={4} sm={4} xl={4} xs={12} lg={4}>
                            {dataMasterW18F1012 && dataMasterW18F1012.ShipPlace}
                        </Col>
                    </FormGroup>
                    <FormGroup >
                        <Col md={2} sm={2} xs={12} lg={2}>
                            <label className={'control-label'}>{Config.lang(('CRM_So_fax'))}</label>
                        </Col>
                        <Col className={'control-label'} md={4} sm={4} xl={4} xs={12} lg={4}>
                            {dataMasterW18F1012 && dataMasterW18F1012.CompanyFaxNo}
                        </Col>
                        <Col md={2} sm={2} xs={12} lg={2}>
                            <label className={'control-label'}>Email</label>
                        </Col>
                        <Col className={'control-label'} md={4} sm={4} xl={4} xs={12} lg={4}>
                            {dataMasterW18F1012 && dataMasterW18F1012.Email}
                        </Col>
                    </FormGroup>
                    <FormGroup>
                        <Col md={2} sm={2} xs={12} lg={2}>
                            <label className={'control-label'}>Website</label>
                        </Col>
                        <Col className={'control-label'} md={4} sm={4} xl={4} xs={12} lg={4}>
                            {dataMasterW18F1012 && dataMasterW18F1012.Website}
                        </Col>
                    </FormGroup>


                    <Collapse in={this.state.collapse3}>
                        <div>
                            <FormGroup>
                                <Col md={2} sm={2} xs={12} lg={2}>
                                    <label className={'control-label'}>{Config.lang("CRM_Ngay_thanh_lap")}</label>
                                </Col>
                                <Col className={'control-label'} md={4} sm={4} xl={4} xs={12} lg={4}>
                                    {dataMasterW18F1012 ? dataMasterW18F1012.DateOfBirth ? moment(dataMasterW18F1012.DateOfBirth).format("DD/MM/YYYY") : null : null}
                                </Col>
                                <Col md={2} sm={2} xs={12} lg={2}>
                                    <label className={'control-label'}>{Config.lang("CRM_Ngay_tao")}</label>
                                </Col>
                                <Col className={'control-label'} md={4} sm={4} xl={4} xs={12} lg={4}>
                                    {dataMasterW18F1012 ? dataMasterW18F1012.CreateDate ? moment(dataMasterW18F1012.CreateDate).format("DD/MM/YYYY") : null : null}
                                </Col>
                            </FormGroup>
                            <FormGroup>
                                <Col md={2} sm={2} xs={12} lg={2}>
                                    <label className={'control-label'}>{Config.lang("CRM_Ma_so_thue")}</label>
                                </Col>
                                <Col className={'control-label mgb15'} md={4} sm={4} xl={4} xs={12} lg={4}>
                                    {dataMasterW18F1012 && dataMasterW18F1012.TaxNo}
                                </Col>
                                <Col md={2} sm={2} xs={12} lg={2}>
                                    <label className={'control-label'}>{Config.lang("CRM_Ten_tat")}</label>
                                </Col>
                                <Col className={'control-label mgb15'} md={4} sm={4} xl={4} xs={12} lg={4}>
                                    {dataMasterW18F1012 && dataMasterW18F1012.CompanyShort}
                                </Col>
                            </FormGroup>
                            <FormGroup >
                                <Col md={2} sm={2} xs={12} lg={2}>
                                    <label className={'control-label'}>{Config.lang("CRM_Ap_Khu_pho")}</label>
                                </Col>
                                <Col className={'control-label mgb15'} md={4} sm={4} xl={4} xs={12} lg={4}>
                                    {dataMasterW18F1012 && dataMasterW18F1012.Quarter}
                                </Col>
                                <Col md={2} sm={2} xs={12} lg={2}>
                                    <label className={'control-label'}>{Config.lang("CRM_Nguon_goc")}</label>
                                </Col>
                                <Col className={'control-label mgb15'} md={4} sm={4} xl={4} xs={12} lg={4}>
                                    {dataMasterW18F1012 && dataMasterW18F1012.CaseSource}
                                </Col>
                            </FormGroup>
                            <FormGroup >
                                <Col md={2} sm={2} xs={12} lg={2}>
                                    <label className={'control-label'}>{Config.lang("CRM_Phuong_Xa")}</label>
                                </Col>
                                <Col className={'control-label mgb15'} md={4} sm={4} xl={4} xs={12} lg={4}>
                                    {dataMasterW18F1012 && dataMasterW18F1012.WardName}
                                </Col>
                                <Col md={2} sm={2} xs={12} lg={2}>
                                    <label className={'control-label'}>{Config.lang("CRM_Nhom_nganh_nghe")}</label>
                                </Col>
                                <Col className={'control-label mgb15'} md={4} sm={4} xl={4} xs={12} lg={4}>
                                    {dataMasterW18F1012 && dataMasterW18F1012.IndustryGroupName}
                                </Col>
                            </FormGroup>
                            <FormGroup>
                                <Col md={2} sm={2} xs={12} lg={2}>
                                    <label className={'control-label'}>{Config.lang("CRM_Quan_Huyen")}</label>
                                </Col>
                                <Col className={'control-label mgb15'} md={4} sm={4} xl={4} xs={12} lg={4}>
                                    {dataMasterW18F1012 && dataMasterW18F1012.DistrictName}
                                </Col>
                                {type && <div className={type === 'Customers' ? '' : 'hide'}>
                                    <Col md={2} sm={2} xs={12} lg={2}>
                                        <label className={'control-label'}>{Config.lang("CRM_Phan_loai")}</label>
                                    </Col>
                                    <Col className={'control-label mgb15'} md={4} sm={4} xl={4} xs={12} lg={4}>
                                        {dataMasterW18F1012 && dataMasterW18F1012.CompanyKindName}
                                    </Col>
                                </div>}
                                {type && <div className={type === 'Competitor' ? '' : 'hide'}>
                                    <Col md={2} sm={2} xs={12} lg={2}>
                                        <label
                                            className={'control-label'}>{Config.lang("CRM_Phan_loai_doi_thu")}</label>
                                    </Col>
                                    <Col className={'control-label mgb15'} md={4} sm={4} xl={4} xs={12} lg={4}>
                                        {dataMasterW18F1012 && dataMasterW18F1012.CompetitorTypeName}
                                    </Col>
                                </div>}
                                {type && <div className={type === 'Partner' ? '' : 'hide'}>
                                    <Col md={2} sm={2} xs={12} lg={2}>
                                        <label
                                            className={'control-label'}>{Config.lang("CRM_Phan_loai_doi_tac")}</label>
                                    </Col>
                                    <Col className={'control-label mgb15'} md={4} sm={4} xl={4} xs={12} lg={4}>
                                        {dataMasterW18F1012 && dataMasterW18F1012.PartnerTypeName}
                                    </Col>
                                </div>}
                            </FormGroup>
                            <FormGroup >
                                <Col md={2} sm={2} xs={12} lg={2}>
                                    <label className={'control-label'}>{Config.lang("CRM_Tinh_Thanh_pho")}</label>
                                </Col>
                                <Col className={'control-label mgb15'} md={4} sm={4} xl={4} xs={12} lg={4}>
                                    {dataMasterW18F1012 && dataMasterW18F1012.ProvinceName}
                                </Col>
                                <Col md={2} sm={2} xs={12} lg={2}>
                                    <label className={'control-label'}>{Config.lang("CRM_Nhom_khach_hang")}</label>
                                </Col>
                                <Col className={'control-label mgb15'} md={4} sm={4} xl={4} xs={12} lg={4}>
                                    {dataMasterW18F1012 && dataMasterW18F1012.CompanyGroupName}
                                </Col>
                            </FormGroup>
                            <FormGroup >
                                {/*<Col md={2} sm={2} xs={12} lg={2}>*/}
                                {/*    <label className={'control-label'}>{Config.lang("CRM_Nhom_kinh_doanh")}</label>*/}
                                {/*</Col>*/}
                                {/*<Col className={'control-label mgb15'} md={4} sm={4} xl={4} xs={12} lg={4}>*/}
                                {/*    {dataMasterW18F1012 && dataMasterW18F1012.GroupSalesName}*/}
                                {/*</Col>*/}
                                <Col md={2} sm={2} xs={12} lg={2}>
                                    <label className={'control-label'}>{Config.lang("CRM_Trang_thai")}</label>
                                </Col>
                                <Col className={'control-label mgb15'} md={4} sm={4} xl={4} xs={12} lg={4}>
                                    {dataMasterW18F1012 && dataMasterW18F1012.CompanyStatusName}
                                </Col>
                                <Col md={2} sm={2} xs={12} lg={2}>
                                    <label className={'control-label'}>{Config.lang("CRM_Ma_doi_tuong")}</label>
                                </Col>
                                <Col className={'control-label mgb15'} md={4} sm={4} xl={4} xs={12} lg={4}>
                                    {dataMasterW18F1012 && dataMasterW18F1012.ObjectID}
                                </Col>
                            </FormGroup>
                            <FormGroup >
                                <Col md={2} sm={2} xs={12} lg={2}>
                                    <label className={'control-label'}>{Config.lang("CRM_Mo_ta")}</label>
                                </Col>
                                <Col className={'control-label mgb15'} md={4} sm={4} xl={4} xs={12} lg={4}>
                                    {dataMasterW18F1012 && dataMasterW18F1012.NotesU}
                                </Col>
                            </FormGroup>
                            {gridDataW18F1012 && gridDataW18F1012.map((value) => {
                                return (
                                    <FormGroup key={value.NormName}>
                                        <Col md={2} sm={2} xl={2} xs={12} lg={2}>
                                            <label className={'control-label'}>{value.NormName}</label>
                                        </Col>
                                        {value.ControlType &&
                                        <Col className={'control-label'} md={10} sm={10} xl={10} xs={12} lg={10}>
                                            {value.ControlType === 'C' ? value.ValueName : value.Note}
                                        </Col>
                                        }
                                    </FormGroup>
                                )
                            })}
                        </div>
                    </Collapse>

                    <FormGroup className={'mgt10'}>
                        <Col md={2} sm={2} xl={2} xs={12} lg={2}>
                                    <span className={'linkExpand'} onClick={() => {
                                        this.toggle3()
                                    }}>{this.state.collapse3 ? Config.lang("CRM_Xem_it_hon") : Config.lang("CRM_Xem_nhieu_hon")}</span>
                        </Col>
                    </FormGroup>

                    <FormGroup>

                        <Col md={12} sm={12} xl={12} xs={12} lg={12}>
                            {(this.state.iPermissionActivity > 0 || this.state.iPermissionDelivery > 0 || this.state.iPermissionContact > 0 || this.state.iPermissionDoc > 0 || this.state.iPermissionW18F1018 > 0 || this.state.iPermissionW18F1017 > 0) &&
                            <Tabs
                                defaultActiveKey={this.state.iPermissionActivity > 0 ? 1 : (this.state.iPermissionContact > 0 ? 2 : 3)}
                                id="tabMain"
                                activeKey={this.state.key}
                                animation={true}
                                unmountOnExit={true}
                                mountOnEnter={true}
                                onSelect={(key) => this.handleSelect(key)}
                            >
                                {this.state.iPermissionActivity > 0 &&
                                <Tab eventKey={1} title={Config.lang("CRM_Hoat_Dong")}>
                                    <GridContainer
                                        allowAdding={this.isPermission}
                                        isPer={this.state.iPermissionActivity}
                                        itemPerPage={this.filter.limit}
                                        totalItems={Number(this.totalRecordsActionTab)}
                                        //itemPerPage={this.filter.limit}
                                        onAddNew={this.addActionTab}
                                        onSearch={(text) => {
                                            this.onSearchTabAction(text)
                                        }}
                                        onChangePage={(page) => {
                                            this.onChangePageTabAction(page)
                                        }}
                                        onChangePerPage={(per) => {
                                            this.onChangePerPageTabAction(per)
                                        }}
                                        dataSource={actionTabDataW18F1012}>

                                        <Column
                                            cellRender={(e) => this.renderActionActivity(e)}
                                            dataField={'Action'}
                                            caption={Config.lang("CRM_Hanh_dong")}
                                            alignment={'center'}
                                            allowSorting={false}
                                            showInColumnChooser={false}
                                            width={120}
                                            fixed={true}
                                        />
                                        <Column
                                            dataField={'TitleU'}
                                            caption={Config.lang("CRM_Ten_Cong_viec")}
                                            allowSorting={true}
                                            allowReordering={false}
                                            width={350}
                                            cellRender={this.renderCompanyName}
                                        />
                                        <Column
                                            dataField={'SalesPersonName'}
                                            caption={Config.lang("CRM_Nguoi_phu_trach")}
                                            allowSorting={true}
                                            allowReordering={false}
                                            width={200}
                                        />

                                        <Column
                                            dataField={'ContactName'}
                                            caption={Config.lang("CRM_Nguoi_lien_he")}
                                            width={200}
                                        />
                                        <Column
                                            dataField={'TaskTypeName'}
                                            width={200}

                                            caption={Config.lang("CRM_Loai_cong_viec")}/>

                                        <Column
                                            cellRender={(e) => {
                                                const data = e.data;
                                                return `${data.TaskDateFrom ? moment(data.TaskDateFrom).format("DD/MM/YYYY hh:mm A") : ''} ${data.TaskDateFrom && data.TaskDateTo ? '-' : ''} ${data.TaskDateTo ? moment(data.TaskDateTo).format("DD/MM/YYYY hh:mm A") : ''}`;
                                            }}
                                            dataField={'OfficeTelNo'}
                                            caption={Config.lang("CRM_Thoi_gian_thuc_hien")}
                                            width={300}

                                        />
                                        <Column
                                            dataField={'TaskStatusName'}
                                            caption={Config.lang("CRM_Trang_thai")}
                                            width={200}
                                        />
                                        <Column
                                            dataField={'IsAttach'}
                                            caption={Config.lang("CRM_Tai_lieu")}
                                            alignment={'center'}
                                            width={80}
                                            allowSorting={false}
                                            cellRender={this.renderIsAttach}
                                        />
                                        <Column
                                            dataField={'NotesU'}
                                            caption={Config.lang("CRM_Mo_ta")}
                                            width={200}

                                        />
                                        <Column
                                            dataField={'ActEvaluationU'}
                                            caption={Config.lang("CRM_Danh_gia")}
                                            width={300}

                                        />
                                        <Column
                                            dataField={'ResultsU'}
                                            caption={Config.lang("CRM_Ket_qua")}
                                            width={300}

                                        />

                                    </GridContainer>
                                </Tab>}
                                {this.state.iPermissionContact > 0 &&
                                <Tab eventKey={2} title={Config.lang("CRM_Lien_he")}>
                                    {dataSourceW13 && <GridContainer
                                        allowAdding={this.isPermission}
                                        isPer={this.state.iPermissionContact}
                                        dataSource={dataSourceW13}
                                        onAddNew={() => {
                                            this.onAddNew()
                                        }}
                                        showSendMail={true}
                                        onSendMail={() => {
                                            this.onSendMail()
                                        }}
                                        totalItems={totalItemsW13}
                                        itemPerPage={this.filterW18F1013.limit}
                                        onSearch={(text) => {
                                            this.onSearchW13(text)
                                        }}
                                        onChangePage={(page) => {
                                            this.onChangePageW13(page)
                                        }}
                                        onChangePerPage={(per) => {
                                            this.onChangePerPageW13(per)
                                        }}
                                    >
                                        <Column
                                            cellRender={(e) => this.renderActionContact(e)}
                                            dataField={'Action'}
                                            caption={Config.lang("CRM_Hanh_dong")}
                                            alignment={'center'}
                                            allowSorting={false}
                                            showInColumnChooser={false}
                                            width={120}
                                            fixed={true}
                                        />
                                        <Column
                                            dataField={'FullNameU'}
                                            caption={Config.lang("CRM_Nguoi_lien_he")}
                                            allowSorting={true}
                                            allowReordering={false}
                                            width={220}
                                            fixed={window.innerWidth>600}
                                        />
                                        <Column
                                            dataField={'JobPositionU'}
                                            caption={Config.lang("CRM_Chuc_vu")}
                                            allowSorting={true}
                                            allowReordering={false}
                                            width={180}
                                            fixed={window.innerWidth>600}
                                        />
                                        <Column
                                            dataField={'IsAdmin'}
                                            caption={Config.lang("CRM_Lien_he_chinh")}
                                            dataType={'boolean'}
                                            allowSorting={true}
                                            allowReordering={false}
                                            fixed={window.innerWidth>600}
                                            width={120}
                                        />
                                        <Column
                                            dataField={'GenderName'}
                                            caption={Config.lang("CRM_Gioi_tinh")}
                                            width={80}
                                        />
                                        <Column
                                            dataField={'MobileNo'}
                                            width={140}
                                            caption={Config.lang("CRM_So_di_dong")}/>
                                        <Column
                                            dataField={'OfficeTelNo'}
                                            caption={Config.lang("CRM_So_may_ban")}
                                            width={140}
                                        />
                                        <Column
                                            dataField={'Email'}
                                            caption={'Email'}
                                            width={200}
                                        />
                                        <Column
                                            dataField={'Skype'}
                                            caption={'Skype'}
                                            width={200}
                                        />
                                        <Column
                                            dataField={'HomeAddressU'}
                                            caption={Config.lang("CRM_Dia_chi")}
                                            width={260}
                                        />
                                        <Column
                                            dataField={'ContactStatusName'}
                                            caption={Config.lang("CRM_Trang_thai")}
                                            width={200}
                                            cellRender={(e) => {
                                                return <div><i className="fas fa-circle pd5" style={{
                                                    fontSize: '12px',
                                                    color: e && e.data && e.data.ContactStatus === "A" ? "#2FBA27" : "#FFC90E"
                                                }}/>{e.value}</div>
                                            }}
                                            allowSorting={true}
                                        />
                                    </GridContainer>}
                                </Tab>}
                                {this.state.iPermissionDelivery > 0 &&
                                <Tab eventKey={3} title={Config.lang("CRM_Giao_hang")}>
                                    <W18F1012Delivery permission={'D17F1027'} companyID={companyID}
                                        ObjectID={dataMasterW18F1012 && dataMasterW18F1012.ObjectID}
                                        ObjectTypeID={dataMasterW18F1012 && dataMasterW18F1012.ObjectTypeID}
                                        iPermission={this.state.iPermissionDelivery}
                                        allowAdding={this.isPermission}/>
                                </Tab>}
                                {this.state.iPermissionDoc > 0 &&
                                <Tab eventKey={4} title={Config.lang("CRM_Tai_lieu")}>
                                    <GridContainer
                                        allowAdding={this.isPermission}
                                        isPer={this.state.iPermissionDoc}
                                        dataSource={dsDocument}
                                        keyExpr={'AttachmentID'}
                                        showBorders={false}
                                        showColumnLines={true}
                                        selection={{mode: 'single'}}
                                        allowColumnReordering={true}
                                        rowAlternationEnabled={true}
                                        onAddNew={() => {
                                            this.onAddNewTabDocument()
                                        }}
                                        totalItems={totalItemDocumentTab}
                                        itemPerPage={this.filter.limit}
                                        onSearch={(text) => {
                                            this.onSearchTabDocument(text)
                                        }}
                                        onChangePage={(page) => {
                                            this.onChangePageTabDocument(page)
                                        }}
                                        onChangePerPage={(per) => {
                                            this.onChangePerPageTabDocument(per)
                                        }}
                                    >
                                        {/*<Paging enabled={false}/>*/}
                                        {/*<Paging defaultPageSize={15}/>*/}
                                        {/*<Pager*/}
                                        {/*showPageSizeSelector={true}*/}
                                        {/*allowedPageSizes={[10, 15, 20]}*/}
                                        {/*showInfo={true}/>*/}
                                        <Column dataField={'DescriptionU'} caption={Config.lang("CRM_Tieu_de")}
                                                width={300}
                                                dataType={'string'}/>
                                        <Column dataField={'NotesU'} caption={Config.lang("CRM_Mo_ta")} width={"100%"}
                                                minWidth={400}
                                                dataType={'string'}/>
                                        <Column
                                            dataField={'FileName'}
                                            caption={Config.lang("CRM_Tai_lieu")}
                                            minWidth={300}
                                            width={"100%"}
                                            alignment='left'
                                        />

                                        <Column dataField={'CreateDate'} caption={Config.lang("CRM_Ngay_cap_nhat")}
                                                alignment='center'
                                                format="dd/MM/yyyy"
                                                width={150} dataType={'date'}/>
                                        <Column
                                            cellRender={(data) => this.renderActionDoc(data)}
                                            dataField={'Action'}
                                            caption={Config.lang("CRM_Hanh_dong")}
                                            alignment={'center'}
                                            allowSorting={false}
                                            showInColumnChooser={false}
                                            width={120}
                                            fixed={true}
                                        />
                                        <Column dataField={'URL'} caption="URL" width={150} visible={false}/>
                                        <Column dataField={'AttachmentID'} caption="AttachmentID" width={150}
                                                visible={false}/>
                                    </GridContainer>
                                </Tab>}
                                {this.state.iPermissionW18F1018 > 0 &&
                                <Tab eventKey={5} title={Config.lang("CRM_Nhom_hang_chu_luc")}>
                                    <W18F1018 companyID={companyID} isPermission={this.state.iPermissionW18F1018}/>
                                </Tab>}
                                {this.state.iPermissionW18F1017 > 0 &&
                                <Tab eventKey={6} title={Config.lang("CRM_Mat_hang_chu_luc")}>
                                    <W18F1017 companyID={companyID} iPermission={this.state.iPermissionW18F1017}/>
                                </Tab>}
                                {this.state.iPermissionPrice > 0 &&
                                <Tab eventKey={7} title={Config.lang("CRM_Bao_gia")}>
                                    <W18F1012Price
                                        objectID={dataMasterW18F1012 && dataMasterW18F1012.ObjectID}
                                        companyID={companyID}
                                        iPermission={this.state.iPermissionPrice}
                                    />
                                </Tab>}
                                {this.state.iPermissionOrder > 0 &&
                                <Tab eventKey={8} title={Config.lang("CRM_Don_hang")}>
                                    <W18F1012Order companyID={companyID} iPermission={this.state.iPermissionOrder}/>
                                </Tab>}
                                {this.state.iPermissionCase > 0 &&
                                <Tab eventKey={9} title={Config.lang("CRM_Tinh_huong")}>
                                    <W18F1012Case permission={'D17F1861'} companyID={companyID}
                                                  companyName={dataMasterW18F1012 && dataMasterW18F1012.CompanyName}
                                                  address={dataMasterW18F1012 && dataMasterW18F1012.AddressLine}
                                                  email={dataMasterW18F1012 && dataMasterW18F1012.Email}
                                                  telephone={dataMasterW18F1012 && dataMasterW18F1012.Telephone}
                                                  iPermission={this.state.iPermissionCase}
                                                  allowAdding={this.isPermission}/>
                                </Tab>}
                            </Tabs>}
                        </Col>


                    </FormGroup>
                    {loading && <Loading/>}
                </Form>
                {this.state.showPopup && <PopupView
                    className={'popup-size-90'}
                    title={Config.lang("CRM_Cap_nhat_lien_he")}
                    show={true}
                    animation={true}
                    onHide={() => this.hideAddNewPopup()}>
                    <Detail data={this.state.row}
                            onHide={() => this.hideAddNewPopup()}
                            onReloadGrid={() => this.loadDataW13()}/>
                </PopupView>}
                {this.state.showAttachment && <PopupView
                    title={Config.lang("CRM_Dinh_kem")}
                    show={true}
                    animation={true}
                    onHide={() => this.hideTabDocumentPopup()}>
                    <Attachment attachmentID={this.state.attachmentID}
                                tableName="D17T1010"
                                caption={Config.lang("CRM_Khach_hang")}
                                id={dataMasterW18F1012 && dataMasterW18F1012.CompanyID}
                                value={dataMasterW18F1012 && dataMasterW18F1012.CompanyName}
                                data={this.state.rowDoc}
                                onHide={() => this.hideTabDocumentPopup()}
                                onReloadGrid={() => this.loadGridTabDocument()}/>
                </PopupView>}
                {this.state.showSendMail && <PopupScroll
                    title={Config.lang("CRM_Gui_mail")}
                    show={true}
                    animation={true}
                    onHide={() => this.hideSendMailPopup()}>
                    <SendMail companyID={window.location.search.split('CompanyID=')[1]}
                              onHide={() => this.hideSendMailPopup()}/>
                </PopupScroll>}
            </div>

        )
    }
}

export default connect(state => ({
        loadW18F1013: state.w18.loadW18F1013,
        deleteW18F1013: state.w18.deleteW18F1013,
        dataMasterW18F1012: state.w18.dataMasterW18F1012,
        gridDataW18F1012: state.w18.gridDataW18F1012,
        actionTabDataW18F1012: state.w18.actionTabDataW18F1012,
        loadW17F0001: state.w17.loadW17F0001,
        delW17F0001: state.w17.delW17F0001,
        getW17F0001: state.w17.getW17F0001,

    }),
    (dispatch) => ({
        w17Actions: bindActionCreators(w17Actions, dispatch),
        w18Actions: bindActionCreators(w18Actions, dispatch),
        w18F1012Actions: bindActionCreators(w18F1012Actions, dispatch),
        userActions: bindActionCreators(userActions, dispatch),
    })
)(W18F1012Page);
