/**
 * @copyright 2019 @ DigiNet
 * @author VINHLE
 * @create 2019/01/11 10:37
 * @update 2019/01/11 10:37
 */

import React from 'react';
import {connect} from "react-redux";

import {Col, Form, FormGroup} from "react-bootstrap";
import EventTracking from "../common/tracking/event";
import GridContainer from "../common/grid-container/grid-container";
import {Column} from "devextreme-react/data-grid";
import Config from "../../config";
import {bindActionCreators} from "redux";
import * as w18f1016Actions from "../../actions/w18/w18F1016/w18F1016-actions";
import * as userActions from "../../actions/user-actions";
import {Loading} from "../common/loading/loading";
import PopupW18F1023 from "../W18/W18F1023";
import {browserHistory} from "react-router";



class W18F1016Page extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            showW18F1023:false,
            iPermission: 0

        };
        this.timer = null;

        this.filter = {
            skip: 0,
            limit: 20,
            search: '',
            searchID: '',
            CompanyStatus: '',

        };
        this.rowData=[];
        this.timeDeplay = Config.getSetting("TIME_SUBMIT_DELAY") || 2000;


    };

    async loadPermission() {
        await this.props.userActions.getPermission(Config.profile.UserID, "D17F1010", (iPer) => {
            this.setState({iPermission: iPer});
        });
    }

    componentWillUnmount() {
        if (this.timer) clearTimeout(this.timer);
    }

    loadDataGrid() {
        this.setState({loading: true});
        this.timer = setTimeout(() => {
            this.setState({loading: false});
        }, this.timeDeplay);
        const uName = Config.profile.UserID || '';
        const uLanguage = Config.language || '';
        const params = {
            "UserID": uName,
            "Language": uLanguage,
            "FormID": '',
            "StrSearch": this.filter.search,
            "skip": this.filter.skip,
            "limit": this.filter.limit,
            "CompanyID": '',
        };
        this.props.w18f1016Actions.dataGrid(params, (error, data) => {
            if (error) {

                this.setState({loading: false});
                Config.popup.show('INFO', `${Config.lang(`${Config.lang("CRM_Loi_chua_xac_dinh")}`)}`);
            }
            if (data) {
                this.setState({loading: false});
            }
        });

    }

    componentDidMount = async () => {
        await this.loadPermission();
        if(this.state.iPermission <= 0) return;
        this.loadDataGrid()
    }

    onChangePage(page) {
        this.filter.skip = page * this.filter.limit;
        this.loadDataGrid();
    }

    onChangePerPage(per) {
        this.filter.skip = 0;
        this.filter.limit = per;
        this.loadDataGrid();
    }

    onSearch(text) {
        this.filter.skip = 0;
        this.filter.search = text;
        this.loadDataGrid();
    }
    onHide(){
        this.setState({showW18F1023:false})
        this.loadDataGrid();
    }
    reDirect(data){
        browserHistory.push({
            pathname: Config.getRootPath() + 'W18F1012',
            state: {type: 'Customers', Permission: 4, CompanyID: data}
        });
    }
    render() {
        const {dataGrid} = this.props;
        return (
            <div className="page-container">
                {Config && Config.profile && <EventTracking category={"TRACKING_USER"} action={"W18F1016"} label={Config.profile.UserID} /> }

                <Form horizontal={true} id={'frmW18F1016'}>
                    {this.state.showW18F1023&&<PopupW18F1023 data={this.rowData} onHide={()=>{this.onHide()}} />}
                    <FormGroup>
                        <Col md={12} sm={12} xl={12} xs={12} lg={12}>
                            {this.state.loading && <Loading/>}
                            {dataGrid&& <GridContainer
                                title={Config.lang("CRM_Danh_sach_khach_hang_dung_chung")}
                                dataSource={ dataGrid}
                                totalItems={dataGrid.length>0?dataGrid[0].TotalRecord:0}
                                selection={{
                                    mode: 'multiple',
                                    allowSelectAll:true,
                                    showCheckBoxesMode:'always'
                                }}
                                buttonCRM={[{
                                    buttonName: `${Config.lang("CRM_Chuyen_chu_so_huu")}`,
                                    icon:'fas fa-share',
                                    disabled:this.state.iPermission<2,
                                    onClick:()=>{
                                        if(this.rowData.length<=0){
                                            Config.popup.show('INFO', `${Config.lang("CRM_Ban_chua_chon_khach_hang")}`);
                                            return false;
                                        }

                                        this.setState({
                                            showW18F1023:true
                                        })
                                    }
                                },


                                ]

                                }

                                onSelectionChanged={(e) => {
                                   this.rowData=e.selectedRowsData;
                                }}
                                itemPerPage={this.filter.limit}

                                onFilter={(o) => {
                                    this.onFilter(o)
                                }}
                                onSearch={(text) => {
                                    this.onSearch(text)
                                }}
                                onChangePage={(page) => {
                                    this.onChangePage(page)
                                }}
                                onChangePerPage={(per) => {
                                    this.onChangePerPage(per)
                                }}>

                                >
                                <Column
                                    dataField={'CompanyID'}
                                    caption={`${Config.lang("CRM_Ma_khach_hang")}`}
                                    alignment={'center'}
                                    allowSorting={false}
                                    showInColumnChooser={false}
                                    width={180}
                                    fixed={true}
                                />
                                <Column
                                    dataField={'CompanyNameU'}
                                    caption={`${Config.lang("CRM_Ten_khach_hang")}`}
                                    cellRender={(e)=>{
                                        if(!e) return null;
                                        const row=e.row.data;
                                        if (row.CompanyNameU) {
                                            /* eslint-disable */
                                            return (<div onClick={()=>{ this.reDirect(row.CompanyID)}}><a>{row.CompanyNameU}</a></div>)
                                            /* eslint-enable */
                                        }
                                        else {
                                            return null
                                        }
                                    }}
                                    allowSorting={true}
                                    width={200}
                                    fixed={true}
                                />
                                <Column
                                    dataField={'AddressLineU'}
                                    caption={`${Config.lang("CRM_Dia_chi_DKKD")}`}
                                    sortOrder={'asc'}
                                    width={350}

                                    fixed={true}
                                />

                                <Column
                                    dataField={'Email'}
                                    width={200}
                                    caption={'Email'}/>
                                <Column
                                    dataField={'Telephone'}
                                    caption={`${Config.lang("CRM_Dien_thoai")}`}
                                    width={200}/>
                                <Column
                                    dataField={'SalesPersonNameU'}
                                    width={200}
                                    caption={`${Config.lang("CRM_Nhan_vien")}`}/>
                                <Column
                                    dataField={'NotesU'}
                                    caption={`${Config.lang("CRM_Mo_ta")}`}
                                    width={250}/>
                                <Column
                                    dataField={'CreateDate'}
                                    caption={`${Config.lang("CRM_Ngay_tao_khach_hang")}`}
                                    dataType={'date'}
                                    format={'dd-MM-yyyy'}
                                    alignment={'center'}
                                    width={180}/>

                            </GridContainer>}

                        </Col>
                    </FormGroup>


                </Form>
            </div>

        )
    }
}

export default connect(state => ({
        dataGrid: state.w18f1016.dataGrid,
    }),
    (dispatch) => ({
        w18f1016Actions: bindActionCreators(w18f1016Actions, dispatch),
        userActions: bindActionCreators(userActions, dispatch),
    })
)(W18F1016Page);
