/**
 * @copyright 2019 @ DigiNet
 * @author THANH TRAM
 * @create 2019/01/11 10:37
 * @update 2019/01/11 10:37
 * @file src/components/header/header.js
 */

import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as w18Actions from "../../actions/w18-actions";
import {
    Input,
    Label
} from "reactstrap";
import {
    CheckBox,
    DateBox
} from 'devextreme-react';
import notify from 'devextreme/ui/notify';
import Config from '../../config/index';
import PropTypes from 'prop-types';
import EventTracking from "../common/tracking/event";
import ButtonSave from "../common/button/button-save";
import ButtonNotSave from "../common/button/button-notsave";

import moment from "moment";
import 'moment/locale/vi'
import ButtonAdd from '../common/button/button-add';
import { Row, Col, FormGroup } from 'diginet-core-ui/components';
import { useTheme } from "diginet-core-ui/theme";
import _ from 'lodash';
moment.locale('vi');

const { spacing } = useTheme();
const cssForm = { margin: spacing(4) };

class W18F1013Page extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            selectedTab: 1,
            CompanyID:this.props.data.CompanyID,
            ContactID: this.props.data.ContactID,
            FullNameU: this.props.data.FullNameU,
            JobPositionU: this.props.data.JobPositionU,
            Birthday: Config.parseDate(this.props.data.Birthday),
            Gender: this.props.data.Gender,
            ContactStatus: this.props.data.ContactStatus,
            NoteU: this.props.data.NoteU,
            MobileNo: this.props.data.MobileNo,
            OfficeTelNo: this.props.data.OfficeTelNo,
            HomeAddressU: this.props.data.HomeAddressU,
            Email: this.props.data.Email,
            IsAdmin: this.props.data.IsAdmin,
            Email2: this.props.data.Email2,
            Facebook: this.props.data.Facebook,
            Skype: this.props.data.Skype,
            //mode:this.props.data.mode,
            m: new Date().toISOString(),
            isSubmit: false,
            allowAddMore:false,
            requiredField: []
        };
        this.timer = null;
        this.timeDeplay = Config.getSetting("TIME_SUBMIT_DELAY") || 2000;
    };

    // componentWillUnmount() {
    //     if (this.timer) clearTimeout(this.timer);
    // }

    componentDidMount() {
        this.loadCheckRequired();
        // this.setState({loading: true});
        // this.timer = setTimeout(() => {
        //     this.setState({loading: false});
        // }, this.timeDeplay);

        // this.props.w18Actions.editW18F1013((error, data) => {
        //     this.setState({loading: false});

        //     if (error) {
        //         let errorCode = error.code || null;
        //         let message = "";
        //         switch (errorCode) {
        //             case "US004":
        //                 message = Config.lang("CRM_Tai_khoan_dang_nhap_hoac_mat_khau_khong_dung");
        //                 break;
        //             default:
        //                 message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
        //                 break;
        //         }
        //         this.setState({isSubmit: false, message: message});
        //         return false;
        //     }
        //     this.setState({
        //         loading: false,
        //     });

        // });

        // const mode = window.location.search.split('mode=')[1];
        // if (mode !== 'add') {
        //     this.props.w18Actions.addW18F1013((error, data) => {
        //         this.setState({loading: false});

        //         if (error) {
        //             let errorCode = error.code || null;
        //             let message = "";
        //             switch (errorCode) {
        //                 case "US004":
        //                     message = Config.lang("CRM_Tai_khoan_dang_nhap_hoac_mat_khau_khong_dung");
        //                     break;
        //                 default:
        //                     message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
        //                     break;
        //             }
        //             this.setState({isSubmit: false, message: message});
        //             return false;
        //         }
        //         this.setState({
        //             loading: false,
        //         });
        //     });
        // }
    }

    loadCheckRequired = () => {
        const params = {
            CodeType: "Contact"
        };
        this.props.w18Actions.loadCheckRequired(params, (error, data) => {
            if (error) {
                Config.popup.show('INFO', error);
                return false;
            } else if (data) {
                if (_.isArray(data)) this.setState({ requiredField: data });
            }
        });
    };

    getValueState = (name = "") => {
        if (!_.isString(name)) return "";
        const stateChanged = ["IsAdmin", "Birthday"]; // Những trường khi onChange có setState
        let result = stateChanged.includes(name) ? this.state?.[name] : document.getElementById(name)?.value;
        if (name === "FullNameU") result = result.trim();
        return result;
    };

    /** Func filter ra những field có required và chưa có value
    * INPUT:
    *   data(type array)
    *   value(type string): mode filter
    * OUTPUT:
    *   trả ra list filter (type array)
    */
    handleFilterDataRequired = (data = [], value = "") => {
        if (_.isEmpty(data) || !_.isArray(data) || !value) return [];
        return data.filter(item => item?.ValidMode === value && Object.hasOwn(this.state, item?.FieldName) && Config.isEmpty(this.getValueState(item?.FieldName), true));
    };

    /** Func kiểm tra required từ store
    * OUTPUT:
    *   status(type number): 0: là không bắt buộc nhập, 1: Required bắt buộc nhập, 2: required Yes_no bạn có chắc ?
    *   dataName(type array): danh sách name Caption
    */
    requiredStatus = () => {
        const { requiredField } = this.state;
        if (_.isEmpty(requiredField)) return 0;
        let status = 0;
        let requiredData = "";
        const equiredOStatus = this.handleFilterDataRequired(requiredField, "O");
        const equiredWStatus = this.handleFilterDataRequired(requiredField, "W");
        if (!_.isEmpty(equiredOStatus)) {
            requiredData = equiredOStatus.filter(item => item?.ValidMode === "O");
            status = 1;
        } else if (!_.isEmpty(equiredWStatus)) {
            requiredData = equiredWStatus.filter(item => item?.ValidMode === "W");
            status = 2;
        }
        return { status, requiredData };
    };

    handleSave() {
        if (this.state.isSubmit) {
            return;
        }
        const fullNameU = document.getElementById("FullNameU").value.trim();
        const gender = document.getElementById("Gender").value;
        if (fullNameU === "" || fullNameU === 'undefined') {
            Config.popup.show('INFO', `${Config.lang("CRM_Ho_va_ten_khong_duoc_de_trong")}`);
            document.getElementById("FullNameU").focus();
            return false;
        }
        if (gender === "") {
            Config.popup.show('INFO', `${Config.lang("CRM_Gioi_tinh_khong_duoc_de_trong")}`);
            document.getElementById("Gender").focus();
            return false;
        }
        const { status = 0, requiredData = [] } = this.requiredStatus();
        if (status) { // Status của required từ API
            let requiredCaption = [];
            let requiredID = [];
            for (let i = 0; i < requiredData.length; i++) {
                const obj = requiredData[i];
                requiredCaption.push(obj?.Caption);
                requiredID.push(obj?.FieldName);
            }
            if (!_.isEmpty(requiredID)) { // Xử lý focus nào element required
                const idDateList = ['Birthday']; // Những thẻ mà input nằm bên trong
                let idElm = document.getElementById(requiredID[0]);
                if (idElm && idDateList.includes(requiredID[0])) idElm = idElm.querySelectorAll("input[type=text]")?.[0]; // lấy thể input của element
                if (idElm) idElm.focus();
            }
            if (status === 1) {
                Config.popup.show('INFO', `${requiredCaption.join(', ')} ${Config.lang("Khong_duoc_de_trong").toLowerCase()}`);
                return;
            } else if (status === 2) {
                Config.popup.show("YES_NO", Config.lang(`Ban_chua_nhap_truong:_%${requiredCaption.join(', ')}%_Ban_co_muon_luu_khong?`),
                    () => { // Yes Lưu
                        this.onSave();
                    }, () => { // No Return
                        return;
                    });
            } else {
                this.onSave();
            }
        } else {
            this.onSave();
        }
    }

    onSave = () => {
        const { data } = this.props;
        const companyID = this.state.CompanyID;
        const fullNameU = document.getElementById("FullNameU").value.trim();
        const jobPositionU = document.getElementById("JobPositionU").value;
        const gender = document.getElementById("Gender").value;
        const contactStatus = document.getElementById("ContactStatus").value;
        const noteU = document.getElementById("NoteU").value;
        const mobileNo = document.getElementById("MobileNo").value;
        const officeTelNo = document.getElementById("OfficeTelNo").value;
        const homeAddressU = document.getElementById("HomeAddressU").value;
        const email = document.getElementById("Email").value;
        const isAdmin = this.state.IsAdmin ? "1" : "0";
        const email2 = document.getElementById("Email2").value;
        const facebook = document.getElementById("Facebook").value;
        const Skype = document.getElementById("Skype").value;
        let birthday = '';
        if (this.state.Birthday) {
            birthday = Config.parseDate(this.state.Birthday, null, 'YYYY-MM-DD');
        }
        if (data.mode === 'add') {
            const param = {
                "CompanyID": companyID,
                "FullNameU": fullNameU,
                "JobPositionU": jobPositionU,
                "Birthday": birthday,
                "Gender": gender,
                "ContactStatus": contactStatus,
                "NoteU": noteU,
                "MobileNo": mobileNo,
                "OfficeTelNo": officeTelNo,
                "HomeAddressU": homeAddressU,
                "Email": email,
                "IsAdmin": isAdmin,
                "Email2": email2,
                "Facebook": facebook,
                "Skype": Skype,
            };
            this.setState({isSubmit: true},() => {
                this.props.w18Actions.addW18F1013(param, (error, data) => {
                    if (error) {
                        let errorCode = error.code || null;
                        let message = "";
                        switch (errorCode) {
                            case "SYS014":
                                message = Config.lang("CRM_Dinh_dang_email_khong_hop_le");
                                Config.popup.show('INFO', message);
                                break;
                            default:
                                message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                                Config.popup.show('INFO', message);
                                break;
                        }
                        this.setState({isSubmit: false, message: message});
                        return false;
                    }
                    if (data === 'successful') {
                        // Show message success and close popup and reloadGrid
                        notify(Config.lang("CRM_Luu_thanh_cong"), "success", 2000);
                        this.setState({isSubmit: false,allowAddMore:true});
                        this.props.onChangeMode && this.props.onChangeMode("detail");
                        this.onReloadGrid();
                    }

                });
            });

        }
        else {
            const contactID = document.getElementById("ContactID").value;
            const param = {
                "CompanyID": companyID,
                "ContactID": contactID,
                "FullNameU": fullNameU,
                "JobPositionU": jobPositionU,
                "Birthday": birthday,
                "Gender": gender,
                "ContactStatus": contactStatus,
                "NoteU": noteU,
                "MobileNo": mobileNo,
                "OfficeTelNo": officeTelNo,
                "HomeAddressU": homeAddressU,
                "Email": email,
                "IsAdmin": isAdmin,
                "Email2": email2,
                "Facebook": facebook,
                "Skype": Skype,
            };
            this.setState({isSubmit: true},() =>{
                this.props.w18Actions.editW18F1013(param, (error, data) => {
                    this.setState({isSubmit: false});
                    if (error) {
                        let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                        Config.popup.show("INFO", message);
                        return false;
                    }
                    if (data === 'successful') {
                        // Show message success and close popup and reloadGrid
                        notify(Config.lang("CRM_Luu_thanh_cong"), "success", 2000);
                        this.onHide();
                        this.onReloadGrid();
                    }

                });
            });

        }
    };

    onAddMore = () =>{
        document.getElementById("FullNameU").value="";
        document.getElementById("JobPositionU").value="";
        document.getElementById("Gender").value="";
        document.getElementById("ContactStatus").value="";
        document.getElementById("NoteU").value="";
        document.getElementById("MobileNo").value="";
        document.getElementById("OfficeTelNo").value="";
        document.getElementById("HomeAddressU").value="";
        document.getElementById("Email").value="";
        document.getElementById("Email2").value="";
        document.getElementById("Facebook").value="";
        document.getElementById("Skype").value="";
        this.setState({isAdmin:0,Birthday:null,allowAddMore:false})
        this.props.onAddMore &&  this.props.onAddMore()
    }
    handleSelect(key) {

        this.setState({key: key});
    }
    onChangeName(value) {
        if (!value || value.length < 3) {
            //Show loi ten khong hop le
            return;
        }
        this.setState({
            FullNameU: value,
            JobPositionU: value,
            Birthday: value,
            Gender: value,
            ContactStatus: value,
            NoteU: value,
            MobileNo: value,
            OfficeTelNo: value,
            HomeAddress: value,
            Email: value,
            Email2: value,
            Skype: value
        });
    }

    checkExistMobileNo = () => {
        const MobileNo = document.getElementById("MobileNo");
        if(MobileNo && MobileNo.value){
            this.props.w18Actions.checkExistMobile({MobileNo:MobileNo.value},(err, data)=>{
                if(err){
                    Config.popup.show('INFO',err.message);
                }
                else if(data && (data.FullName || data.CompanyNameU)){
                    let mess = Config.lang('CRM_So_dien_thoai_trung_voi_lien_he') + (data.FullName ? data.FullName : '-');
                    mess += Config.lang('CRM_cua_khach_hang')+ (data.CompanyNameU ? data.CompanyNameU : '-') + Config.lang('CRM_Ban_co_muon_nhap_tiep_khong')
                    Config.popup.show('YES_NO',mess,null,()=>{
                        setTimeout(()=>{
                            MobileNo.focus();
                        },500)
                    });
                }
            })
        }
    };

    onIsAdminChanged(e) {
        this.setState({
            IsAdmin: e.value
        });
    }

    onHide() {
        if (this.props.onHide) {
            this.props.onHide();
        }
    }

    onReloadGrid() {
        if (this.props.onReloadGrid) {
            this.props.onReloadGrid();
        }
    }

    renderRequired = (name = "") => {
        let status = "";
        const { requiredField } = this.state;
        if (requiredField.findIndex(item => item?.FieldName === name && item?.ValidMode === "O") > -1) status = <span className={'text-red'}> (*)</span>;
        return status;
    };

    render() {
        const {data} = this.props;
        const {allowAddMore,isSubmit} = this.state;
        return (
            <form style={cssForm}>
                {Config && Config.profile && <EventTracking category={"TRACKING_USER"} action={"W18F1013"} label={Config.profile.UserID} />}

                <FormGroup>
                    <div className="page-container">
                        <Row>
                            <Input id={'ContactID'} className="hide"
                                disabled={data && data.mode === 'detail'}
                                defaultValue={this.state.ContactID} />
                            <Col md={2} sm={2} xl={2} xs={12} lg={2}>
                                <Label>{Config.lang("CRM_Ho_va_ten")}<span className={'text-red'}> (*)</span></Label>
                            </Col>
                            <Col md={4} sm={4} xl={4} xs={12} lg={4}>
                                <Input id={'FullNameU'}
                                    disabled={data && data.mode === 'detail'}
                                    defaultValue={this.state.FullNameU} />
                            </Col>
                            <Col md={2} sm={2} xl={2} xs={12} lg={2}>
                                <Label>{Config.lang("CRM_Chuc_vu")}
                                    {this.renderRequired("JobPositionU")}</Label>
                            </Col>
                            <Col md={4} sm={4} xl={4} xs={12} lg={4}>
                                <Input id={'JobPositionU'}
                                    disabled={data && data.mode === 'detail'}
                                    defaultValue={this.state.JobPositionU} />
                            </Col>

                            <Col md={2} sm={2} xl={2} xs={12} lg={2}>
                                <Label>{Config.lang("CRM_Ngay_sinh")}
                                    {this.renderRequired("Birthday")}</Label>
                            </Col>
                            <Col md={4} sm={4} xl={4} xs={12} lg={4}>
                                <DateBox
                                    width={"100%"}
                                    ref={(ref) => this.birthday = ref}
                                    id={'Birthday'}
                                    disabled={data && data.mode === 'detail'}
                                    openOnFieldClick={true}
                                    useMaskBehavior={true}
                                    showClearButton={true}
                                    value={this.state.Birthday}
                                    type={'date'}
                                    pickerType={"calendar"}
                                    showAnalogClock={false}
                                    displayFormat={'d/MM/y'}
                                    onValueChanged={(e) => {
                                        this.setState({ Birthday: Config.parseDate(e.value, null, 'YYYY-MM-DD', false) })
                                    }}
                                />
                            </Col>
                            <Col md={2} sm={2} xl={2} xs={12} lg={2}>
                                <Label>{Config.lang("CRM_Gioi_tinh")}<span className={'text-red'}> (*)</span></Label>
                            </Col>
                            <Col md={2} sm={2} xl={2} xs={12} lg={2}>
                                <Input type={'select'}
                                    id={'Gender'}
                                    disabled={data && data.mode === 'detail'}
                                    defaultValue={this.state.Gender}>
                                    <option value="">-- Chọn --</option>
                                    <option value="F">{Config.lang("CRM_Nu")}</option>
                                    <option value="M">{Config.lang("CRM_Nam1")}</option>

                                </Input>
                            </Col>
                            <Col md={2} sm={2} xl={2} xs={12} lg={2}>
                                <CheckBox
                                    width={200}
                                    text={Config.lang("CRM_Lien_he_chinh")}
                                    id={"IsAdmin"}
                                    disabled={data && data.mode === 'detail'}
                                    onValueChanged={(e) => this.onIsAdminChanged(e)}
                                    value={this.state.IsAdmin}
                                />
                            </Col>

                            <Col md={2} sm={2} xl={2} xs={12} lg={2}>
                                <Label>{Config.lang("CRM_Email_cong_ty")}
                                    {this.renderRequired("Email")}</Label>
                            </Col>
                            <Col md={4} sm={4} xl={4} xs={12} lg={4}>
                                <Input id={'Email'}
                                    disabled={data && data.mode === 'detail'}
                                    placeholder="xdir@diginet.com.vn"
                                    defaultValue={this.state.Email} />
                            </Col>
                            <Col md={2} sm={2} xl={2} xs={12} lg={2}>
                                <Label>{Config.lang("CRM_Email_ca_nhan")}
                                    {this.renderRequired("Email2")}</Label>
                            </Col>
                            <Col md={4} sm={4} xl={4} xs={12} lg={4}>
                                <Input id={'Email2'}
                                    disabled={data && data.mode === 'detail'}
                                    placeholder="xdir@gmail.com.vn"
                                    defaultValue={this.state.Email2} />
                            </Col>

                            <Col md={2} sm={2} xl={2} xs={12} lg={2}>
                                <Label>{Config.lang("CRM_Dien_thoai_cong_ty")}
                                    {this.renderRequired("OfficeTelNo")}</Label>
                            </Col>
                            <Col md={4} sm={4} xl={4} xs={12} lg={4}>
                                <Input id={'OfficeTelNo'}
                                    placeholder="0798 456 248"
                                    disabled={data && data.mode === 'detail'}
                                    defaultValue={this.state.OfficeTelNo} />
                            </Col>
                            <Col md={2} sm={2} xl={2} xs={12} lg={2}>
                                <Label>{Config.lang("CRM_Dien_thoai_di_dong")}
                                    {this.renderRequired("MobileNo")}</Label>
                            </Col>
                            <Col md={4} sm={4} xl={4} xs={12} lg={4}>
                                <Input id={'MobileNo'}
                                    placeholder="038 7895 012" disabled={data && data.mode === 'detail'}
                                    defaultValue={this.state.MobileNo}
                                    onBlur={this.checkExistMobileNo}
                                />
                            </Col>

                            <Col md={2} sm={2} xl={2} xs={12} lg={2}>
                                <Label>{Config.lang("CRM_Facebook")}
                                    {this.renderRequired("Facebook")}</Label>
                            </Col>
                            <Col md={4} sm={4} xl={4} xs={12} lg={4}>
                                <Input id={'Facebook'}
                                    placeholder="https://www.facebook.com/utybfj"
                                    disabled={data && data.mode === 'detail'}
                                    defaultValue={this.state.Facebook} />
                            </Col>
                            <Col md={2} sm={2} xl={2} xs={12} lg={2}>
                                <Label>{Config.lang("Skype")}
                                    {this.renderRequired("Skype")}</Label>
                            </Col>
                            <Col md={4} sm={4} xl={4} xs={12} lg={4}>
                                <Input id={'Skype'}
                                    placeholder="abty@gmail.com.vn"
                                    type={"email"}
                                    disabled={data && data.mode === 'detail'}
                                    defaultValue={this.state.Skype} />
                            </Col>

                            <Col md={2} sm={2} xl={2} xs={12} lg={2}>
                                <Label>{Config.lang("CRM_Dia_chi_giao_dich")}
                                    {this.renderRequired("HomeAddressU")}</Label>
                            </Col>
                            <Col md={10} sm={10} xl={10} xs={12} lg={10}>
                                <Input id={'HomeAddressU'}
                                    disabled={data && data.mode === 'detail'}
                                    defaultValue={this.state.HomeAddressU} />
                            </Col>

                            <Col md={2} sm={2} xl={2} xs={12} lg={2}>
                                <Label>{Config.lang("CRM_Ghi_chu")}
                                    {this.renderRequired("NoteU")}</Label>
                            </Col>
                            <Col md={10} sm={10} xl={10} xs={12} lg={10}>
                                <Input id={'NoteU'} type={'textarea'} rows={4}
                                    disabled={data && data.mode === 'detail'}
                                    defaultValue={this.state.NoteU} />
                            </Col>

                            <Col md={2} sm={2} xl={2} xs={12} lg={2}>
                                <Label>{Config.lang("CRM_Trang_thai")}
                                    {this.renderRequired("ContactStatus")}</Label>
                            </Col>
                            <Col md={4} sm={4} xl={4} xs={12} lg={4}>
                                <Input type={'select'}
                                    id={'ContactStatus'}
                                    disabled={data && data.mode === 'detail'}
                                    defaultValue={this.state.ContactStatus}>
                                    <option value="A">{Config.lang("CRM_Dang_cong_tac")}</option>
                                    <option value="R">{Config.lang("CRM_Da_nghi_viec")}</option>


                                </Input>

                            </Col>
                            <Col xs={12}>
                                <div className={"jus-end"}>
                                    {
                                        allowAddMore && data && data.mode === 'detail' && <ButtonAdd className={"mgr5"}
                                            name={Config.lang("CRM_Nhap_tiep")} onClick={this.onAddMore}
                                        />
                                    }
                                    <ButtonSave disabled={isSubmit || (data && data.mode === 'detail')} onClick={() => this.handleSave()} className={"mgr5"} name={Config.lang("CRM_Luu")} />
                                    <ButtonNotSave disabled={isSubmit || (data && data.mode === 'detail')} name={Config.lang("CRM_Khong_luu")} onClick={() => {
                                        this.props.onHide()
                                    }} />
                                </div>
                            </Col>
                        </Row>

                    </div>
                </FormGroup>
            </form>
        )
    }
}

W18F1013Page.propTypes = {

    onHide: PropTypes.func,
    onReloadGrid: PropTypes.func
};

export default connect(state => ({
    loadW18F1013: state.w18.loadW18F1013,
    editW18F1013: state.w18.editW18F1013,
}),
    (dispatch) => ({
        w18Actions: bindActionCreators(w18Actions, dispatch)
    })
)(W18F1013Page);
