/**
 * @copyright 2019 @ DigiNet
 * @author kimlong
 * @create 2019/01/11 10:37
 * @update 2019/01/11 10:37
 * @file src/components/header/header.js
 */

import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import Config from '../../config/index';
import notify from 'devextreme/ui/notify';
import * as w18Actions from "../../actions/w18-actions";
import * as userActions from "../../actions/user-actions";
import Detail from "../W18/W18F1001";
import GridContainer from "../common/grid-container/grid-container";
import { Column} from 'devextreme-react/data-grid';
import PopupView from "../popup/popup-view";
import GridActionBar from "../common/action-bar/grid-action-bar";
import EventTracking from "../common/tracking/event";

class W18F1000Page extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            showPopup: false,
            iPermission: 0,
            rows: [],
            callAdd:false,
            row:{
                id:'',
                name: '',
                des:'',
                disabled:false
            }
        };
        this.timer = null;
        this.filter = {
            skip: 0,
            limit: 20,
            search: ''
        };
        this.dataGrid = [];
        this.totals = 0;
    };
    async loadPermission() {
        await this.props.userActions.getPermission(Config.profile.UserID, "D17F1130",(iPer)=>{
            this.setState({iPermission: iPer});
        });
    }

    componentDidMount = async () => {
        await this.loadPermission();
        if(this.state.iPermission <= 0) return;
        this.loadGrid();
    };

    loadGrid(){
        const param = {
            "skip" : this.filter.skip,
            "limit" : this.filter.limit,
            "search": this.filter.search
        };
        this.props.w18Actions.loadW18F1000(param, (error, data) => {
            if (error) {
                let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
            else if (data && data.rows && data.rows.length > 0) {
                this.dataGrid = data.rows.map(o => {
                    const item = {
                        CustomerGroupID: o.CustomerGroupID,
                        CustomerGroupName: o.CustomerGroupName,
                        CustomerTypeName: o.CustomerTypeName,
                        Description: o.Description,
                        Disabled: o.Disabled === 0 ? false : true};
                    return item;
                });
                this.totals = parseInt(data.total);
            } else {
                this.dataGrid = [];
                this.totals = 0;
            }
        });
    }

    onEdit(row) {
        const rowEdit ={
            id: row.CustomerGroupID,
            name:  row.CustomerGroupName,
            des:   row.Description,
            disabled: row.Disabled
        };
        this.setState({row:rowEdit, showPopup: true});
    }

    onAddNew () {
        const rowNew ={
            id:'',
            name: '',
            des:'',
            disabled:false
        };
        this.setState({row:rowNew, showPopup: true});
    }

    onDelete (row) {
        Config.popup.show("YES_NO",Config.lang("CRM_Ban_co_chac_chan_muon_xoa_du_lieu_nay"),()=>{
            const param = {
                "customerGroupID": row.CustomerGroupID,
            };
            this.props.w18Actions.deleteW18F1000(param, (error, data) => {
                if(error){
                    let  message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                    Config.popup.show('INFO',message);
                    return false;
                }else{
                    notify(Config.lang("CRM_Du_lieu_da_xoa_thanh_cong"),"success",2000);
                    this.loadGrid();
                }
            });
        });
    }

    hideAddNewPopup () {
        this.setState({showPopup: false});
    }

    onChangePage(page) {
        this.filter.skip = page * this.filter.limit;
        this.loadGrid();
    }
    onChangePerPage(per) {
        this.filter.skip = 0;
        this.filter.limit = per;
        this.loadGrid();
    }

    onSearch(text) {
        this.filter.skip = 0;
        this.filter.search = text;
        this.loadGrid();
    }

    renderAction (e) {
        if (!e) return null;
        return (
            <GridActionBar
                isPer={this.state.iPermission}
                tooltipEdit={Config.lang("CRM_Sua")}
                onEditAction={()=>{this.onEdit(e.row.data)}}

                tooltipDelete={Config.lang("CRM_Xoa")}
                onDeleteAction={()=>{this.onDelete(e.row.data)}} />
        );
    }

    render(){
        return(
            <div className="page-container">
                {Config && Config.profile && <EventTracking category={"TRACKING_USER"} action={"W18F1000"} label={Config.profile.UserID} /> }
                <GridContainer
                    isPer={this.state.iPermission}
                    title={Config.lang("CRM_Nhom_kinh_doanh")}
                    dataSource={this.dataGrid}
                    keyExpr={'CustomerGroupID'}
                    showBorders={false}
                    showColumnLines={true}
                    selection={{ mode: 'single' }}
                    allowColumnReordering={true}
                    rowAlternationEnabled={true}
                    onAddNew={() => {this.onAddNew()}}
                    totalItems={this.totals}
                    itemPerPage={this.filter.limit}
                    onSearch={(text) => {this.onSearch(text)}}
                    onChangePage={(page) => {this.onChangePage(page)}}
                    onChangePerPage={(per) => {this.onChangePerPage(per)}}
                >
                    <Column dataField={'CustomerGroupID'} caption=""   visible={false}  width={300}   dataType={'string'}  fixed={true}/>
                    <Column dataField={'CustomerGroupName'} caption={Config.lang("CRM_Ten_nhom_khach_hang")} width={400}   dataType={'string'}/>
                    <Column dataField={'Description'} caption={Config.lang("CRM_Mo_ta")} minWidth={300}    width= {"100%"} />
                    <Column dataField={'Disabled'} caption={Config.lang("CRM_Ngung_hoat_dong")}  width={150}  dataType={'boolean'} />
                    <Column
                        cellRender={(data)=>this.renderAction(data)}
                        dataField={'Action'}
                        caption={Config.lang("CRM_Hanh_dong")}
                        alignment={'center'}
                        allowSorting={false}
                        showInColumnChooser={false}
                        width={120}
                        fixed={true}
                    />
                </GridContainer>
                {this.state.showPopup && <PopupView
                    title={Config.lang("CRM_Cap_nhat_nhom_khach_hang")}
                    show={ true }
                    animation={true}
                    onHide={() => this.hideAddNewPopup()}
                >
                    <Detail data={this.state.row} onHide={() => this.hideAddNewPopup()} onReloadGrid={()=>this.loadGrid()}/>
                </PopupView>}
            </div>
        )
    }
}
export default connect(state => ({
        loadW18F1000: state.w18.loadW18F1000,
        deleteW18F1000: state.w18.deleteW18F1000
    }),
    (dispatch) => ({
        w18Actions: bindActionCreators(w18Actions, dispatch),
        userActions: bindActionCreators(userActions, dispatch)
    })
)(W18F1000Page);
