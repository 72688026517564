/**
 * @copyright 2020 @ DigiNet
 * @author CANHTOAN
 * @create 2020/07/28 16:00
 * @update 2020/07/28 16:00
 */

import React, { Fragment } from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import GridContainer from "../common/grid-container/grid-container";
import Config from "../../config";
import {Column} from "devextreme-react/data-grid";
import * as w18f1012Actions from "../../actions/w18F1012-actions";
import * as w18f1060Actions from "../../actions/w18f1060-actions";
import * as userActions from "../../actions/user-actions";
// import {browserHistory} from "react-router";
import PropTypes from "prop-types";
import moment from "moment";
import 'moment/locale/vi'
import GridActionBar from "../common/action-bar/grid-action-bar";
import PopupView from "../popup/popup-view";
import Detail from "./W18F1027";
import notify from "devextreme/ui/notify";
moment.locale('vi');

class W18F1012_Delivery extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            showPopup: false,
        }

        this.filter = {
            skip: 0,
            limit: 20,
            search: ''
        };
    };

    loadGrid = () => {
        const { companyID, ObjectID } = this.props;
        const param = {
            ObjectID: ObjectID || '',
            CompanyID: companyID || ''
        };

        this.props.w18f1012Actions.loadGridDelivery(param, (error, _data) => {
            if (error) {
                let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                this.setState({isSubmit: false, message: message, loading: false});
                return false;
            }
        });
    }

    componentDidMount = async () => {
        if (this.props.iPermission <= 0) return;
        this.loadGrid();
    };


    // onChangePage = (page) => {
    //     this.filter.skip = page * this.filter.limit;
    //     this.loadGrid();
    // };

    // onChangePerPage = (per) => {
    //     this.filter.skip = 0;
    //     this.filter.limit = per;
    //     this.loadGrid();
    // };

    onSearch = (text) => {
        // this.filter.skip = 0;
        // this.filter.search = text;
        this.loadGrid();
    };

    onShowW18F1027 = (mode, data = {}) => {
        const {companyID, ObjectID, ObjectTypeID} = this.props;
        const rowNew = {
            CompanyID     : companyID,
            ObjectID      : ObjectID,
            ObjectTypeID  : ObjectTypeID,
            AddressID     : data.AddressID,
            ObjectAddressU: data.ObjectAddressU,
            ContactPersonU: data.ContactPersonU,
            TelNo         : data.TelNo,
            NoteU         : data.NoteU,
            Disabled      : data.Disabled,
            mode          : mode || 'add'
        };
        this.setState({row: rowNew, showPopup: true});
    }

    hideAddNewPopup = () => {
        this.setState({showPopup: false});
    }

    onDelete = data => {
        let param = {
            AddressID: data.AddressID
        };
        Config.popup.show("YES_NO", Config.lang("CRM_Ban_co_chac_chan_muon_xoa_du_lieu_nay"), () => {
            this.props.w18f1012Actions.deleteDelivery(param, async (error) => {
                if (error) {
                    const message = error.message || Config.lang('CRM_Loi_chua_xac_dinh');
                    Config.popup.show("YES_NO", message)
                    return false;
                } else {
                    const historyStatus= await this.saveHistory(data.AddressID);
                    if (historyStatus !== 200) {
                        Config.popup.show("INFO", Config.lang('CRM_Co_loi_khi_luu'));
                        return false;
                    }
                    notify(Config.lang("CRM_Du_lieu_da_xoa_thanh_cong"), "success", 400);
                    this.loadGrid();
                }
            })
        })
    };

    saveHistory = async (AddressID) => {
        let el={};
        let data=[];
        let status = '';
        el.codeID = AddressID;
        el.formID = 'W18F1012';
        el.linkedTrans = '';
        el.oldValue = AddressID;
        el.newValue ='';
        el.description84 = Config.lang("Danh_sach_tinh_huong",'vi');
        el.description01 =Config.lang("Danh_sach_tinh_huong",'en');
        el.action = 2;
        data.push(el);
        const param = {
            attributes: JSON.stringify(data)
        };
        await this.props.w18f1060Actions.addHistory(param, (error) => {
            status = error ? 400 : 200
        });
        return status
    };

    actionRenderW18F1027 = (e) => {
        if (!e) return null;
        const { data } = e;
        const {iPermission, allowAdding} = this.props;
        return (
            <GridActionBar
                isPer={iPermission}
                classNameIs={allowAdding}
                className={allowAdding >= 4 ? '' : 'isDisabled'}
                tooltipEdit={Config.lang('CRM_Sua')}
                onEditAction={() => {
                    this.onShowW18F1027('edit', data);
                }}
                tooltipDelete={Config.lang('CRM_Xoa')}
                onDeleteAction={() => {
                    this.onDelete(data)
                }}
            />
        )
    };

    renderW18F1027 = (e) => {
        if (!e || !e.data) return null;
        const { data } = e;
        return (
            <div style={{cursor: 'pointer', color: '#337ab7'}} title={ data.ObjectAddressU } onClick={() => { this.onShowW18F1027('view', data) }}>
                { data.ObjectAddressU }
            </div>
        )
    };

    render() {
        const {allowAdding, iPermission, dataGridDelivery} = this.props;
        return (
            <Fragment>
                {this.state.showPopup && <PopupView
                    className={'popup-size-90'}
                    title={Config.lang("CRM_Cap_nhat_thong_tin_giao_hang")}
                    show={true}
                    animation={true}
                    onHide={() => this.hideAddNewPopup()}>
                    <Detail data={this.state.row}
                        onHide={() => this.hideAddNewPopup()}
                        onReloadGrid={this.loadGrid}/>
                </PopupView>}
                <GridContainer
                    allowAdding={allowAdding}
                    isPer={iPermission}
                    // itemPerPage={this.filter.limit}
                    // totalItems={Number(this.totalCaseTab)}
                    dataSource={dataGridDelivery}
                    onAddNew={this.onShowW18F1027}
                    onSearch={this.onSearch}
                    // onChangePage={this.onChangePage}
                    // onChangePerPage={this.onChangePerPage}
                >
                    <Column
                        cellRender={(data) => this.actionRenderW18F1027(data)}
                        caption={Config.lang("CRM_Hanh_dong")}
                        width={100}
                    />
                    <Column
                        cellRender={(data) => this.renderW18F1027(data)}
                        caption={Config.lang("CRM_Dia_chi")}
                        width={"80%"}
                    />
                    <Column
                        dataField={'ContactPersonU'}
                        caption={Config.lang("CRM_Nguoi_lien_he")}
                        width={200}
                    />
                    <Column
                        dataField={'TelNo'}
                        caption={Config.lang("CRM_So_dien_thoai")}
                        width={150}
                    />
                    <Column
                        dataField={'Disabled'}
                        dataType={'boolean'}
                        caption={Config.lang("CRM_Khong_su_dung")}
                        width={150}
                        alignment={'center'}
                    />
                </GridContainer>
            </Fragment>
        )
    }
}

W18F1012_Delivery.propTypes = {
    companyID      : PropTypes.string
};
export default connect((state) => ({
        dataGridDelivery: state.w18f1012.dataGridDelivery
    }), (dispatch) => ({
        w18f1012Actions: bindActionCreators(w18f1012Actions, dispatch),
        w18f1060Actions: bindActionCreators(w18f1060Actions, dispatch),
        userActions: bindActionCreators(userActions, dispatch),
    })
)(W18F1012_Delivery);
