/**
 * @copyright 2019 @ DigiNet
 * @author kimlong
 * @create 2019/01/11 10:37
 * @update 2019/01/11 10:37
 * @file src/components/header/header.js
 */

import React from 'react';
import {FormGroup, Row, Col} from 'react-bootstrap';
import notify from 'devextreme/ui/notify';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import ButtonSave from "../common/button/button-save";
import ButtonNotSave from "../common/button/button-notsave";
import Config from '../../config/index';
import {
    CheckBox,
    TextArea,
    TextBox,
    Validator,
    Button
} from 'devextreme-react';
import {RequiredRule} from 'devextreme-react/validator';
import PropTypes from 'prop-types';
import * as w18Actions from "../../actions/w18-actions";
import EventTracking from "../common/tracking/event";

const cssForm = {margin: '0 15px 0 15px'};

class W18F1001Page extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.data.id,
            name: this.props.data.name,
            des: this.props.data.des,
            disabled: this.props.data.disabled,
            isSubmit: false,

        };

        this.timer = null;
        this.timeDeplay = Config.getSetting("TIME_SUBMIT_DELAY") || 3000;
    };

    componentWillUnmount() {
        if (this.timer) clearTimeout(this.timer);
    }

    onEventSave() {
        this.onFormSubmit();
    }

    onFormSubmit(e) {
        this.onSave();
    }

    onSave() {
        const id = this.state.id;
        const disabled = this.state.disabled === false ? 0 : 1;

        if (id !== '') {
            const param = {
                "ID": id,
                "name": this.state.name,
                "description": this.state.des,
                "disabled": disabled,
            };
            this.props.w18Actions.editW18F1001(param, (error, data) => {
                if (error) {
                    let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                    this.setState({isSubmit: false});
                    Config.popup.show("INFO", message);
                    return false;
                }

                // Check data return success

                if (data && data.code === 200) {
                    // Show message success and close popup
                    this.setState({isSubmit: false});
                    notify(Config.lang("CRM_Luu_thanh_cong"), "success", 2000);
                    this.onReloadGrid();
                    // Auto close when nothing to do
                    clearTimeout(this.timer);
                    this.timer = setTimeout(() => {
                        this.setState({isSubmit: false});
                        this.onHide();
                    }, this.timeDeplay);
                }
            });
        } else {
            const param = {
                "name": this.state.name,
                "description": this.state.des,
                "disabled": disabled,
            };
            this.props.w18Actions.addW18F1001(param, (error, data) => {
                if (error) {
                    let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                    this.setState({isSubmit: false});
                    Config.popup.show("INFO", message);
                    return false;
                }

                // Check data return success

                if (data && data.code === 200) {
                    // Show message success and close popup
                    this.setState({isSubmit: false});
                    notify(Config.lang("CRM_Luu_thanh_cong"), "success", 2000);
                    this.onReloadGrid();
                    // Auto close when nothing to do
                    clearTimeout(this.timer);
                    this.timer = setTimeout(() => {
                        this.setState({isSubmit: false});
                        this.onHide();
                    }, this.timeDeplay);
                }
            });
        }
    }

    onHide() {
        if (this.props.onHide) {
            this.props.onHide();
        }
    }

    onReloadGrid() {
        if (this.props.onReloadGrid) {
            this.props.onReloadGrid();
        }
    }

    render() {
        return (
            <form style={cssForm} onSubmit={(e) => this.onFormSubmit(e)}>
                {Config && Config.profile &&
                <EventTracking category={"TRACKING_USER"} action={"W18F1001"} label={Config.profile.UserID}/>}

                <FormGroup>
                    <Row>
                        <Col sm={12}>
                            <label className={'control-label-above'}>{Config.lang("CRM_Ten_nhom_khach_hang")} <span
                                style={{color: 'red'}}><b>*</b></span></label>
                            <TextBox name={"CustomGroupName"}
                                     defaultValue={this.state.name}
                                     onChange={(e) => this.onTextValueChanged(e)}
                                     className="input-devextreme"
                            >
                                <Validator>
                                    <RequiredRule message={Config.lang("CRM_Gia_tri_bat_buoc_nhap")}/>
                                </Validator>
                            </TextBox>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <label className={'control-label-above'}>{Config.lang("CRM_Mo_ta")}</label>
                            <TextArea name={"Description"}
                                      height={90}
                                      value={this.state.des}
                                      text={this.state.des}
                                      maxLength={this.state.des.maxLength}
                                      valueChangeEvent={this.state.des}
                                // onValueChanged={()=>this.onTextAreaValueChanged()}
                                      onChange={(e) => this.onTextValueChanged(e)}
                                      className="input-devextreme"
                            ></TextArea>
                        </Col>
                    </Row>

                </FormGroup>

                <FormGroup className={"jus-between"}>
                    <div>
                        <CheckBox
                            width={200}
                            text={Config.lang("CRM_Ngung_hoat_dong")}
                            controlID={"disabled"}
                            onValueChanged={(e) => this.onDisabledChanged(e)}
                            valueChangeEvent={this.state.disabled}
                            value={!this.state.disabled || this.state.disabled === 0 ? false : true}
                        />
                    </div>
                    <div>
                        <ButtonSave onClick={() => this.onEventSave()} className={"mgr5"} name={Config.lang("CRM_Luu")}/>
                        <ButtonNotSave name={Config.lang("CRM_Khong_luu")} onClick={() => {this.props.onHide()}}/>
                        <Button id="btnSubmit" useSubmitBehavior={true} className="hide"/>
                    </div>
                </FormGroup>


            </form>

        )
    }

    onTextValueChanged(e) {
        switch (e.event.target.name) {
            case "CustomGroupName":
                this.setState({name: e.event.target.value});
                break;
            case "Description":
                this.setState({des: e.event.target.value});
                break;
            default:
                break;
        }
    }

    onDisabledChanged(e) {
        this.setState({
            disabled: e.value
        });
    }
}

W18F1001Page.propTypes = {

    onHide: PropTypes.func,
    onReloadGrid: PropTypes.func
};


export default connect(state => ({
        addW18F1001: state.w18.addW18F1001,
        editW18F1001: state.w18.editW18F1001,
    }),
    (dispatch) => ({
        w18Actions: bindActionCreators(w18Actions, dispatch)
    })
)(W18F1001Page);
