/**
 * @copyright 2019 @ DigiNet
 * @author VINHLE
 * @create 2019/01/11 10:37
 * @update 2019/01/11 10:37
 * @file src/components/header/header.js
 */

import React from 'react';

import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as w19F1000Actions from "../../actions/w19/w19F1000/w19F1000-actions";
import * as userActions from "../../actions/user-actions";
import GridContainer from "../common/grid-container/grid-container";
import {browserHistory} from "react-router";
import Config from "../../config";
import {Column} from "devextreme-react/data-grid";
import GridActionBar from "../common/action-bar/grid-action-bar";
import notify from "devextreme/ui/notify";
import {CheckBox} from "devextreme-react";
import PropTypes from "prop-types"
import * as w19F1001Actions from "../../actions/w19/w19F1001/w19F1001-actions";

import moment from "moment";
import 'moment/locale/vi'

moment.locale('vi');

class W18F1012_Price extends React.PureComponent {
    constructor(props) {
        super(props);

        this.filter = {
            skip: 0,
            limit: 20,
            search: ''
        };
        this.mode = '';

    };

    loadGrid() {
        const uName = Config.profile.UserID;
        const {companyID} = this.props;
        const divisionID = Config.profile.DivisionID || '';
        const params = {
            UserID: uName,
            DivisionID: divisionID,
            Language: Config.language || '84',
            StrSearch: this.filter.search,
            skip: this.filter.skip,
            limit: this.filter.limit,
            CompanyID: companyID,
        };
        this.props.w19F1000Actions.loadGrid(params, () => {

        });
    }

    componentDidMount = async () => {
        if(this.props.iPermission <= 0) return;
        this.getNumValue();
        this.loadGrid();
    };

    onDelete(data) {
        const rowData = data.row.data;
        const param = {
            QuotationID: rowData.QuotationID
        };
        Config.popup.show("YES_NO", `${Config.lang("CRM_Ban_co_chac_chan_muon_xoa_du_lieu_nay")}`, () => {
            this.props.w19F1000Actions.deleteGrid(param, (error) => {
                if (error) {
                    let errorCode = error.code || null;
                    let message = "";
                    switch (errorCode) {
                        case "US004":
                            message = "Tài khoản đăng nhập hoặc mật khẩu không đúng.";
                            break;
                        default:
                            message = error.message || "Lỗi chưa xác định";
                            break;
                    }
                    Config.popup.show('INFO', message);
                    return false;
                }
                this.loadGrid();
                notify(Config.lang("CRM_Du_lieu_da_xoa_thanh_cong"), "success", 400);
            })
        })

    }

    onAddNew() {
        const {objectID} = this.props;
        if (!objectID) {
            Config.popup.show('INFO', Config.lang('CRM_Ban_phai_chuyen_khach_hang_thanh_doi_tuong'));
            return false;
        }
        browserHistory.push({
            pathname: Config.getRootPath() + 'W19F1001',
            state: {mode: 'add'}
        })
        // const url = `W19F1001?mode=add&CompanyID=${companyID}`;
        // browserHistory.push(Config.env.url + '/' + url);
    };


    onRedirect(e, mode = 'edit') {
        const data = e.row.data;
        if (!e || !data.QuotationID) return null;
        browserHistory.push({
            pathname: Config.getRootPath() + 'W19F1001',
            state: {
                mode: mode,
                id: data.QuotationID,
                formCall: 'W19F1000'
            },
        });
    }

    onChangePage(page) {
        this.filter.skip = page * this.filter.limit;
        this.loadGrid();
    }

    onChangePerPage(per) {
        this.filter.skip = 0;
        this.filter.limit = per;
        this.loadGrid();
    }

    onSearch(text) {
        this.filter.skip = 0;
        this.filter.search = text;
        this.loadGrid();
    }

    getNumValue() {
        this.props.w19F1001Actions.getNumValue((error) => {
            if (error) {
                let errorCode = error.code || null;
                let message = "";
                switch (errorCode) {
                    case "US004":
                        message = "Tài khoản đăng nhập hoặc mật khẩu không đúng.";
                        break;
                    default:
                        message = error.message || "Lỗi chưa xác định";
                        break;
                }
                Config.popup.show('INFO', message);
                return false;
            }

        })
    };

    renderAction(data) {
        let per = 0;
        const {iPermission} = this.props;
        if (!data) return null;
        if (data.data.AStatusID === 0) {
            per = iPermission;
        }
        return (
            <GridActionBar
                isPer={per}
                tooltipEdit={Config.lang('CRM_Sua')}
                onEditAction={() => {
                    this.onRedirect(data)
                }}

                tooltipDelete={Config.lang("CRM_Xoa")}
                onDeleteAction={() => {
                    this.onDelete(data)
                }}
            />
        );
    }

    render() {
        const {dataGrid, numValue} = this.props;
        const {iPermission} = this.props;
        return (
            <GridContainer
                id={'W18F1012_Price'}
                dataSource={dataGrid && dataGrid}
                totalItems={dataGrid ? dataGrid.length > 0 ? dataGrid[0].TotalRecord : 0 : 0}
                itemPerPage={this.filter.limit}
                onAddNew={() => this.onAddNew()}
                isPer={iPermission}

                onSearch={(text) => {
                    this.onSearch(text)
                }}
                onChangePage={(page) => {
                    this.onChangePage(page)
                }}
                onChangePerPage={(per) => {
                    this.onChangePerPage(per)
                }}>
                <Column
                    cellRender={(data) => this.renderAction(data)}
                    caption={'Hành động'}
                    alignment={'center'}
                    allowSorting={false}
                    showInColumnChooser={false}
                    width={120}
                    fixed={true}
                />
                <Column
                    dataField={'VoucherNum'}
                    caption={Config.lang("CRM_So_bao_gia")}
                    cellRender={(e) => {
                        const {data} = e;
                        /* eslint-disable */
                        return (
                            <a onClick={() => this.onRedirect(e, 'view')}>{data.VoucherNum && data.VoucherNum}</a>)
                        /* eslint-enable */

                    }}
                    allowSorting={true}
                    width={180}
                />
                <Column
                    dataField={'QuotationDate'}
                    alignment={'center'}
                    width={150}
                    cellRender={(e) => {
                        let date = e.value ? moment(e.value).format("DD/MM/YYYY") : '';
                        return date
                    }}

                    caption={Config.lang("CRM_Ngay_bao_gia")}
                />

                <Column
                    dataField={'Amount'}
                    caption={Config.lang("CRM_Tien_hang")}
                    format={'#,##0.##'}
                    visible={numValue ? numValue.NumValue === 1 : false}
                    width={120}/>
                <Column
                    dataField={'Reduce'}
                    width={120}
                    format={'#,##0.##'}
                    visible={numValue ? numValue.NumValue === 1 : false}
                    caption={Config.lang("CRM_Tien_chiet_khau")}/>
                <Column
                    dataField={'VAT'}
                    width={120}
                    format={'#,##0.##'}
                    visible={numValue ? numValue.NumValue === 1 : false}
                    caption={Config.lang("CRM_Tien_thue")}/>
                <Column
                    dataField={'TotalAmount'}
                    format={'#,##0.##'}
                    visible={numValue ? numValue.NumValue === 1 : false}
                    caption={Config.lang("CRM_Tong_tien")}
                    width={120}/>
                <Column
                    dataField={'AStatus'}
                    caption={Config.lang("CRM_Da_duyet")}
                    alignment={'center'}
                    width={120}/>
                <Column
                    dataField={'ApproveUser'}
                    caption={Config.lang("CRM_Nguoi_duyet")}
                />
                <Column
                    dataField={'ValidTo'}
                    alignment={'center'}
                    cellRender={(e) => {
                        let date = e.value ? moment(e.value).format("DD/MM/YYYY") : '';
                        return date
                    }}
                    caption={Config.lang("CRM_Ngay_hieu_luc")}
                    width={150}
                />
                <Column
                    dataField={'SalesPersonName'}
                    caption={Config.lang("CRM_Nguoi_bao_gia")}

                />
                <Column
                    dataField={'Status'}
                    caption={Config.lang("CRM_Da_dong")}
                    alignment={'center'}
                    width={80}
                    cellRender={(e) => {
                        return (<CheckBox
                            value={e.value === 1}
                            className={'control-label text-bold'}
                            id={"Disabled"}
                        />)
                    }}
                />
                <Column
                    dataField={'Description'}
                    caption={Config.lang("CRM_Ghi_chu")}
                />
            </GridContainer>
        )
    }

}

W18F1012_Price.propTypes = {
    objectID: PropTypes.string,
    companyID: PropTypes.string,
    iPermission: PropTypes.number,

};
export default connect(state => ({
        dataGrid: state.w19f1000.dataGrid,
        numValue: state.w19f1001.numValue
    }),
    (dispatch) => ({
        w19F1000Actions: bindActionCreators(w19F1000Actions, dispatch),
        w19F1001Actions: bindActionCreators(w19F1001Actions, dispatch),
        userActions: bindActionCreators(userActions, dispatch),
    })
)(W18F1012_Price);
