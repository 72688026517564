/**
 * @copyright 2019 @ DigiNet
 * @author VINHLE
 * @create 2019/01/11 10:37
 * @update 2019/01/11 10:37
 * @file src/components/header/header.js
 */

import React from 'react';

import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import GridContainer from "../common/grid-container/grid-container";
import Config from "../../config";
import {Column} from "devextreme-react/data-grid";
import * as w19f1020Actions from "../../actions/w19/w19f1020/w19f1020-actions";
import * as userActions from "../../actions/user-actions";
import {browserHistory} from "react-router";
import PropTypes from "prop-types";

import moment from "moment";
import 'moment/locale/vi'

moment.locale('vi');

class W18F1012_Order extends React.PureComponent {
    constructor(props) {
        super(props);

        this.filter = {
            skip: 0,
            limit: 20,
            search: ''
        };
    };

    loadGrid() {
        const uName = Config.profile.UserID || '';
        const uLanguage = Config.language || '84';
        const divisionID = Config.CompanyID() || '';
        const {companyID} = this.props || '';
        const params = {
            "UserID": uName,
            "Language": uLanguage,
            "DivisionID": divisionID,
            "StrSearch": this.filter.search,
            "skip": this.filter.skip,
            "limit": this.filter.limit,
            "CompanyID": companyID,
        };

        this.props.w19f1020Actions.dataGridW19F1020(params, (error) => {
            if (error) {
                const errorCode = error.code || null;
                let message = "";
                if (errorCode) {
                    message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                    Config.popup.show('INFO', message);
                }
                this.setState({isSubmit: false, message: message, loading: false});
                return false;
            }
        });

    }

    componentDidMount = async () => {
        if (this.props.iPermission <= 0) return;
        this.loadGrid();
    };


    onChangePage(page) {
        this.filter.skip = page * this.filter.limit;
        this.loadGrid();
    }

    onChangePerPage(per) {
        this.filter.skip = 0;
        this.filter.limit = per;
        this.loadGrid();
    }

    onSearch(text) {
        this.filter.skip = 0;
        this.filter.search = text;
        this.loadGrid();
    }

    renderVoucherNum(data) {
        if (!data) return null;
        const name = data && data.value ? data.value : '';
        /* eslint-disable */
        return (
            <div><a onClick={() => this.onDetailVoucher(data.data)}>{name}</a></div>
        );
        /* eslint-disable */
    }

    onDetailVoucher = e => {
        const TranTypeID = e && e.TranTypeID ? e.TranTypeID : '';
        if (e && e.QuotationID) {
            browserHistory.push({
                pathname: Config.getRootPath() + 'W19F1021',
                state: {
                    QuotationID: e.QuotationID,
                    TranTypeID: TranTypeID,
                    ScreenID: 'W19F1020'
                }
            });
        }
    };

    render() {
        const {dataGrid} = this.props;
        const {iPermission} = this.props;
        return (
            <GridContainer
                id={'W18F1012_Order'}
                dataSource={dataGrid && dataGrid}
                totalItems={dataGrid ? dataGrid.length > 0 ? dataGrid[0].TotalRecord : 0 : 0}
                itemPerPage={this.filter.limit}
                isPer={iPermission}
                columnResizingMode={'widget'}
                onSearch={(text) => {
                    this.onSearch(text)
                }}
                onChangePage={(page) => {
                    this.onChangePage(page)
                }}
                onChangePerPage={(per) => {
                    this.onChangePerPage(per)
                }}>
                <Column
                    cellRender={(data) => data.rowIndex + 1}
                    caption={Config.lang("CRM_STT")}
                    alignment={'center'}
                    allowSorting={false}
                    showInColumnChooser={false}
                    width={80}
                />
                <Column
                    dataField={'VoucherNum'}
                    caption={Config.lang("CRM_So_don_hang")}
                    cellRender={(data) => this.renderVoucherNum(data)}
                    width={200}
                />
                <Column
                    dataField={'QuotationDate'}
                    alignment={'center'}
                    width={150}
                    cellRender={(e) => {
                        return e.value ? moment(e.value).format("DD/MM/YYYY") : '';
                    }}
                    caption={Config.lang("CRM_Ngay_lap")}
                />

                <Column
                    dataField={'SalesPersonName'}
                    caption={Config.lang("CRM_Nhan_vien_kinh_doanh")}
                    alignment={'left'}
                    width={400}/>
                <Column
                    dataField={'StatusName'}
                    width={300}
                    alignment={'left'}
                    caption={Config.lang("CRM_Trang_thai")}/>
                <Column
                    dataField={'OTotalAmount'}
                    width={200}
                    format={'#,##0.##'}
                    caption={Config.lang("CRM_Tong_tien")}/>
                <Column
                    dataField={'CTotalAmount'}
                    format={'#,##0.##'}
                    caption={Config.lang("CRM_Tong_tien_QD")}
                    width={200}/>
                <Column
                    dataField={'CurrencyID'}
                    caption={Config.lang("CRM_Loai_tien")}
                    alignment={'center'}
                    width={200}/>
                <Column
                    dataField={'ExchangeRate'}
                    caption={Config.lang("CRM_Ty_gia")}
                    width={120}/>

            </GridContainer>
        )
    }

}

W18F1012_Order.propTypes = {
    companyID: PropTypes.string,
    iPermission: PropTypes.number,
};
export default connect(state => ({
        dataGrid: state.w19f1020.dataGrid,

    }),
    (dispatch) => ({
        w19f1020Actions: bindActionCreators(w19f1020Actions, dispatch),
        userActions: bindActionCreators(userActions, dispatch),
    })
)(W18F1012_Order);
