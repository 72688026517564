/**
 * @copyright 2019 @ DigiNet
 * @author THANHTRAM
 * @create 2019/27/02 17:00
 * @update 2019/27/02 17:00
 * @file src/components/header/header.js
 */

import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as w18f1021Actions from "../../actions/w18f1021-actions";
import * as userActions from "../../actions/user-actions";
import Config from '../../config';
import GridContainer from "../common/grid-container/grid-container";
import {Column} from 'devextreme-react/data-grid';
import {
    Row,
    FormGroup,
    Col,
} from 'react-bootstrap';
import Combo from "../common/dropdown/combo";
import EventTracking from "../common/tracking/event";
import ComboTree from "../common/dropdown/dropdown-treeview.js";
import {browserHistory} from "react-router";
import GridActionBar from "../common/action-bar/grid-action-bar";
import Detail from "../W18/W18F1013";
import PopupView from "../popup/popup-view";
import ButtonExport from '../common/button/button-export';

class W18F1021Page extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            showPopup: false,
            iPermission: 0,
            salesPersonID:'',
            typeTimeID:''
        };
        this.timer = null;
        this.filter = {
            skip: 0,
            limit: 15,
            search: ''
        };
        // this.typeTimeID = '';
        this.groupSalesID = '';
        // this.salesPersonID = '';

        this.dataGroupSales = [];
        this.dataSalesPerson = [];
        this.dataTypeTime = [];

        this.resetComboSalesPerson = false;
        this.resetComboGroupSales = false;
        this.exportRef = null;

    };

    componentDidMount = async () => {
        await this.loadPermission();
        if(this.state.iPermission <= 0) return;
        this.comboGroupSales();
        this.comboSalesPerson();
        this.comboTypeTime();
        this.loadGrid();
    };

    async loadPermission() {
        await this.props.userActions.getPermission(Config.profile.UserID, "D17F1001",(iPer)=>{
            this.setState({iPermission: iPer});
        });
    }

    hideAddNewPopup() {
        this.setState({showPopup: false});
    }

    comboGroupSales() {
        const uName = Config.profile.UserID || '';
        const groupParam = {
            "UserID": uName,
        };
        this.props.w18f1021Actions.comboGroupSalesW18F1021(groupParam, (error, data) => {
            // console.log("comboGroupSalesW18F1021 comboGroupSalesW18F1021", data);
            if (error) {
                let errorCode = error.code || null;
                let message = "";
                switch (errorCode) {
                    case "US004":
                        message = Config.lang("CRM_Tai_khoan_dang_nhap_hoac_mat_khau_khong_dung");
                        break;
                    default:
                        message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                        Config.popup.show('INFO', message);
                        break;
                }
                this.setState({isSubmit: false, message: message, loading: false});
                return false;
            } else if (data && data.length > 0) {
                this.dataGroupSales = data.map(o => {
                    const groupSales = {
                        GroupSalesID: o.GroupSalesID
                        , GroupSalesNameU: o.GroupSalesNameU
                        , GroupSalesParentID: o.GroupSalesParentID
                    };
                    return groupSales;
                });
            } else {
                return false;
            }
        });
    }

    comboSalesPerson() {
        const groupSale = this.groupSalesID || '';
        const salesParam = {
            "groupSalesID": groupSale
        };
        this.props.w18f1021Actions.comboSalesPersonW18F1021(salesParam, (error, data) => {
            if (error) {
                let errorCode = error.code || null;
                let message = "";
                switch (errorCode) {
                    case "US004":
                        message = Config.lang("CRM_Tai_khoan_dang_nhap_hoac_mat_khau_khong_dung");
                        break;
                    default:
                        message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                        Config.popup.show('INFO', message);
                        break;
                }
                this.setState({isSubmit: false, message: message, loading: false});
                return false;
            } else if (data && data.data && data.data.length > 0) {
                this.dataSalesPerson = data.data.map(o => {
                    const salesPerson = {
                        SalesPersonID: o.SalesPersonID
                        , SalesPersonNameU: o.SalesPersonNameU
                        , JobPosition: o.JobPosition
                    };
                    return salesPerson;
                });
            }
        });
    }

    comboTypeTime() {
        const uLanguage = Config.language || '84';
        const timeParam = {
            "uLanguage": uLanguage,
        };
        this.props.w18f1021Actions.comboTypeTimeW18F1021(timeParam, (error, data) => {
            // console.log('data=======================', data);
            if (error) {
                let errorCode = error.code || null;
                let message = "";
                switch (errorCode) {
                    case "US004":
                        message = Config.lang("CRM_Tai_khoan_dang_nhap_hoac_mat_khau_khong_dung");
                        break;
                    default:
                        message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                        Config.popup.show('INFO', message);
                        break;
                }
                this.setState({isSubmit: false, message: message, loading: false});
                return false;
            } else if (data && data.data && data.data.length > 0) {
                this.dataTypeTime = data.data.map(o => {
                    const item = {
                        ID: o.ID
                        , Name: o.Name
                        , IsDefault: o.IsDefault
                    };
                    return item
                });
                const defaultValue = data.data.filter((row) => {
                    return row.IsDefault === 1;
                });
                if (defaultValue.length > 0) {
                    this.setState({
                        typeTimeID:defaultValue[0].ID
                    });
                    // this.typeTimeID = defaultValue[0].ID;
                    this.loadGrid();
                }
            }
        });
    }

    loadGrid() {
        const uName = Config.profile.UserID || '';
        const uLanguage = Config.language || '84';
        const formID = "W18F1021";
        const mnParam = {
            "UserID": uName,
            "Language": uLanguage,
            "FormID": formID,
            "StrSearch": this.filter.search,
            "GroupSalesID": this.groupSalesID,
            "SalesPersonID": this.state.salesPersonID,
            "TypeTime": this.state.typeTimeID,
            "skip": this.filter.skip,
            "limit": this.filter.limit,
        };
        this.props.w18f1021Actions.loadW18F1021(mnParam, (error, data) => {
            if (error) {
                let errorCode = error.code || null;
                let message = "";
                switch (errorCode) {
                    case "US004":
                        message = Config.lang("CRM_Tai_khoan_dang_nhap_hoac_mat_khau_khong_dung");
                        break;
                    default:
                        message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                        Config.popup.show('INFO', message);
                        break;
                }
                this.setState({isSubmit: false, message: message, loading: false});
                return false;
            }
        });
    }

    onChangeComboGroupSales(data) {
        this.groupSalesID = data;
        this.comboSalesPerson();
        this.loadGrid();

        this.resetComboGroupSales = false;
        this.resetComboSalesPerson = true;
        this.setState({
            salesPersonID:''
        })
    }

    onChangeComboSalesPerson(data) {
        // this.salesPersonID = data.SalesPersonID;
        this.setState({
            salesPersonID:data.SalesPersonID
        })
        this.resetComboSalesPerson = false;
        this.loadGrid();
    }

    onChangeComboTypeTime(data) {
        this.setState({
            typeTimeID:data.ID
        })
        // this.typeTimeID = data.ID;
        this.loadGrid();
    }

    renderActionContact(e) {
        if (!e) return null;
        return (
            <GridActionBar
                tooltipEdit={Config.lang("CRM_Sua")} onEditAction={() => {
                this.onEdit(e.row.data)
            }}
                tooltipDelete={Config.lang("CRM_Xoa")} onDeleteAction={() => {
                this.onDelete(e.row.data)
            }}/>
        );
    }

    onChangePage(page) {
        this.filter.skip = page * this.filter.limit;
        this.loadGrid();
    }

    onChangePerPage(per) {
        this.filter.skip = 0;
        this.filter.limit = per;
        this.loadGrid();
    }

    onSearch(text) {
        this.filter.search = text;
        this.loadGrid();
    }

    renderCompanyNameU(data) {
        if (!data) return null;
        const name = data && data.value ? data.value : '';

        /* eslint-disable */
        return (
            <div><a onClick={() => this.onDetail(data.data)}>{name}</a></div>
        );
        /* eslint-enable */
    }

    onDetail(e) {
        const IsCustomer = e && e.IsCustomer ? e.IsCustomer : 0;
        const IsPartner = e && e.IsPartner ? e.IsPartner : 0;
        if (IsCustomer === 1) {
            browserHistory.push({
                pathname: Config.getRootPath() + 'W18F1012',
                state: {type: 'Customers', Permission: e.Permission, CompanyID: e.CompanyID}
            });
        } else if (IsPartner === 1) {
            browserHistory.push({
                pathname: Config.getRootPath() + 'W18F1012',
                state: {type: 'Partner', Permission: e.Permission, CompanyID: e.CompanyID}
            });
        } else {
            browserHistory.push({
                pathname: Config.getRootPath() + 'W18F1012',
                state: {type: 'Customers', Permission: e.Permission, CompanyID: e.CompanyID}
            });
        }
    }

    renderSalesPersonNameU(data) {
        if (!data) return null;
        const name = data && data.value ? data.value : '';

        /* eslint-disable */
        return (
            <div><a onClick={() => this.onDetailSalesPersonNameU(data.data)}>{name}</a></div>
        );
        /* eslint-enable */
    }

    onDetailSalesPersonNameU(e) {
        const userID = e && e.UserID ? e.UserID : '';
        const salePersonID = e && e.SalesPersonID ? e.SalesPersonID : '';
        const disabled = e && e.Disabled ? e.Disabled : 0;
        browserHistory.push({
            pathname: Config.getRootPath() + 'W18F1031',
            state: {mode:"edit",UserID:userID,SalesPersonID:salePersonID,Disabled:disabled}
        });
    }

    renderContactID(data) {
        if (!data) return null;
        const name = data && data.value ? data.value : '';

        /* eslint-disable */
        return (
            <div><a onClick={() => this.onDetailContactID(data.data)}>{name}</a></div>
        );
        /* eslint-enable */
    }

    onDetailContactID(e) {
        const rowDetail = {
            ContactID: e.ContactID,
            CompanyID: e.CompanyID,
            FullNameU: e.FullNameU,
            JobPositionU: e.JobPositionU,
            Birthday: e.Birthday,
            ContactStatus: e.ContactStatus,
            Gender: e.Gender,
            NoteU: e.NoteU,
            MobileNo: e.MobileNo,
            OfficeTelNo: e.OfficeTelNo,
            HomeAddressU: e.HomeAddressU,
            Email: e.Email,
            IsAdmin: e.IsAdmin,
            Email2: e.Email2,
            Facebook: e.Facebook,
            Skype: e.Skype,
            mode: 'detail'
        };
        this.setState({row: rowDetail, showPopup: true});
    }

    render() {
        const {loadW18F1021} = this.props;
        const {salesPersonID,typeTimeID} = this.state;
        const {skip, limit, search} = this.filter;
        let totalItems = 0;
        let dataSource = [];
        const btnCRM = [
            {
                buttonName: Config.lang("Xuat_du_lieu"),
                buttonType: 'button',
                icon: 'fas fa-file-excel text-green',
                align: 'right',
                disabled: false,
                onClick: () => {
                    if (this.exportRef.getWrappedInstance().onClick) {
                        this.exportRef.getWrappedInstance().onClick("0")
                    }
                }
            }
        ];
        // console.log("=================loadW18F1021",loadW18F1021);
        if (loadW18F1021 && loadW18F1021.data && loadW18F1021.data.length > 0) {
            loadW18F1021.data.map(o => {
                const row = {
                    CompanyID: o.CompanyID,
                    SalesPersonID: o.SalesPersonID,
                    ContactID: o.ContactID,
                    UserID: o.UserID,
                    CompanyNameU: o.CompanyNameU,
                    FullNameU: o.FullNameU,
                    Birthday: o.Birthday,
                    HomeAddressU: o.HomeAddressU,
                    MobileNo: o.MobileNo,
                    Email: o.Email,
                    SalesPersonNameU: o.SalesPersonNameU,
                    JobPositionU: o.JobPositionU,
                    ContactStatus: o.ContactStatus,
                    Gender: o.Gender,
                    NoteU: o.NoteU,
                    OfficeTelNo: o.OfficeTelNo,
                    IsAdmin: o.IsAdmin,
                    Email2: o.Email2,
                    Facebook: o.Facebook,
                    IsCustomer: o.IsCustomer,
                    IsPartner: o.IsPartner,
                    Skype: o.Skype,
                };
                totalItems = o.TotalRecord;
                dataSource.push(row);

                return o;
            });
        }
        const paramExport = [
            { "id": "UserID", "type": "VarChar", "value": Config.profile.UserID },
            { "id": "Language", "type": "VarChar", "value": Config.language || '84' },
            { "id": "DivisionID", "type": "VarChar", "value": Config.profile.DivisionID },
            { "id": "StrSearch", "type": "NVarChar", "value": search },
            { "id": "skip", "type": "Int", "value": skip },
            { "id": "limit", "type": "Int", "value": limit },
            { "id": "GroupSalesID", "type": "VarChar", "value": this.groupSalesID },
            { "id": "SalesPersonID", "type": "VarChar", "value": salesPersonID },
            { "id": "DataType", "type": "VarChar", "value": 'REPORT' },
            { "id": "ReportTypeID", "type": "VarChar", "value": 'W18F1021' },
            { "id": "TypeTime", "type": "VarChar", "value": typeTimeID },
        ];
        return (
            <div className="page-container">
                <ButtonExport
                    className="hide"
                    ref={ref => this.exportRef = ref}
                    reportTypeID={"W18F1021"}
                    id={'slave1'}
                    onlyShowPopup
                    param={paramExport}
                />
                {Config && Config.profile &&
                <EventTracking category={"TRACKING_USER"} action={"W18F1021"} label={Config.profile.UserID}/>}

                <div className="grid-title">{Config.lang("CRM_Danh_sach_sinh_nhat_cua_nguoi_lien_he")}</div>
                <FormGroup>
                    <Row className={"list_padding mgbt5"}>
                        <Col sm={3}>
                            <ComboTree
                                dataSource={this.dataGroupSales}
                                reset={this.resetComboGroupSales}
                                placeholder={Config.lang("CRM_Nhom_kinh_doanh")}
                                parentIdExpr={'GroupSalesParentID'}
                                value={this.groupSalesID}
                                valueExpr={'GroupSalesID'}
                                displayExpr={'GroupSalesNameU'}
                                isMultiple={false}
                                onActionWhenSelectChange={(value) => {
                                    this.onChangeComboGroupSales(value)
                                }}
                            />
                        </Col>
                        <Col sm={3}>
                            <Combo
                                reset={this.resetComboSalesPerson}
                                showHeader={false}
                                dataSource={this.dataSalesPerson}
                                placeholder={Config.lang("CRM_Nhan_vien_kinh_doanh")}
                                valueExpr={'SalesPersonID'}
                                displayExpr={'SalesPersonNameU'}
                                onActionWhenSelectChange={(data) => {
                                    this.onChangeComboSalesPerson(data)
                                }}
                            >
                                <Column dataField={'SalesPersonID'} caption="Mã" visible={false}
                                        dataType={'string'}/>
                                <Column dataField={'UserID'} caption="Mã" visible={false}
                                        dataType={'string'}/>
                                <Column dataField={'SalesPersonNameU'} caption="Tên" dataType={'string'}/>
                            </Combo>
                        </Col>
                        <Col sm={2}>
                            <Combo
                                columns={[
                                    {dataField: 'ID', caption: 'Mã', width: '150px', visible: false},
                                    {dataField: 'Name', caption: 'Tên'}]}
                                showHeader={false}
                                dataSource={this.dataTypeTime}
                                placeholder={Config.lang("CRM_Thong_so_thoi_gian")}
                                value={this.state.typeTimeID}
                                valueExpr="ID"
                                displayExpr="Name"
                                onActionWhenSelectChange={(data) => {
                                    this.onChangeComboTypeTime(data)
                                }}
                            >
                                <Column dataField={'ID'} caption="Mã" visible={false}
                                        dataType={'string'}/>
                                <Column dataField={'Name'} caption="Tên" dataType={'string'}/>
                            </Combo>
                        </Col>
                    </Row>
                </FormGroup>
                {loadW18F1021 && <GridContainer
                    dataSource={dataSource}
                    totalItems={totalItems}
                    itemPerPage={this.filter.limit}
                    listPerPage={[15, 20, 30, 50]}
                    buttonCRM={btnCRM}
                    onSearch={(text) => {
                        this.onSearch(text)
                    }}
                    onChangePage={(page) => {
                        this.onChangePage(page)
                    }}
                    onChangePerPage={(per) => {
                        this.onChangePerPage(per)
                    }}
                >
                    <Column cellRender={(e) => this.renderActionContact(e)} visible={false} dataField={'Action'}
                            fixed={true}/>
                    <Column dataField={'CompanyID'} visible={false} allowSorting={true} allowReordering={false}
                            fixed={true}/>
                    <Column dataField={'SalesPersonID'} visible={false} allowSorting={true} allowReordering={false}
                            fixed={true}/>
                    <Column dataField={'ContactID'} visible={false} allowSorting={true} allowReordering={false}
                            fixed={true}/>
                    <Column dataField={'UserID'} visible={false} allowSorting={true} allowReordering={false}
                            fixed={true}/>
                    <Column dataField={'CompanyNameU'} caption={Config.lang("CRM_Ten_khach_hang")} allowSorting={true}
                            cellRender={(data) => this.renderCompanyNameU(data)} allowReordering={false} width={390}/>
                    <Column dataField={'FullNameU'} cellRender={(data) => this.renderContactID(data)}
                            caption={Config.lang("CRM_Nguoi_lien_he")} allowSorting={true} allowReordering={false}/>
                    <Column alignment='center' format="dd/MM/yyyy" dataType={'date'} dataField={'Birthday'}
                            caption={Config.lang("CRM_Sinh_nhat")} allowSorting={true} allowReordering={false}
                            width={160}/>
                    <Column dataField={'HomeAddressU'} caption={Config.lang("CRM_Dia_chi")} width={360}/>
                    <Column dataField={'MobileNo'} width={240} caption={Config.lang("CRM_Dien_thoai")}/>
                    <Column dataField={'Email'} caption={'Email'} width={260}/>
                    <Column cellRender={(data) => this.renderSalesPersonNameU(data)} dataField={'SalesPersonNameU'}
                            caption={Config.lang("CRM_Nhan_vien")} width={180}/>
                </GridContainer>}
                {this.state.showPopup && <PopupView
                    title={Config.lang("CRM_Chi_tiet_nguoi_lien_he")}
                    className={'popup-size-90'}
                    show={true}
                    animation={true}
                    onHide={() => this.hideAddNewPopup()}>
                    <Detail data={this.state.row} onHide={() => this.hideAddNewPopup()}/>
                </PopupView>}
            </div>
        )
    }
}

export default connect(state => ({
        loadW18F1021: state.w18f1021.loadW18F1021,
        comboGroupSalesW18F1021: state.w18f1021.comboGroupSalesW18F1021,
        comboSalesPersonW18F1021: state.w18f1021.comboSalesPersonW18F1021,
        comboTypeTimeW18F1021: state.w18f1021.comboTypeTimeW18F1021,
    }),
    (dispatch) => ({
        w18f1021Actions: bindActionCreators(w18f1021Actions, dispatch),
        userActions: bindActionCreators(userActions, dispatch),
    })
)(W18F1021Page);