/**
 * @copyright 2019 @ DigiNet
 * @author rocachien
 * @create 2019/01/11 16:37
 * @update 2019/01/11 16:37
 * @file src/components/dashboard/dashboard.js
 */

import React, {Component} from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";

import * as dashboardActions from "../../actions/dash-boad-action";
import {Column} from "devextreme-react/data-grid";
import Config from "../../config";
import {Loading} from "../common/loading/loading";
import GridContainer from "../common/grid-container/grid-container";
import {Pie, Bar} from 'react-chartjs-2';
import {Col, FormGroup, Radio, Row} from "react-bootstrap";
import DropDownSaleGroup from "../common/dropdown/dropdown-sale-group";
import Combo from "../common/dropdown/combo";
import {Button, DateBox} from "devextreme-react";
import * as w18F1020Actions from "../../actions/w18F1020-actions";

import moment from "moment";
import 'moment/locale/vi'

moment.locale('vi');

class DashboardPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            scaleScreen: false,
            isSetting: false,
            settingScaling: false,
            data: [],
            endItem: 0,
            loadingColumnChart: true,
            loadingGridChart: true,
        };
    }

    componentDidMount = () => {
        const params = {
            CompanyID: Config.getSetting('DB_CRM_NAME'),
            ListType: 'W19F4000_TypeTime',
        };
        this.props.dashboardActions.getListParts(params, (error, data) => {
            // console.log('data========', data, params, Config.language);
            if (error) {
                let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            } else if (data) {
                this.setState({
                    endItem: data.length - 1,
                    data: data
                });
                data.forEach((item, idx) => {
                    if (item.MReportID === 'W19F4000') {
                        this.loadChartW19F4000();
                    }
                });

                window.addEventListener("resize", () => {
                    if ((window.innerWidth < 1200 && window.innerWidth > 766) && data.length % 2 === 1 && this.refs['box-item-' + (data.length - 1)]) {
                        this.refs['box-item-' + (data.length - 1)].classList.add('columns-special');
                    } else if (this.refs['box-item-' + (data.length - 1)]) {
                        this.refs['box-item-' + (data.length - 1)].classList.remove('columns-special');
                    }
                });
            }
        });
    };

    loadChartW19F4000 = () => {
        this.props.dashboardActions.getConpoTypeTime({}, (error, data) => {
            if (error) {
                let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
            const dataTime = data.find(o => o.IsDefault === 1);
            if (dataTime) {
                const params = {
                    vlCbbTime: dataTime.ID
                };
                this.onLoadChartColumn(params);
            }

        });
    };

    loadChartW19F4002 = () => {
        const {cboGroupSaleW18F1020} = this.props;
        this.props.dashboardActions.getConpoTypeTime2({}, (error, data) => {
            if (error) {
                let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            } else if (data && data.length > 0) {

                const params = {
                    TranMonth: data[0].TranMonth,
                    TranYear: data[0].TranYear,
                    GroupSalesID: cboGroupSaleW18F1020 && cboGroupSaleW18F1020[0] ? cboGroupSaleW18F1020[0].GroupSalesID : ''
                };
                this.onLoadGrid(params);
            }
        });
    };

    onClickSetting = (idx) => {
        this.setState({
            isSetting: idx
        });
    };

    onClickInfo = () => {
        this.setState({
            isSetting: false,
            settingScaling: false
        });
    };

    onClickShowMaximize = (idx) => {

        const {isSetting} = this.state;

        let dataTemp = [...this.state.data];
        dataTemp.push(dataTemp[idx]);

        this.setState({
            scaleScreen: dataTemp.length - 1,
            settingScaling: isSetting === idx ? dataTemp.length - 1 : false,
            data: dataTemp,
        }, () => {
            setTimeout(() => {
                const newEl = document.querySelector('#box-item-' + (dataTemp.length - 1));
                newEl.style.opacity = 0;
                newEl.className += ' maximize-view';
                // if(isSetting === idx) settingEl.className += ' on-setting';
            }, 50);
        })
    };

    onClickShowMinimize = () => {
        let dataTemp = [...this.state.data];
        let newEl = document.querySelector('.maximize-view');
        if (!newEl) return;

        newEl.classList.remove("maximize-view");
        newEl.classList.add("minimize-view");

        setTimeout(() => {
            dataTemp.splice(-1);
            this.setState({
                scaleScreen: false,
                data: dataTemp,
                settingScaling: false,
            })
        }, 500);
    };

    onLoadChartColumn = (params, cb) => {
        const data = {
            TypeTime: params && params.vlCbbTime ? params.vlCbbTime : '',
            DataMode: params && params.radioValue ? params.radioValue : 0,
            GroupSalesID: params && params.vlCbbGS ? params.vlCbbGS : '',
            ValuesType: params && params.vlCbbChiTiet ? params.vlCbbChiTiet : ''
        };
        this.props.dashboardActions.getReportColumn(data, (error, data) => {
            cb && cb(error, data);
            if (error) {
                let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
            this.setState({
                isSetting: false,
                loadingColumnChart: false
            });
        });
    };

    onLoadGrid = (params, cb) => {
        const data = {
            Language: Config.language,
            TimeMode: params && params.TimeMode ? params.TimeMode : 0,
            GroupSalesID: params && params.GroupSalesID ? params.GroupSalesID : '',
            ValuesType: params && params.vlCbbChiTiet ? params.vlCbbChiTiet : 'EMP',
        };
        if (params.TimeMode) {
            data.DateFrom = params && params.DateFrom ? params.DateFrom : '';
            data.DateTo = params && params.DateTo ? params.DateTo : '';
        } else {
            data.TranMonth = params && params.TranMonth ? params.TranMonth : '';
            data.TranYear = params && params.TranYear ? params.TranYear : '';
        }
        this.props.dashboardActions.getReportMatrix(data, (error, data) => {
            if (error) {
                let message = error.message || Config.lang("CRM_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
            cb && cb(error, data);
            this.setState({
                isSetting: false,
                loadingGridChart: false
            });
        });
    };

    parseLanguage = (vn, en) => {
        return Config.language === '84' ? vn : en;
    };

    onAccept = (params, cb) => {
        this.onLoadChartColumn(params, cb);
    };

    onAcceptGrid = (params, cb) => {
        this.onLoadGrid(params, cb);
    };

    loadSuccessTree = () => {
        this.loadChartW19F4002();
    };

    render() {
        const {listDashboard, listReportColumn, typeTime, typeTime2, cboGroupSaleW18F1020, gridReportColumn} = this.props;
        const {data, scaleScreen, isSetting, settingScaling, endItem, loadingGridChart, loadingColumnChart} = this.state;
        let dataParse = [];
        let columns = [];
        let column1 = [];
        if (gridReportColumn) {
            gridReportColumn.forEach((key, idx) => {
                if (!column1.find(i => i.Itemcode === key.Itemcode)) {
                    column1.push({Itemcode: key.Itemcode, ItemName: key.ItemName});
                }
                if (!columns.find(i => i.CustomerDatatype === key.CustomerDatatype)) {
                    columns.push({
                        CustomerDatatypeName: key.CustomerDatatypeName,
                        CustomerDatatype: key.CustomerDatatype
                    });
                }
            })
        }
        column1.forEach((title, index) => {
            let itemTemp = {colums1: title.ItemName};
            columns.forEach((column, idx) => {
                gridReportColumn.forEach((item, itemIdx) => {
                    if (item.Itemcode === title.Itemcode && item.CustomerDatatype === column.CustomerDatatype) {
                        itemTemp[column.CustomerDatatype] = item.CustomerQuantity;
                    }
                })
            });
            dataParse.push(itemTemp);
        });

        return (
            <div className='card-setting-container dashboard-view col-lg-12'
                 ref={'dashboard-view'}>
                {/*{this.state.loading && <Loading/>}*/}
                {data && data.map((key, idx) => {
                    const classSetting = (isSetting === idx || settingScaling === idx ? ' on-setting' : '');
                    const classNameLastChild = idx === endItem && data && listDashboard && listDashboard.length % 2 === 1 && window.innerWidth < 1200 && window.innerWidth > 766;
                    return (
                        <div key={'box-item' + idx}
                             ref={'box-item-' + idx}
                             id={'box-item-' + idx}
                             style={{opacity: idx === (data.length - 1) && scaleScreen !== false ? 0 : 1}}
                             className={"box-item col-lg-4 col-md-6 col-sm-6 col-xs-12" + (classNameLastChild ? ' columns-special' : '')}>
                            <div className={'card-setting box-body' + classSetting}>
                                <div className={'side'}>
                                    <Title title={this.parseLanguage(key.MReportName, key.MReportName01)}
                                           isMax={scaleScreen === idx}
                                           isSetting={classSetting}
                                           onClickSetting={() => this.onClickSetting(idx)}
                                           onClickInfo={() => this.onClickInfo(idx)}
                                           onClickShowMaximize={() => this.onClickShowMaximize(idx)}
                                           onClickShowMinimize={() => this.onClickShowMinimize(idx)}
                                    />
                                    {key.MReportID === 'W19F4002' && !classSetting &&
                                    <Grid loading={loadingGridChart} data={dataParse} dataColumn={columns}/>
                                    }
                                    {key.MReportID === 'W19F4001' &&
                                    <ChartCircle/>
                                    }
                                    {key.MReportID === 'W19F4000' &&
                                    <ChartColumn loading={loadingColumnChart} isMax={scaleScreen === idx}
                                                 data={listReportColumn || []}/>
                                    }
                                    {key.ReportType === 'EMBED' && key.EmbedLink &&
                                    <iframe style={{
                                        maxWidth: '100%',
                                        maxHeight: 'calc(100% - 30px)',
                                        width: '100%',
                                        height: '100%'
                                    }}
                                            title={'MyFrame'}
                                            src={key.EmbedLink}
                                            frameBorder={0}
                                            allowFullScreen={true}
                                    />
                                    }
                                </div>
                                <div className={'box-item side back'}>
                                    <Title title={this.parseLanguage(key.MReportName, key.MReportName01)}
                                           isMax={scaleScreen === idx}
                                           isSetting={classSetting}
                                           onClickSetting={()=>this.onClickSetting(idx)}
                                           onClickInfo={()=>this.onClickInfo(idx)}
                                           onClickShowMaximize={()=>this.onClickShowMaximize(idx)}
                                           onClickShowMinimize={()=>this.onClickShowMinimize(idx)}
                                    />
                                    {(key.MReportID !== 'W19F4000' && key.MReportID !== 'W19F4002') &&
                                    <div className="display_row align-center valign-middle">Setting screen</div>
                                    }
                                    {key.MReportID === 'W19F4000' &&
                                    <SettingW19F4000
                                        typeTime={typeTime || []}
                                        onLoad={this.onLoadChartColumn}
                                        onAccept={this.onAccept}
                                        cboGroupSaleW18F1020={cboGroupSaleW18F1020}
                                    />
                                    }
                                    {key.MReportID === 'W19F4002' &&
                                    <SettingW19F4002
                                        typeTime={typeTime2 || []}
                                        onLoad={this.onLoadChartColumn}
                                        onAccept={this.onAcceptGrid}
                                        loadSuccessTree={this.loadSuccessTree}
                                        cboGroupSaleW18F1020={cboGroupSaleW18F1020}
                                    />
                                    }
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    }

}

export class SettingW19F4000 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            radioValue: 0,
            vlCbbGS: null,
            vlCbbChiTiet: null,
            vlCbbTime: null,

        };
        // this.vlCbbTime = null;
        // this.vlCbbChiTiet = null;
        this.isSubmit = false;
    }

    static getDerivedStateFromProps(nextProps, state) {
        if (!state.vlCbbGS && nextProps.cboGroupSaleW18F1020 && nextProps.cboGroupSaleW18F1020.length) {
            return {
                vlCbbGS: nextProps.cboGroupSaleW18F1020[0].GroupSalesID
            }
        }
        if (!state.vlCbbTime && nextProps.typeTime && nextProps.typeTime.length) {
            const typeTimeF = nextProps.typeTime.filter((value) => value.IsDefault);
            if (typeTimeF && typeTimeF.length) return {vlCbbTime: typeTimeF[0].ID};
        }
        return null
    }

    onChangeComboTT = (data) => {
        this.setState({
            vlCbbTime: data ? data.ID : null
        });

        // this.vlCbbTime = data ? data.TranYear : null;
    };

    onChangeRadio = (value) => {
        const {cboGroupSaleW18F1020} = this.props;
        this.setState({
            radioValue: value
        });
        if (value === 0) {
            // this.vlCbbChiTiet = null;
            this.setState({
                vlCbbChiTiet: null
            })
        }
        if (value === 1 && !this.vlCbbGS) {
            this.setState({
                vlCbbGS: cboGroupSaleW18F1020 && cboGroupSaleW18F1020[0] ? cboGroupSaleW18F1020[0].GroupSalesID : ''
            });
            // this.vlCbbGS = cboGroupSaleW18F1020 && cboGroupSaleW18F1020[0] ? cboGroupSaleW18F1020[0].GroupSalesID : ''
        }
        if (value === 1 && !this.vlCbbChiTiet) {
            // this.vlCbbChiTiet = 'EMP';
            this.setState({
                vlCbbChiTiet: 'EMP'
            })
        }
    };

    onChangeComboGS = (data) => {
        // this.vlCbbGS = data;
        this.setState({
            vlCbbGS: data
        })
    };

    onChangeCbbChitiet = (data) => {
        // this.vlCbbChiTiet = data ? data.cbbCode : null;
        this.setState({
            vlCbbChiTiet: data ? data.cbbCode : null
        })

    };

    onAccept = () => {
        if (this.isSubmit) return;

        this.isSubmit = true;
        let params = {
            vlCbbTime: this.state.vlCbbTime,
            radioValue: this.state.radioValue,
            vlCbbGS: this.state.radioValue ? this.state.vlCbbGS : null,
            vlCbbChiTiet: this.state.vlCbbChiTiet
        };

        if (this.props.onAccept) this.props.onAccept(params, (error, data) => {
            this.isSubmit = false;
        });
    };

    parseLanguage = (vn, en) => {
        return Config.language === '84' ? vn : en;
    };

    render() {
        const {typeTime} = this.props;
        const {radioValue} = this.state;
        const dataCbb = [
            {
                cbbCode: 'EMP',
                cbbName: Config.lang('CRM_Theo_nhan_vien')
            },
            {
                cbbCode: 'ORG',
                cbbName: Config.lang('CRM_Bo_phan')
            }

        ];

        // typeTime.forEach((key, idx) => {
        //     if (key.IsDefault === 1) {
        //         this.vlCbbTime = key.ID;
        //     }
        // });

        return (
            <div className="setting-dashboard" style={{margin: "0px 10px"}}>
                <FormGroup>
                    <Row>
                        <Col sm={4} md={4} lg={4} className={'display_row align-between'}>
                            <label className={'control-label-above'}>{Config.lang("CRM_Ky_bao_cao")}</label>
                        </Col>
                        <Col sm={8} md={8} lg={8}>
                            <Combo
                                showHeader={false}
                                dataSource={typeTime}
                                value={this.state.vlCbbTime}
                                placeholder={''}
                                valueExpr="ID"
                                displayExpr={this.parseLanguage('Name84', 'Name01')}
                                onActionWhenSelectChange={this.onChangeComboTT}
                            >
                                <Column dataField={this.parseLanguage('Name84', 'Name01')} caption="" width={'100%'}
                                        dataType={'string'}/>
                            </Combo>
                        </Col>
                    </Row>
                    <div className="group-radio">
                        <Row>
                            <Col sm={12} md={12} lg={12}>
                                <Radio id={"Detail"}
                                       checked={!radioValue}
                                       style={{paddingLeft: -20}}
                                       onChange={(e) => this.onChangeRadio(0)}>
                                    <b>{Config.lang("CRM_Cua_toi")}</b>
                                </Radio>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={4} md={4} lg={4}>
                                <Radio id={"Detail"}
                                       checked={radioValue}
                                       style={{paddingLeft: -20, whiteSpace: 'pre'}}
                                       onChange={(e) => this.onChangeRadio(1)}>
                                    <b>{Config.lang("CRM_Cua_bo_phan")}</b>
                                </Radio>
                            </Col>

                            <Col sm={8} md={8} lg={8}>
                                <DropDownSaleGroup disabled={!radioValue}
                                                   value={this.state.vlCbbGS}
                                                   placeholder={""}
                                                   onChange={(e) => {
                                                       this.onChangeComboGS(e)
                                                   }}/>
                            </Col>
                        </Row>
                        <Row
                            style={{height: radioValue ? 60 : 10, transition: 'all 0.5s', opacity: radioValue ? 1 : 0}}>
                            <Col sm={4} md={4} lg={4}>
                                <label className={'control-label-above'}>{Config.lang("CRM_Chi_tiet_theo")}</label>
                            </Col>

                            <Col sm={8} md={8} lg={8}>
                                <Combo
                                    showHeader={false}
                                    dataSource={dataCbb}
                                    placeholder={''}
                                    value={this.state.vlCbbChiTiet}
                                    valueExpr="cbbCode"
                                    displayExpr="cbbName"
                                    onActionWhenSelectChange={this.onChangeCbbChitiet}
                                >
                                    <Column dataField={'cbbCode'} caption="" width={'150px'} visible={false}
                                            dataType={'string'}/>
                                    <Column dataField={'cbbName'} caption="" width={'100%'} dataType={'string'}/>
                                </Combo>
                            </Col>
                        </Row>
                    </div>
                    <Row>
                        <Col sm={12} md={12} lg={12} className='display_row valign-bottom'>
                            <Button id="btnSubmit"
                                    onClick={this.onAccept}>
                                <i className='fa fa-check'/>
                                {Config.lang('CRM_Dong_y')}
                            </Button>
                        </Col>
                    </Row>
                </FormGroup>
            </div>
        )
    }

}

export class SettingW19F4002 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            radioValue: 0
        };
        this.vlCbbTime = null;
        this.vlCbbChiTiet = null;
        this.isSubmit = false;
    }

    onChangeComboKBC = (data) => {
        this.vlTranMonth = data.TranMonth;
        this.vlTranYear = data.TranYear;
    };

    onChangeRadio = (value) => {
        this.setState({
            radioValue: value
        });
        if (value === 1) {
            if (!this.vlDateFrom) this.vlDateFrom = moment().startOf('month').format();
            if (!this.vlDateTo) this.vlDateTo = moment().endOf('month').format();
        }
    };

    onChangeComboGS = (data) => {
        this.vlCbbGS = data;
    };

    onChangeCbbChitiet = (data) => {
        this.vlCbbChiTiet = data ? data.cbbCode : null;
    };

    onAccept = () => {
        if (this.isSubmit) return;

        this.isSubmit = true;
        let params = {
            vlCbbTime: this.vlCbbTime,
            TimeMode: this.state.radioValue,
            GroupSalesID: this.vlCbbGS,
            vlCbbChiTiet: this.vlCbbChiTiet,
            DateFrom: this.vlDateFrom,
            DateTo: this.vlDateTo,
            TranMonth: this.vlTranMonth,
            TranYear: this.vlTranYear,
        };

        if (this.props.onAccept) {
            this.props.onAccept(params, (error, data) => {
                this.isSubmit = false;
            });
        }
    };

    loadSuccess = (data) => {
        if (this.props.loadSuccessTree) this.props.loadSuccessTree(data);
    };


    render() {
        const {typeTime, cboGroupSaleW18F1020} = this.props;
        const {radioValue} = this.state;
        const dataCbb = [
            {
                cbbCode: 'EMP',
                cbbName: Config.lang('CRM_Theo_nhan_vien')
            },
            {
                cbbCode: 'ORG',
                cbbName: Config.lang('CRM_Bo_phan')
            }

        ];
        let dataTypeTime = [];
        if (typeTime && typeTime.length > 0) {
            typeTime.forEach((time, idx) => {
                dataTypeTime.push({
                    TranMonth: time.TranMonth,
                    TranYear: time.TranYear,
                    value: time.TranMonth + '/' + time.TranYear
                })
            });
            if (!this.vlTranMonth) this.vlTranMonth = typeTime[0].TranMonth;
            if (!this.vlTranYear) this.vlTranYear = typeTime[0].TranYear;
        }


        this.vlCbbChiTiet = 'EMP';
        // this.vlCbbGS = cboGroupSaleW18F1020 && cboGroupSaleW18F1020[0] ? cboGroupSaleW18F1020[0].GroupSalesID : '';

        return (
            <div className="setting-dashboard" style={{margin: "0px 10px"}}>
                <FormGroup>
                    <Row>
                        <Col sm={4} md={4} lg={4}>
                            <Radio id={"Detail"}
                                   checked={!radioValue}
                                   style={{paddingLeft: -20}}
                                   onChange={(e) => this.onChangeRadio(0)}>
                                <b>{Config.lang("CRM_Ky_bao_cao")}</b>
                            </Radio>
                        </Col>
                        <Col sm={8} md={8} lg={8}>
                            <Combo
                                showHeader={false}
                                disabled={!!radioValue}
                                dataSource={dataTypeTime}
                                value={dataTypeTime && dataTypeTime[0] ? dataTypeTime[0].value : ''}
                                valueExpr="value"
                                displayExpr="value"
                                placeholder={''}
                                onActionWhenSelectChange={this.onChangeComboKBC}
                            >
                                <Column dataField={'value'}
                                        caption="" width={'100%'}
                                        dataType={'string'}/>
                            </Combo>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={4} md={4} lg={4}>
                            <Radio id={"Detail"}
                                   checked={radioValue}
                                   style={{paddingLeft: -20, whiteSpace: 'pre'}}
                                   onChange={(e) => this.onChangeRadio(1)}>
                                <b>{Config.lang("CRM_Thoi_gian")}</b>
                            </Radio>
                        </Col>

                        <Col sm={4} md={4} lg={4}>
                            <DateBox
                                width={"100%"}
                                disabled={!radioValue}
                                id={'CreateDateFrom'}
                                openOnFieldClick={true}
                                useMaskBehavior={true}
                                defaultValue={moment().startOf('month')}
                                type={'date'}
                                pickerType={"calendar"}
                                showAnalogClock={false}
                                displayFormat={'dd/MM/y'}
                                onValueChanged={(e) => {
                                    this.vlDateFrom = moment(e.value).format();
                                }}
                            />
                        </Col>
                        <Col sm={4} md={4} lg={4}>
                            <DateBox
                                width={"100%"}
                                disabled={!radioValue}
                                id={'CreateDateTo'}
                                openOnFieldClick={true}
                                useMaskBehavior={true}
                                // value={this.state.createDateFrom}
                                defaultValue={moment().endOf('month')}
                                type={'date'}
                                pickerType={"calendar"}
                                showAnalogClock={false}
                                displayFormat={'dd/MM/y'}
                                onValueChanged={(e) => {
                                    this.vlDateTo = moment(e.value).format();
                                }}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={4} md={4} lg={4}>
                            <b>{Config.lang("CRM_Cua_bo_phan")}</b>
                        </Col>

                        <Col sm={8} md={8} lg={8}>
                            <DropDownSaleGroup
                                value={cboGroupSaleW18F1020 && cboGroupSaleW18F1020[0] ? cboGroupSaleW18F1020[0].GroupSalesID : ''}
                                placeholder={""}
                                loadSuccess={this.loadSuccess}
                                onChange={(e) => {
                                    this.onChangeComboGS(e)
                                }}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={4} md={4} lg={4}>
                            <label className={'control-label-above'}>{Config.lang("CRM_Chi_tiet_theo")}</label>
                        </Col>

                        <Col sm={8} md={8} lg={8}>
                            <Combo
                                showHeader={false}
                                dataSource={dataCbb}
                                placeholder={''}
                                value={this.vlCbbChiTiet}
                                valueExpr="cbbCode"
                                displayExpr="cbbName"
                                onActionWhenSelectChange={this.onChangeCbbChitiet}
                            >
                                <Column dataField={'cbbCode'} caption="" width={'150px'} visible={false}
                                        dataType={'string'}/>
                                <Column dataField={'cbbName'} caption="" width={'100%'} dataType={'string'}/>
                            </Combo>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} md={12} lg={12} className='display_row valign-bottom'>
                            <Button id="btnSubmit"
                                    onClick={this.onAccept}>
                                <i className='fa fa-check'/>
                                {Config.lang('CRM_Dong_y')}
                            </Button>
                        </Col>
                    </Row>
                </FormGroup>
            </div>
        )
    }

}

export class ChartCircle extends Component {

    render() {
        const data = {
            labels: [
                'Red',
                'Green',
                'Yellow'
            ],
            datasets: [{
                data: [300, 50, 100],
                backgroundColor: [
                    '#FF6384',
                    '#36A2EB',
                    '#FFCE56'
                ],
                hoverBackgroundColor: [
                    '#FF6384',
                    '#36A2EB',
                    '#FFCE56'
                ]
            }]
        };
        return (
            <div className={'chart-view'}>
                <Pie data={data}/>
            </div>
        )
    }
}

export class ChartColumn extends Component {

    datasetKeyProvider = () => {
        return Math.random()
    };

    render() {
        const {data, isMax, loading} = this.props;
        let labels = [];
        let datasets = [

            {
                label: '',
                type: 'line',
                borderColor: '#EC932F',
                backgroundColor: '#EC932F',
                pointBorderColor: '#EC932F',
                pointBackgroundColor: '#EC932F',
                pointHoverBackgroundColor: '#EC932F',
                pointHoverBorderColor: '#EC932F',
                data: [],
                yAxisID: 'y-axis-2'
            },
            {
                label: '',
                type: 'bar',
                backgroundColor: '#71B37C',
                borderColor: '#71B37C',
                hoverBackgroundColor: '#71B37C',
                hoverBorderColor: '#71B37C',
                data: [],
                yAxisID: 'y-axis-1'
            }
        ];
        const min = 0;
        let max = data && data[0] ? data[0].Values : 0;
        data.forEach((item) => {
            if (max < item.Values || max < item.FocastValues) {
                max = item.Values;
                if (max < item.FocastValues) {
                    max = item.FocastValues;
                }
            }
            datasets[1].data.push(item.Values);
            datasets[0].data.push(item.FocastValues);
            labels.push(item.ItemName);
        });

        const fontSize = isMax ? 20 : 10;
        const options = {
            legend: {
                display: false,
            },
            responsive: true,
            labels: labels,
            tooltips: {
                mode: 'label',
                bodyFontSize: fontSize,
                titleFontSize: fontSize,
                callbacks: {
                    label: (tooltipItem, data) => {
                        return tooltipItem.value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
                    }
                }
            },
            elements: {
                line: {
                    fill: false
                }
            },
            scales: {
                gridLines: {
                    color: 'transparent'
                },
                angleLines: {
                    color: 'transparent'
                },
                xAxes: [
                    {
                        display: true,
                        gridLines: {
                            display: false
                        },
                        labels: labels,
                        ticks: {
                            fontSize: fontSize,
                        }
                    }
                ],
                yAxes: [
                    {
                        type: 'linear',
                        display: true,
                        position: 'left',
                        id: 'y-axis-1',
                        gridLines: {
                            display: false
                        },
                        labels: {
                            show: true
                        },
                        ticks: {
                            min: min,
                            max: max,
                            fontSize: fontSize,
                            callback: function (value, index, values) {
                                return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
                            }
                        }
                    },
                    {
                        type: 'linear',
                        display: false,
                        position: 'right',
                        id: 'y-axis-2',
                        gridLines: {
                            display: false
                        },
                        labels: {
                            show: true
                        },
                        ticks: {
                            min: min,
                            max: max,
                            fontSize: fontSize,
                            callback: function (value, index, values) {
                                return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
                            }
                        }
                    }
                ]
            }
        };

        // let dataTemp ={
        //     "labels": [
        //         "Nhóm hàng 1",
        //         "Nhóm hàng 2",
        //         "Nhóm hàng 3",
        //         "Nhóm hàng 4",
        //         "Nhóm hàng 5",
        //         "Nhóm hàng 6",
        //         "Nhóm hàng 7"
        //     ],
        //     "datasets": [
        //         {
        //             "label": "",
        //             "type": "line",
        //             "borderColor": "#EC932F",
        //             "backgroundColor": "#EC932F",
        //             "pointBorderColor": "#EC932F",
        //             "pointBackgroundColor": "#EC932F",
        //             "pointHoverBackgroundColor": "#EC932F",
        //             "pointHoverBorderColor": "#EC932F",
        //             "data": [
        //                 10000000,
        //                 15000000,
        //                 5000000,
        //                 20000000,
        //                 10000000,
        //                 5000000,
        //                 5000000
        //             ],
        //             "yAxisID": "y-axis-2"
        //         },
        //         {
        //             "label": "",
        //             "type": "bar",
        //             "backgroundColor": "#71B37C",
        //             "borderColor": "#71B37C",
        //             "hoverBackgroundColor": "#71B37C",
        //             "hoverBorderColor": "#71B37C",
        //             "data": [
        //                 3812000,
        //                 6700500,
        //                 10119000,
        //                 14063500,
        //                 18534000,
        //                 0,
        //                 0
        //             ],
        //             "yAxisID": "y-axis-1"
        //         }
        //     ]
        // }

        return (
            <div className={'chart-column display_row align-center valign-middle'}>
                {loading && <Loading/>}
                {!loading && data && data.length > 0 &&
                <Bar datasetKeyProvider={this.datasetKeyProvider}
                     data={{labels: labels, datasets: datasets}}
                     options={options}/>
                }
                {!loading && (!data || data.length === 0) && Config.lang('CRM_Khong_co_du_lieu')}
            </div>
        )
    }
}

export class Grid extends Component {

    render() {
        const {loading, data, dataColumn} = this.props;
        let column = [];
        if (data.length > 0) {
            Object.keys(data[0]).forEach((key, idx) => {
                const nameColumn = dataColumn.find(col => col.CustomerDatatype === key);
                column.push(
                    <Column
                        key={'column' + idx}
                        dataField={key}
                        caption={nameColumn ? nameColumn.CustomerDatatypeName : 'Đối tượng'}
                        alignment={'center'}
                        width={!idx ? 220 : 150}
                    />
                )
            });
        }

        return (
            <>
                {loading && <Loading/>}
                {!loading && data.length > 0 &&
                <GridContainer dataSource={data}
                               title={''}
                               style={{
                                   height: 'calc(100% - 30px)',
                                   overflowY: 'scroll'
                               }}
                               showBorders={false}
                               showColumnLines={true}
                               wordWrapEnabled={true}
                               selection={{mode: 'single'}}
                               allowColumnReordering={false}
                               rowAlternationEnabled={true}
                               keyExpr={'colums1'}
                >
                    {column && column}
                </GridContainer>
                }
            </>
        )
    }
}

export class Title extends Component {

    onClickShowMaximize = () => {
        if (this.props.onClickShowMaximize) this.props.onClickShowMaximize();
    };

    onClickShowMinimize = () => {
        if (this.props.onClickShowMinimize) this.props.onClickShowMinimize();
    };

    onClickSetting = () => {
        if (this.props.onClickSetting) this.props.onClickSetting();
    };

    onClickInfo = () => {
        if (this.props.onClickInfo) this.props.onClickInfo();
    };

    render() {
        const {title, isMax, isSetting} = this.props;
        return (
            <div className="title-view">
                <div className="title-text">{title ? title : '---'}</div>
                <div className="action-title">
                    {!isSetting && <i className='fa fa-cog' onClick={this.onClickSetting}/>}
                    {isSetting && <i className='fa fa-info-circle' onClick={this.onClickInfo}/>}
                    {!isSetting && !isMax && <i className='fa fa-window-maximize' onClick={this.onClickShowMaximize}/>}
                    {!isSetting && isMax && <i className='fa fa-window-minimize' onClick={this.onClickShowMinimize}/>}
                </div>
            </div>
        )
    }
}

export default connect(state => ({
        listDashboard: state.dashboard.listDashboard,
        listReportColumn: state.dashboard.listReportColumn,
        gridReportColumn: state.dashboard.gridReportColumn,
        typeTime: state.dashboard.typeTime,
        typeTime2: state.dashboard.typeTime2,
        cboGroupSaleW18F1020: state.w18F1020.cboGroupSaleW18F1020
    }),
    (dispatch) => ({
        dashboardActions: bindActionCreators(dashboardActions, dispatch),
        w18F1020Actions: bindActionCreators(w18F1020Actions, dispatch)
    })
)(DashboardPage);

