/**
 * @copyright 2019 @ DigiNet
 * @author VINHLE
 * @create 2019/03/28 13:49
 * @update 2019/03/28 13:49
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import connect from "react-redux/es/connect/connect";
import {bindActionCreators} from "redux";
import * as w18F1019Actions from "../../actions/w18/w18F1019/w18F1019-actions";
import Config from "../../config";
import PopupView from "../popup/popup-view";
import {Col, Form, FormGroup, InputGroup, Alert} from "react-bootstrap";
import MultiToggle from "react-multi-toggle";
import InputGroupAddon from "react-bootstrap/es/InputGroupAddon";
import {Loading} from "../common/loading/loading";
import notify from "devextreme/ui/notify";
import EventTracking from "../common/tracking/event";

import moment from "moment";
import 'moment/locale/vi'
moment.locale('vi');

class W18F1019 extends Component {
    constructor(props) {
        super(props);
        this.allowLoad = true;
        this.state = {
            checkKedPer: '',
            dataGrid: [],
            groupSize: 2,
            loading: false,

        };

        this.filter = {
            skip: 0,
            limit: 10,
            search: '',
            totalRows: 0,
        };
        this.timer = null;
        this.timeDeplay = Config.getSetting("TIME_SUBMIT_DELAY") || 3000;
        this.groupOptions = [
            {
                displayName: 'No',
                value: 0,// xoa quyen
                optionClass: 'bgGray text-white bRadius'

            },
            {
                displayName: 'View',
                value: 1,
                optionClass: 'bgOrange text-white bRadius'

            },
            {
                displayName: 'Full',
                value: 4,
                optionClass: 'bgGreen text-white bRadius'
            },
        ];

    }


    search(e) {
        if (this.timer) {
            clearTimeout(this.timer);
        }
        this.timer = setTimeout(() => {
            this.filter.search = e;
            this.filter.limit = 10;
            this.filter.skip = 0;
            this.setState({
                dataGrid: []
            });
            this.loadGrid();
        }, this.timeDeplay / 3);
    }

    deletePer(salesPersonID, callback) {
        const {companyID} = this.props;
        const param = {
            SalesPersonID: salesPersonID,
            CompanyID: companyID
        };
        this.setState({
            loading: true
        });
        this.props.w18F1019Actions.deletePer(param, (error) => {
            if (error) {
                Config.popup.show('INFO', `${Config.lang("CRM_Loi_chua_xac_dinh")}`);
                this.loadGrid();
                return false;
            } else {
                this.setState({
                    loading: false
                });
                if (callback) {
                    callback();
                    notify(`${Config.lang("CRM_Xoa_quyen_thanh_cong")}`, "success", 500);

                }


            }
        })
    }

    onGroupSizeSelect(value, index) {
        const {dataGrid} = this.state;

        // if (dataGrid) {
        //     let arr = [...dataGrid];
        //     arr[index].Permission = value
        //
        //     this.setState({
        //         dataGrid: arr,
        //         loading: false
        //     })
        // }
        if (value === dataGrid[index].Permission) return false;
        if (value === 0) {
            Config.popup.show("YES_NO", `${Config.lang("CRM_Ban_co_muon_xoa_quyen_cua_nhan_vien")} ${dataGrid[index].SalesPersonNameU && dataGrid[index].SalesPersonNameU} ?`, () => {

                this.deletePer(dataGrid[index].SalesPersonID, () => {
                    let data = [...dataGrid];
                    data[index].Permission = 0;
                    this.setState({
                        dataGrid: data
                    })
                })
            }, () => {

                this.loadGrid()
            });
        } else {
            if (dataGrid[index].Permission !== 0) {
                this.deletePer(dataGrid[index].SalesPersonID, () => {
                    this.saveData(dataGrid[index].SalesPersonID, value, () => {
                        let data = [...dataGrid];
                        data[index].Permission = value;
                        this.setState({
                            dataGrid: data
                        })
                    });
                });

            } else {
                this.saveData(dataGrid[index].SalesPersonID, value, () => {
                    let data = [...dataGrid];
                    data[index].Permission = value;
                    this.setState({
                        dataGrid: data
                    })
                });
            }


        }


    }

    saveData(salesPersonID = '', permission = 0, cb) {
        const {companyID, transType} = this.props;
        const param = {
            CompanyID: companyID,
            SalesPersonID: salesPersonID,
            Permission: permission,
        };
        if (transType) {
            param.TransType = transType;
        }

        this.props.w18F1019Actions.saveData(param, (error) => {
            if (error) {
                Config.popup.show('INFO', `${Config.lang("CRM_Loi_chua_xac_dinh")}`);
                this.loadGrid();
                return false;
            } else {
                this.setState({
                    loading: false,
                });
                if (cb) {
                    cb();
                    notify(`${Config.lang("CRM_Luu_quyen_thanh_cong")}`, "success", 300);


                }

            }
        })
    }

    loadGrid() {
        this.allowLoad = false;
        this.setState({
            loading: true,
        });
        const {companyID} = this.props;
        const uName = Config.profile.UserID || '';
        const uLanguage = Config.language || '';
        const param = {
            "UserID": uName,
            "Language": uLanguage,
            "GroupSalesID": '',
            "Mode": 2,
            "StrSearch": this.filter.search,
            "CompanyID": companyID,
            "skip": this.filter.skip,
            "limit": this.filter.limit,
        };
        this.props.w18F1019Actions.loadGridW18F1019(param, (error, data) => {
            if (error) {
                Config.popup.show('INFO', `${Config.lang("CRM_Loi_chua_xac_dinh")}`);
                return false;
            } else if (data && data.length > 0) {
                const newData = [...this.state.dataGrid, ...data];
                this.filter.totalRows = data && data.length > 0 ? data[0].TotalRecord : 0;
                this.setState({
                    dataGrid: newData
                }, () => {
                    this.setState({
                        loading: false,
                    });
                    this.allowLoad = true;
                })
            } else if (data && data.length === 0) {
                this.filter.totalRows = 0;
                this.setState({
                    loading: false,
                });
            }


        });


    }

    componentDidMount() {
        this.loadGrid();
        this.appendTitle();
    }


    onHide() {
        this.setState({
            showGrid: false
        });

        if (this.props.onHide) {
            this.props.onHide();
        }

    }

    componentWillUnmount() {
        if (this.timer) clearTimeout(this.timer);
    }


    loadMoreData() {
        if (!this.allowLoad) {
            return false;
        }
        const el = document.getElementById('frmSharePermission');
        if (this.filter.totalRows > 0) {
            if (el.offsetHeight + el.scrollTop >= el.scrollHeight) {
                this.filter.skip = this.filter.skip + 10;
                this.loadGrid();

            }
        }


    }

    appendTitle() {
        const {companyName} = this.props;
        if (companyName) {
            let node = document.createElement("SPAN");
            node.setAttribute("id", "shareCustomerNameW18F1019");
            node.appendChild(document.createTextNode(` ${companyName}`));
            document.getElementsByClassName('W18F1019Pop')[0].getElementsByClassName('pu-title')[0].appendChild(node);
        } else {
            return false;
        }
    }

    render() {
        const {dataGrid} = this.state;
        return (
            <PopupView
                title={`${Config.lang("CRM_Chia_se_khach_hang")}`}
                show={true}
                animation={true}
                onHide={() => this.onHide()}
                className={"W18F1019Pop"}

            >

                <div className="page-container">
                    {this.state.loading && <Loading/>}
                    {Config && Config.profile &&
                    <EventTracking category={"TRACKING_USER"} action={"W18F1019"} label={Config.profile.UserID}/>}

                    <Form id={'W18F1019'} horizontal={true}>
                        <FormGroup className={'mgt5'}>
                            <Col md={6} sm={6} xl={6} xs={6} lg={6}>

                                <InputGroup style={{width: '100%'}}>
                                    <input id={'search-share-permission'}
                                           placeholder={Config.lang("CRM_Nhap_noi_dung_tim_kiem")}
                                           onKeyDown={(e) => {
                                               //khong submit form khi enter
                                               if (e.keyCode === 13) {
                                                   e.preventDefault();
                                                   return false;
                                               }
                                           }}
                                           onKeyUp={(e) => this.search(e.target.value)} type="text"
                                           className="form-control"/>
                                    <InputGroupAddon id={'search-button-permission'}>
                                        <i className="fas fa-search"/>
                                    </InputGroupAddon>
                                </InputGroup>
                            </Col>
                        </FormGroup>

                        <div id={'frmSharePermission'} onScroll={() => {
                            this.loadMoreData()
                        }}>
                            {!this.state.loading && dataGrid.length <= 0 &&
                            <FormGroup>
                                <Col style={{padding: 0}} xs={12} sm={12} lg={12} md={12}>
                                    <Alert bsStyle="warning">
                                        {Config.lang("CRM_Khong_tim_thay_du_lieu")}
                                    </Alert>
                                </Col>
                            </FormGroup>
                            }
                            {dataGrid && dataGrid.map((value, index) => {
                                return (

                                    <FormGroup className={'Share-Rows'} key={index}>
                                        <Col style={{display: 'flex'}} className={'Share-Content'} xs={12} sm={12}
                                             lg={12} md={12}>
                                            <img className={'Avatar-Share-Person'}
                                                 src={value.Image ? value.Image : require('./../../images/icon-user-default.png')}
                                                 alt="not found"/>
                                            <div className={'Share-Info'}>
                                                <div className={'Share-Info-Content'}>
                                                    <div className={'Share-Person-Name'}>{value.SalesPersonNameU}</div>
                                                    <div className={'Share-Person-Location'}>{value.JobPositionU &&
                                                    <div>{value.JobPositionU}&nbsp;&nbsp; &nbsp;&nbsp;</div>}{value.Email &&
                                                    <div><i
                                                        className="fas fa-envelope"/> {value.Email}&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </div>}
                                                        {value.MobileNo &&
                                                        <div><i className="fas fa-phone"/> {value.MobileNo}</div>}</div>
                                                </div>
                                            </div>
                                            <div className={'Share-Per'}>
                                                <MultiToggle
                                                    options={this.groupOptions}
                                                    selectedOption={dataGrid && dataGrid[index].Permission}
                                                    onSelectOption={(e) => {
                                                        this.onGroupSizeSelect(e, index)
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                    </FormGroup>

                                )
                            })}

                        </div>


                    </Form>

                </div>
            </PopupView>
        )
    }
}


W18F1019.propTypes = {
    onClick: PropTypes.func,
    companyID: PropTypes.string,
    companyName: PropTypes.string,
    transType: PropTypes.string
};

export default connect(state => ({
        dataGrid: state.w18F1019.dataGrid
    }),
    (dispatch) => ({
        w18F1019Actions: bindActionCreators(w18F1019Actions, dispatch)
    })
)(W18F1019);
