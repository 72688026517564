/**
 * @copyright 2019 @ DigiNet
 * @author XUANVINH
 * @create 2019/01/11 10:37
 * @update 2019/01/11 10:37
 * @file src/components/header/header.js
 */
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from "react-redux";

import EventTracking from "../common/tracking/event";
import GridContainer from "../common/grid-container/grid-container";
import {Column} from "devextreme-react/data-grid";
import {bindActionCreators} from "redux";
import * as w18F1015Actions from "../../actions/w18F1015-actions";
import Config from "../../config";
import _ from "lodash";
import { Modal, ModalHeader, ModalBody } from 'diginet-core-ui/components';
import { Loading } from "../common/loading/loading";


class W18F1015Page extends React.PureComponent {
    constructor(props) {
        super(props);
        this.filter = {
            skip: 0,
            limit: 10,
            search: '',
            searchID: '',
            CompanyStatus: ''
        };
        this.data = {};
        this.dataInventory = [];
        this.oldCRM = -1
        this.state ={
            dataGrid:[],
            dataGridTotal:0,
            loading: false
        }
    }


    componentDidMount() {
        this.loadDataGrid()
    }


    loadDataGridOld(isSearch) {
        const {mode, formID="W18F1010", typeID=""} = this.props;
        const uName = Config.profile.UserID || '';
        const uLanguage = Config.language || '84';
        const paramGrid = {
            "UserID": uName,
            "Language": uLanguage,
            "FormID": formID,
            "SearchID": '',
            "StrSearch": this.filter.search,
            "CompanyID": '',
            "skip": this.filter.skip,
            "limit": this.filter.limit,
            "CompanyStatus": '',
            "ReportID": '',
            "Mode": mode ? mode : 0,
            "UseFilter": isSearch ? 1 : 0
        };
        if(!_.isEmpty(typeID)) paramGrid.typeID = typeID;
        this.setState({ loading: true });
        this.props.w18F1015Actions.loadGridW18F1015(paramGrid,(error, data) => {
            this.setState({ loading: false });
            if (error || !data) {
                let message =(error && error.message )|| Config.lang("CRM_Loi_chua_xac_dinh");
                return Config.popup.show('INFO', message);
            }
            this.setState({dataGrid:data || [],dataGridTotal: _.get(data,"0.TotalRecord",0) })
        })
    }
    loadDataGridW17F1025() {
        const {formID,typeID} = this.props;
        const paramGrid = {
            "Language": Config.language || '84',
            "FormID": formID || 'W18F1015',
            TypeID: typeID || "AddRequestID",
            CompanyStatus:"",
            "StrSearch": this.filter.search,
            "skip": this.filter.skip,
            "limit": this.filter.limit,
            UseFilter:0
        };
        this.props.w18F1015Actions.loadGridW17F1025(paramGrid, (error, data) => {
            if (error || !data) {
                let message = (error && error.message) || Config.lang("Loi_chua_xac_dinh");
                return Config.popup.show('INFO', message);
            }
            this.setState({dataGrid:data.rows || [],dataGridTotal: data.total})

        })
    }

    loadRefreshInventory = () => {
        const { quotationID: QuotationID = "", tranTypeID: TranTypeID = "" } = this.props;
        const { ObjectID = "" } = this.data;
        const params = {
            QuotationID,
            TranTypeID,
            ObjectID
        };
        this.setState({ loading: true });
        this.props.w18F1015Actions.loadRefreshInventory(params, (error, data) => {
            this.setState({ loading: false });
            if (error) {
                const message = (error && error.message) || Config.lang("Loi_chua_xac_dinh");
                return Config.popup.show('INFO', message);
            } else if (data) {
                this.dataInventory = data;
                this.onHide();
            }
        })
    };

    loadDataGrid = () =>{
        if (this.oldCRM === -1) {
            // chua load
            this.props.w18F1015Actions.checkOldCRM({},(error, data) => {
                if (error || !data) {
                    return
                }
                this.oldCRM =data.Status
                if (this.oldCRM) {
                    return this.loadDataGridW17F1025()
                }
                return this.loadDataGridOld()
            })
            return false
        }
        if (this.oldCRM === 1) {
            return this.loadDataGridW17F1025()
        }
        return this.loadDataGridOld()

    }

    onHide() {
        if (this.props.onHide) {
            this.props.onHide(this.data, this.dataInventory);
        }
    }

    onChangePage(page) {
        this.filter.skip = page * this.filter.limit;
        this.loadDataGrid();
    }

    onChangePerPage(per) {
        this.filter.skip = 0;
        this.filter.limit = per;
        this.loadDataGrid();
    }

    onSearch(text) {
        this.filter.skip = 0;
        this.filter.search = text;
        this.loadDataGrid(true);
    }

    render() {
        const { dataGrid, dataGridTotal, loading } = this.state;
        return (
            <Modal
                open
                size={"xl"}
                onClose={() => this.onHide()} >
                <ModalHeader>{Config.lang("Thong_tin_khach_hang")}</ModalHeader>
                <ModalBody>
                    {loading && <Loading />}
                    {Config && Config.profile &&
                        <EventTracking category={"TRACKING_USER"} action={"W18F1015"} label={Config.profile.UserID} />}
                    <GridContainer
                        columnAutoWidth={true}
                        dataSource={dataGrid && dataGrid}
                        itemPerPage={this.filter.limit}
                        totalItems={dataGridTotal}
                        onSearch={(text) => {
                            this.onSearch(text)
                        }}
                        onDbRowClick={(e) => {

                            this.data = e.data;
                            this.loadRefreshInventory();
                        }}
                        onRowClick={(e) => {
                            this.data = e.data;

                        }}
                        onChangePage={(page) => {
                            this.onChangePage(page)
                        }}
                        onChangePerPage={(per) => {
                            this.onChangePerPage(per)
                        }}>


                        <Column
                            dataField={'CompanyNameU'}
                            caption={Config.lang("Ten_khach_hang")}
                            allowSorting={true}
                            allowReordering={false}
                        />

                        <Column
                            dataField={'SalesPersonNameU'}
                            caption={Config.lang("Nhan_vien")}
                            width={200}
                        />
                        <Column
                            dataField={'Telephone'}
                            width={150}
                            caption={Config.lang("Dien_thoai")} />


                        <Column
                            dataField={'AddressLineU'}
                            caption={Config.lang("Dia_chi_DKKD")}
                            width={450}

                        />
                        <Column
                            dataField={'ObjectID'}
                            caption={Config.lang("Ma_doi_tuong")}
                            width={300}

                        />
                        <Column
                            dataField={'CompanyID'}
                            caption={Config.lang("Ma_khach_hang")}
                            allowSorting={true}
                            width={180}
                        />
                    </GridContainer>
                </ModalBody>
            </Modal>
        )
    }
}

W18F1015Page.propTypes = {
    mode: PropTypes.number,
    tranTypeID: PropTypes.string,
    quotationID: PropTypes.string
};
W18F1015Page.defaultProps = {
    tranTypeID: "",
    quotationID: "",
};

export default connect(state => ({
    dataGrid: state.w18f1015.dataGrid

}),
    (dispatch) => ({

        w18F1015Actions: bindActionCreators(w18F1015Actions, dispatch),

    })
)(W18F1015Page);
